export default function itrFilingSecArray() {
    return (
        [
            { key: "11", value: "139(1)" },
            { key: "12", value: "139(4)" },
            { key: "13", value: "142(1)" },
            { key: "14", value: "148" },
            { key: "15", value: "153A" },
            { key: "16", value: "153C" },
            { key: "17", value: "139(5)" },
            { key: "18", value: "139(9)" },
            { key: "19", value: "92CD" },
            { key: "20", value: "119(2)(b)" },
            { key: "20N", value: "139(9A)" },
            { key: "21", value: "139(8A)" },
            { key: "22", value: "22" },
            { key: "23", value: "23" },
            { key: "24", value: "24" },
            { key: "25", value: "25" },
            { key: "35", value: "35" },
            { key: "36", value: "36" },
            { key: "41", value: "170(A)" },
            { key: "154", value: "154" },
            { key: "153AC", value: "153A/153C" }
        ]

    )
}