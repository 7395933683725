import { Box, Button, Text, Dialog, FormControl, Checkbox, Spinner, Label } from "@primer/react"
import { Helmet } from "react-helmet"
import Header from "../../Portal/Header"
import SideNav from "../../Portal/SideNav"
import { useDispatch, useSelector } from "react-redux"
import AddPanDialog from "../../Workplace/Dialogs/AddPanDialog"
import UploadPanDialog from "../../Workplace/Dialogs/UploadPanDialog"
import { useNavigate } from "react-router-dom"
import React from "react"
import Workplacetheme from "../../Workplace/Workplacetheme"
import { changeITPasswordOpenClose, getProductTourTaxhero, getSingleItportal, updateItPortal, updateProductTourTaxhero } from "../../redux/features/portalSlice"
import customTheme from "../../../Theme.module.scss"
import customThemVariable from "../../../ThemeVariable.module.scss"
import { ChevronRightIcon } from "@primer/octicons-react"
import TaxHeroHeader from "../../Portal/TaxHeroHeader"
import axios from "axios"
import ChangePasswordITPanDialog from "./ChangePasswordDialog"
import { Toaster, Intent, Position, ProgressBar } from "@blueprintjs/core";
import { UpdateIcon } from "@radix-ui/react-icons"
const synchingToast = Toaster.create({ position: Position.TOP })

function ItPlaywright(props) {

    let hostName = window.location.hostname
    let host = window.location.host
    const driver = window.driver.js.driver;
    let dispatch = useDispatch()
    const navigate = useNavigate()
    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail) || localStorage.getItem("userEmail")
    const serverTrigger = useSelector((state) => state.portalData.serverTrigger)

    const clientId = localStorage.getItem("client_id") || ""
    const insertId = localStorage.getItem("insert_id") || ""
    const initialFocusRef = React.createRef()
    const [sample, setSample] = React.useState([])
    const [dataSyncOpenClose, setdataSyncOpenClose] = React.useState(false)
    const [showLoadingBtn, setshowLoadingBtn] = React.useState(false)
    const [showSyncProgress, setshowSyncProgress] = React.useState(false)
   const [isDisabledSyncing, setisDisabledSyncing] = React.useState(false);
    const [isShowSyncDialog, setisShowSyncDialog] = React.useState(false)

    const [progressPercentage, setprogressPercentage] = React.useState(0)
    const [isInvalidPassword,setisInvalidPassword] = React.useState(false)
    

    const [profileData, setProfileData] = React.useState(null)
    //const [filedReturns, setfiledReturns] = React.useState([])
    const [allDataFeilds, setallDataFeilds] = React.useState([])
    const [selectedCheckBox, setselectedCheckBox] = React.useState(["Profile", "Returns", "ProceedingNotice"])

    //Post Hog Events-----------------------------------
    const postHogTrackingItSyncBtnClick = async (userId, userEmail) => {

        if (userId !== null && userEmail !== null) {

            const postData = {
                api_key: `${process.env.REACT_APP_POSTHOG_APIKEY_TAXHERO}`,  //'phc_YGL9BwpH02nYVaF1iRs3oc9fJxsk2IUyrPU07nRqVpg',
                event: 'ITRSyncClick',
                properties: {
                    distinct_id: `${userId}`,
                    user_id: `${userId}`,
                    user_email: `${userEmail}`,
                },
                timestamp: new Date().toISOString(),
            };

            const apiUrl = `${process.env.REACT_APP_POSTHOG_APIURL}/`

            const result = await axios.post(apiUrl, postData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            return result;
        }


    }

    //------Product tour section ----------------
    const updateProductTourInDb = (props) => {

        const locationPath = [...props, { pathname: window.location.pathname, tour: true }]
        dispatch(updateProductTourTaxhero({
            userId: userId,
            userEmail: userEmail,
            locationPath: locationPath
        })).then((data) => {

            console.log("updateProductTourTaxhero", data.payload)
            const newLocationPath = data.payload.data
            console.log("newLocationPath", newLocationPath)
        })

    }

    const showProductTour = (props) => {

        const driverObj = driver({
            popoverClass: 'driverjs-theme',
            showProgress: true,
            allowClose: false,
            steps: [
                { element: '#syncBtn', popover: { title: 'Sync data', description: `Use the sync button to update all client's latest compliance reports in the Income Tax portal.`, side: "top", align: 'start' } },
                { element: '#profileDetails', popover: { title: 'ITR Profile', description: 'View detailed profile information about your client for quick insights.', side: "top", align: 'start' } },

            ],
            onDestroyStarted: () => {
                console.log("driverObj", driverObj)

                if (driverObj.getActiveIndex() == 1) {
                    updateProductTourInDb(props)
                    if (!driverObj.hasNextStep() || driverObj.confirm("Are you sure?")) {
                        driverObj.destroy();

                    }

                }


            },
        });

        driverObj.drive();

    }

    const getUserProductTour = (props) => {


        dispatch(getProductTourTaxhero({
            userId: userId,
            userEmail: userEmail
        })).then((data) => {

            console.log("getProductTourTaxhero", data.payload.data)
            const tourPaths = [...data.payload.data]
            const checkProductTour = tourPaths?.find((pData) => pData.pathname === window.location.pathname)?.tour
            console.log("checkProductTour", checkProductTour)
            if (!checkProductTour) {
                // showProductTour(tourPaths)
            }
        })

    }
    //------------------------------------------

    const disableSyncButton = (props) => {
        setisDisabledSyncing(true);
        setTimeout(() => {
            setisDisabledSyncing(false);
        },
            30 * 60 * 1000
        );
    };

 

    const getSingleItportalFromDb = (props) => {

        dispatch(getSingleItportal({
            userId: userId,
            userEmail: userEmail,
            insertId: insertId,
            serviceType:"Profile"
        })).then((data) => {
            console.log("getSingleItportal", data.payload)
            setallDataFeilds(data.payload.data)
            setProfileData(data.payload.data[0]?.playwright_itr_profile[0])
            //setfiledReturns(data.payload.data[0]?.playwright_itr_return)
        })

    }

    const loadingToast = async (progressPercentage, key) => {

        synchingToast.show({
            icon: "cloud-upload",
            message: <Box sx={{ marginTop: '4px' }}>
                <ProgressBar
                    animate={true}
                    stripes={true}
                    intent={progressPercentage < 100 ? Intent.PRIMARY : Intent.SUCCESS}
                    value={progressPercentage / 100}

                />
            </Box>

            ,
            onDismiss: (didTimeoutExpire) => {
                if (!didTimeoutExpire) {

                    synchingToast.dismiss()

                }
            },
            timeout: progressPercentage < 100 ? 0 : 3000,


        }, key);

    }


    const showChangePasssordDialog = (props, itName, itUsername, itPassword) => {

        dispatch(changeITPasswordOpenClose({
            openCloseITChangePassword: true,
            itinsertId: props,
            itName: itName,
            itUsername: itUsername,
            itPassword: itPassword
        }))


    }

    const showItSyncDialog = async (props) => {

        postHogTrackingItSyncBtnClick(userId, userEmail)
        disableSyncButton("")
        setisShowSyncDialog(false)
      //  loadingToast(10, "1")
        setprogressPercentage(1)
        setTimeout(() => {
            if (!isInvalidPassword) {
                setprogressPercentage(15)
            }
        }, 2000)
        setTimeout(() => {

            if (!isInvalidPassword) {
                setprogressPercentage(30)
            }
        }, 5000)
        setTimeout(() => {

            if (!isInvalidPassword) {
                setprogressPercentage(60)
            }
        }, 15000)
        try {

            const insertId = allDataFeilds[0]?.insert_id
            const response = await fetch(`${process.env.REACT_APP_SCRAPSERVERURL}/syncitdev?insertId=${insertId}&checkboxes=${JSON.stringify(selectedCheckBox)}`);
            setselectedCheckBox([])
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const responseData = await response.json();
            const profileDataResponse = responseData?.profileData || []
            const errorCode = responseData?.code
            if (errorCode === "401") {
                // synchingToast.dismiss()
                synchingToast.clear()
                Toaster.create({ position: Position.TOP }).show({
                    message: "Sync error: Invalid username or password.",
                    intent: Intent.PRIMARY
                });
                showChangePasssordDialog(insertId, allDataFeilds[0]?.pan_name, allDataFeilds[0]?.pan_number, allDataFeilds[0]?.pan_password)
                setprogressPercentage(100)
                setisInvalidPassword(true)
            }

            if (errorCode === "200") {
               // loadingToast(100, "1")
                setprogressPercentage(100)
            }
            console.log("plinggst", responseData)
            getSingleItportalFromDb("")

        } catch (error) {


        }

    }

    const handleSyncProfile = async (props) => {

        disableSyncButton("")
        setisShowSyncDialog(false)
     //   loadingToast(10, "1")
        setprogressPercentage(1)
        setTimeout(() => {
            if (!isInvalidPassword) {
                setprogressPercentage(15)
            }
        }, 2000)
        setTimeout(() => {

            if (!isInvalidPassword) {
                setprogressPercentage(30)
            }
        }, 5000)
        setTimeout(() => {

            if (!isInvalidPassword) {
                setprogressPercentage(60)
            }
        }, 15000)
        try {

            const insertId = allDataFeilds[0]?.insert_id
            const response = await fetch(`${process.env.REACT_APP_SCRAPSERVERURL}/syncitprofile?insertId=${insertId}&checkboxes=${JSON.stringify(["Profile"])}`);
            setselectedCheckBox([])
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const responseData = await response.json();
            const errorCode = responseData?.code
            if (errorCode === "401") {
                // synchingToast.dismiss()
                synchingToast.clear()
                Toaster.create({ position: Position.TOP }).show({
                    message: "Sync error: Invalid username or password.",
                    intent: Intent.PRIMARY
                });
                showChangePasssordDialog(insertId, allDataFeilds[0]?.pan_name, allDataFeilds[0]?.pan_number, allDataFeilds[0]?.pan_password)
                setprogressPercentage(100)
                setisInvalidPassword(true)
            }

            if (errorCode === "200") {
               // loadingToast(100, "1")
                setprogressPercentage(100)
            }
            console.log("plinggst", responseData)
            getSingleItportalFromDb("")

        } catch (error) {


        }

    }

    const closeDataSyncDialog = (props) => {

           setdataSyncOpenClose(false)

    }

    const ITSyncDialogPlaywright = (props) => {

        return (

            <Dialog
                sx={{
                    height: '480px',
                    width: '564px',

                }}
                isOpen={isShowSyncDialog}
                onDismiss={() => {
                    setisShowSyncDialog(false)
                }}
                aria-labelledby="header-id"
                initialFocusRef={initialFocusRef}
            >
                <Dialog.Header id="header-id">
                    <Text ref={initialFocusRef} sx={Workplacetheme.scheduleDalogeHeading}>Data Sync</Text>

                </Dialog.Header>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: 564,
                    height: 'fit-content',

                }}>

                    <Text sx={{
                        display: 'flex',
                        position: 'relative',
                        margin: '15px 20px 0px 15px',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: '#6A737D',
                    }}>Once synced for a IT account, you'll have to wait for the next 8 hrs to sync again.</Text>


                    <Box sx={{
                        position: 'relative',
                        margin: '40px 40px 0px 23px',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '20px'
                    }}>

                        <Box sx={{ flexBasis: 'calc(50% - 20px)' }}>
                            <FormControl>
                                <Checkbox id="default-checkbox" value={"Profile"} checked={selectedCheckBox.includes("Profile")} onChange={(e) => {
                                    if (e.target.checked) {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState, e.target.value]
                                            return newState
                                        })
                                    } else {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState].filter((row) => row !== e.target.value)
                                            return newState
                                        })
                                    }
                                }} />
                                <FormControl.Label
                                    sx={{
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                    }}
                                >Profile</FormControl.Label>
                            </FormControl>
                        </Box>

                        <Box sx={{ flexBasis: 'calc(50% - 20px)' }}>
                            <FormControl>
                                <Checkbox id="default-checkbox" value={"Returns"} checked={selectedCheckBox.includes("Returns")} onChange={(e) => {
                                    if (e.target.checked) {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState, e.target.value]
                                            return newState
                                        })
                                    } else {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState].filter((row) => row !== e.target.value)
                                            return newState
                                        })
                                    }
                                }} />
                                <FormControl.Label
                                    sx={{
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                    }}
                                >Returns</FormControl.Label>
                            </FormControl>


                        </Box>

                        <Box sx={{ flexBasis: 'calc(50% - 20px)' }}>
                            <FormControl>
                                <Checkbox id="default-checkbox" value={"ProceedingNotice"} checked={selectedCheckBox.includes("ProceedingNotice")} onChange={(e) => {
                                    if (e.target.checked) {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState, e.target.value]
                                            return newState
                                        })
                                    } else {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState].filter((row) => row !== e.target.value)
                                            return newState
                                        })
                                    }
                                }} />
                                <FormControl.Label
                                    sx={{
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                    }}
                                >Proceeding Notice</FormControl.Label>
                            </FormControl>


                        </Box>
                        <Box sx={{ flexBasis: 'calc(50% - 20px)' }}>
                            <FormControl>
                                <Checkbox id="default-checkbox" value={"CAList"} checked={selectedCheckBox.includes("CAList")} onChange={(e) => {
                                    if (e.target.checked) {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState, e.target.value]
                                            return newState
                                        })
                                    } else {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState].filter((row) => row !== e.target.value)
                                            return newState
                                        })
                                    }
                                }} />
                                <FormControl.Label sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                }}>CA List</FormControl.Label>
                            </FormControl>
                        </Box>

                        {/* <Box sx={{ flexBasis: 'calc(50% - 20px)' }}>
                            <FormControl >
                                <Checkbox id="default-checkbox" value={"BankAccount"} checked={selectedCheckBox.includes("BankAccount")} onChange={(e) => {
                                    if (e.target.checked) {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState, e.target.value]
                                            return newState
                                        })
                                    } else {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState].filter((row) => row !== e.target.value)
                                            return newState
                                        })
                                    }
                                }} />
                                <FormControl.Label sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                }}>Bank Account</FormControl.Label>
                            </FormControl>
                        </Box> */}
                        <Box sx={{ flexBasis: 'calc(50% - 20px)' }}>
                            <FormControl

                            >
                                <Checkbox id="default-checkbox" value={"FiledForms"} checked={selectedCheckBox.includes("FiledForms")} onChange={(e) => {
                                    if (e.target.checked) {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState, e.target.value]
                                            return newState
                                        })
                                    } else {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState].filter((row) => row !== e.target.value)
                                            return newState
                                        })
                                    }
                                }} />
                                <FormControl.Label sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                }}>Filed Forms</FormControl.Label>
                            </FormControl>


                        </Box>
                        <Box sx={{ flexBasis: 'calc(50% - 20px)' }}>
                            <FormControl

                            >
                                <Checkbox id="default-checkbox" value={"Demand"} checked={selectedCheckBox.includes("Demand")} onChange={(e) => {
                                    if (e.target.checked) {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState, e.target.value]
                                            return newState
                                        })
                                    } else {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState].filter((row) => row !== e.target.value)
                                            return newState
                                        })
                                    }
                                }} />
                                <FormControl.Label sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                }}>Demand</FormControl.Label>
                            </FormControl>


                        </Box>
                        <Box sx={{ flexBasis: 'calc(50% - 20px)' }}>
                            <FormControl

                            >
                                <Checkbox id="default-checkbox" value={"Payments"} checked={selectedCheckBox.includes("Payments")} onChange={(e) => {
                                    if (e.target.checked) {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState, e.target.value]
                                            return newState
                                        })
                                    } else {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState].filter((row) => row !== e.target.value)
                                            return newState
                                        })
                                    }
                                }} />
                                <FormControl.Label sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                }}>Payments</FormControl.Label>
                            </FormControl>


                        </Box>
                        <Box sx={{ flexBasis: 'calc(50% - 20px)' }}>
                            <FormControl>
                                <Checkbox id="default-checkbox" value={"KeyPersonDetails"} checked={selectedCheckBox.includes("KeyPersonDetails")} onChange={(e) => {
                                    if (e.target.checked) {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState, e.target.value]
                                            return newState
                                        })
                                    } else {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState].filter((row) => row !== e.target.value)
                                            return newState
                                        })
                                    }
                                }} />
                                <FormControl.Label sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                }}>KeyPerson Details</FormControl.Label>
                            </FormControl>


                        </Box>
                        <Box sx={{ flexBasis: 'calc(50% - 20px)' }}>
                            <FormControl

                            >
                                <Checkbox id="default-checkbox" value={"Adjournment"} checked={selectedCheckBox.includes("Adjournment")} onChange={(e) => {
                                    if (e.target.checked) {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState, e.target.value]
                                            return newState
                                        })
                                    } else {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState].filter((row) => row !== e.target.value)
                                            return newState
                                        })
                                    }
                                }} />
                                <FormControl.Label sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                }}>Adjournment</FormControl.Label>
                            </FormControl>


                        </Box>
                        <Box sx={{ flexBasis: 'calc(50% - 20px)' }}>
                            <FormControl

                            >
                                <Checkbox id="default-checkbox" value={"VideoConference"} checked={selectedCheckBox.includes("VideoConference")} onChange={(e) => {
                                    if (e.target.checked) {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState, e.target.value]
                                            return newState
                                        })
                                    } else {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState].filter((row) => row !== e.target.value)
                                            return newState
                                        })
                                    }
                                }} />
                                <FormControl.Label sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                }}>Video Conference</FormControl.Label>
                            </FormControl>


                        </Box>
                        <Box sx={{ flexBasis: 'calc(50% - 20px)' }}>
                            <FormControl>
                                <Checkbox id="default-checkbox" value={"Grievances"} checked={selectedCheckBox.includes("Grievances")} onChange={(e) => {
                                    if (e.target.checked) {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState, e.target.value]
                                            return newState
                                        })
                                    } else {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState].filter((row) => row !== e.target.value)
                                            return newState
                                        })
                                    }
                                }} />
                                <FormControl.Label sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                }}>Grievances</FormControl.Label>
                            </FormControl>


                        </Box>
                        <Box sx={{ flexBasis: 'calc(50% - 20px)' }}>
                            <FormControl>
                                <Checkbox id="default-checkbox" value={"Worklist"} checked={selectedCheckBox.includes("Worklist")} onChange={(e) => {
                                    if (e.target.checked) {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState, e.target.value]
                                            return newState
                                        })
                                    } else {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState].filter((row) => row !== e.target.value)
                                            return newState
                                        })
                                    }
                                }} />
                                <FormControl.Label sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                }}>Worklist</FormControl.Label>
                            </FormControl>
                          </Box>

                    </Box>



                    <Box

                        sx={{
                            position: 'absolute',
                            bottom: '52px',
                            left: '0px',
                            right: '0px',
                        }}
                        borderColor="border.default"
                        borderBottomWidth={1}
                        borderBottomStyle="solid">


                    </Box>





                </Box>

                <Box
                    sx={{
                        position: 'absolute',
                        bottom: '10px',
                        left: '20px',
                        right: '20px',

                    }}
                    display="flex"
                    flexDirection="row"
                    justifyContent={"right"}
                >


                    {
                        showLoadingBtn === true ?

                            <Button
                                variant="outline"
                                sx={Workplacetheme.createSpaceDialogFooterBox.submitBtn}
                            >
                                <Spinner sx={{
                                    position: 'absolute',
                                    left: '0px',
                                    right: '0px',
                                    margin: 'auto',
                                    top: '0px',
                                    bottom: '0px',
                                }} size="small" />
                            </Button>

                            :

                            <Button
                                style={{ width: '92px' }}
                                variant="outline"

                                sx={Workplacetheme.createSpaceDialogFooterBox.submitBtn}
                                onClick={() => {


                                    if (selectedCheckBox.length > 0) {
                                        showItSyncDialog("")
                                    } else {

                                    }

                                    // showGstSyncDialog("")
                                }
                                }
                            >

                                Sync now
                            </Button>


                    }






                </Box>





            </Dialog>

        )

    }


    React.useEffect(() => {

        if (dataSyncOpenClose === true) {

        }



    }, [dataSyncOpenClose])

    React.useEffect(() => {

        if (serverTrigger) {
            getSingleItportalFromDb("")
        }

    }, [serverTrigger])

    
    React.useEffect(() => {

        if (progressPercentage > 0 && !isInvalidPassword) {
            loadingToast(progressPercentage, "1")//
        }

    }, [progressPercentage])


    React.useState(() => {



        // getUserProductTour("")
        getSingleItportalFromDb("")

    })


    React.useState(() => {

        (function (c, l, a, r, i, t, y) {
            c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
            t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
            y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
        })(window, document, "clarity", "script", "ibtrxlrmkf");

    })


    return (
        <Box
            position={"relative"}
            display="flex"
            background={"#F6F8FA"}
            height="100vh"
            width={"100vw"}
            overflowY={"auto"}

        >
            <Helmet>
                <title>
                    {hostName === "www.app.taxherohq.com" || hostName === "www.get.taxherohq.com" ? 'IT - info - taxhero' : 'IT - info - taxhero'}
                </title>
            </Helmet>


            {
                hostName === "www.app.taxherohq.com"
                    || hostName === "www.get.taxherohq.com"
                    ?

                    <><TaxHeroHeader /></>
                    :
                    <><TaxHeroHeader /></>
            }


            <Box

                sx={{
                    top: '48px',
                    position: 'fixed',
                    left: hostName === "www.app.taxherohq.com" || hostName === "www.get.taxherohq.com" ? '0px' : '0px',
                    right: '0px',
                    minHeight: '48px',
                    maxHeight: '48px',
                    height: '48px',
                    background: customThemVariable.whiteBgColor,
                    display: 'flex',
                    borderBottom: '1px solid #E5E9EB',
                    zIndex: '1',
                    alignItems:"center"
                }}>

                <Text sx={{
                    marginTop: '12px',
                    marginLeft: '32px',
                    marginBottom: '15px',
                    color: customThemVariable.textgray,
                    fontSize: '14px',
                    fontFamily: customThemVariable.primartFont,
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '20px',
                    cursor: 'pointer'
                }}
                    onClick={() => {
                        hostName === "www.app.taxherohq.com"
                            ||
                            hostName === "www.get.taxherohq.com"
                            ?
                            navigate('/taxheroportal')
                            :
                            navigate('/portal')
                    }}
                >
                    Home
                </Text>

                <Box sx={{
                    marginLeft: '6px',
                    marginTop: '14px',
                    marginBottom: '18px',
                    color: '#B0BABF'
                }}>

                    <ChevronRightIcon />
                </Box>

                <Text
                    sx={{
                        marginTop: '12px',
                        marginLeft: '6px',
                        marginBottom: '15px',
                        color: customThemVariable.textgraydark,
                        fontSize: '14px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '20px',
                        cursor: 'pointer'
                    }}
                    onClick={() => {
                        navigate('/itpanlist')
                    }}
                >
                    IT
                </Text>

                <Box sx={{
                    marginLeft: '6px',
                    marginTop: '14px',
                    marginBottom: '18px',
                    color: '#B0BABF'
                }}>

                    <ChevronRightIcon />
                </Box>

                <Text
                    sx={{
                        marginTop: '12px',
                        marginLeft: '6px',
                        marginBottom: '15px',
                        color: customThemVariable.textgraydark,
                        fontSize: '14px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '20px',
                        cursor: 'pointer'
                    }}
                    onClick={() => { }}
                >
                    {allDataFeilds[0]?.pan_name.substring(0, 100)}

                </Text>

                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px', position: 'absolute', right: '122px' }}>
                    <Button
                        id="gstsyncBtn"
                        disabled={isDisabledSyncing}
                        variant="invisible"
                        leadingIcon={() => <Box sx={{ display: "flex" }}><UpdateIcon /></Box>}
                        sx={{
                            padding: '4px 8px 4px 8px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontFamily: customThemVariable.primartFont,
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '20px',
                        }}
                        onClick={() => {
                            handleSyncProfile("")
                        }}
                    >
                        Sync data
                    </Button>
                </Box>



            </Box>




            <Box
                id="profileDetails"
                sx={{
                    position: 'absolute',
                    left: '89px',
                    top: '112px',
                    right: '80px',
                    minHeight: '280px',
                    background: '#FFFFFF',
                    borderRadius: '3px',
                    border: '1px solid #D0D7DE'
                }}>

                <Box sx={{
                    position: 'absolute',
                    top: '15px',
                    left: '25px',
                    right: '25px',
                    minHeight: '36px',
                    maxHeight: '36px',
                    height: '36px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}>

                    <Text
                        className={customTheme.H3}
                        sx={{ color: customThemVariable.textgraydark }}>
                        {profileData?.pan}
                    </Text>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        position: 'absolute',
                        left: '282px',
                        alignItems: 'center'
                    }}>

                        <Text
                            className={customTheme.H3}
                            sx={{
                                color: customThemVariable.textgraydark
                            }}>
                            {allDataFeilds[0]?.pan_name.substring(0, 7)}
                        </Text>

                        <Text
                            className={customTheme.H4}
                            sx={{
                                marginLeft: '30px',
                                color: customThemVariable.textgray
                            }}>

                            {/* {profileData?.pan} */}
                            {allDataFeilds.length > 0 ? allDataFeilds[0]?.pan_number : ""}
                        </Text>
                        <Text
                            className={customTheme.H4}
                            sx={{
                                marginLeft: '20px',
                                color: customThemVariable.textgray
                            }}>

                            {allDataFeilds.length > 0 ? allDataFeilds[0]?.pan_password : ""}
                        </Text>

                    </Box>


                    <Text
                        sx={{
                            display: 'flex',
                            position: 'absolute',
                            right: '137px',
                            fontFamily: customThemVariable.primartFont,
                            fontWeight: '400',
                            fontSize: '12px',
                            lineHeight: '20px',
                            fontStyle: 'normal',
                            color: customThemVariable.textgraydark
                        }}
                    >
                        Last synced on : {

                            allDataFeilds.length > 0 ?
                                profileData == undefined ? "Not Synched" :
                                    "" + new Date(allDataFeilds[0]?.updated_timestamp).toDateString() + " " + new Date(allDataFeilds[0]?.updated_timestamp).toLocaleTimeString() + ""

                                : ""}
                    </Text>

                    <Button
                        id="syncBtn"
                        disabled={isDisabledSyncing}
                        variant="primary"
                        sx={{
                            height: '32px',
                            width: '100px',
                            position: 'absolute',
                            right: '0px',
                            top: '0px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontFamily: customThemVariable.primartFont,
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '20px',
                        }}
                        // onClick={() => {
                        //     showSyncDialog("")
                        // }}
                        onClick={() => { setisShowSyncDialog(true) }}
                    >
                        SYNC
                    </Button>

                </Box>







                <Box

                    sx={{ position: 'absolute', left: '25px', top: '67px', display: 'flex', flexDirection: 'column', gap: '10px' }}>

                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        Legal Name
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        Date of birth
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        Address
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        Primary email
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        Contact
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        PAN Status
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        Aadhaar status
                    </Text>

                </Box>

                <Box sx={{ position: 'absolute', left: '307px', top: '67px', display: 'flex', flexDirection: 'column', gap: '10px' }}>

                    <Box sx={
                        {
                            position: 'relative',
                            margin: '0px 0px 0px 0px',
                            display: 'flex',
                            minHeight: '20px',
                            gap: '10px',
                            alignItems: 'center'
                        }
                    }>
                        <Text sx={{
                            position: 'relative',
                            margin: '0px 0px 0px 0px',
                            fontFamily: customThemVariable.primartFont,
                            fontStyle: 'normal',
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '20px',
                            color: customThemVariable.textgraydark,
                            minHeight: '20px'
                        }}>
                            {profileData?.name}
                        </Text>
                        <Label sx={{
                            width: 'fit-content',
                            position: 'relative',
                            padding: '1px 8px 1px 8px',
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '18px',
                            color: '#365314',
                            borderRadius: '6px',
                            background: '#d9f99d',
                            border: 'none',
                            display: profileData?.roleDesc !== "" && profileData?.roleDesc !== undefined ? 'flex' : "none",
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Text sx={{}}>
                                {profileData?.roleDesc}
                            </Text>
                        </Label>

                    </Box>

                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px'
                    }}>

                        {profileData?.dob}

                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px'
                    }}>
                        {profileData?.address}


                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px'
                    }}>
                        {profileData?.email}
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px'
                    }}>
                        {profileData?.primaryMobile}
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgreen,
                        minHeight: '20px'
                    }}>
                        {profileData?.panStatus === "A" ? "Active" : profileData?.panStatus}

                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px'
                    }}>
                        {profileData?.adharStatus === "Y" ? "Linked" : profileData?.adharStatus || ''}
                    </Text>

                </Box>

            </Box>

            <Box
                id="profileDetails"
                sx={{
                    position: 'absolute',
                    left: '89px',
                    top: '410px',
                    right: '80px',
                    minHeight: '368px',
                    background: '#FFFFFF',
                    borderRadius: '3px',
                    border: '1px solid #D0D7DE'
                }}>

                <Box sx={{
                    position: 'absolute',
                    top: '15px',
                    left: '25px',
                    right: '25px',
                    minHeight: '36px',
                    maxHeight: '36px',
                    height: '36px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}>

                    <Text
                        className={customTheme.H3}
                        sx={{ color: customThemVariable.textgraydark }}>
                        Jurisdiction Details
                    </Text>

                </Box>







                <Box

                    sx={{ position: 'absolute', left: '25px', top: '67px', display: 'flex', flexDirection: 'column', gap: '10px' }}>

                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>

                        Jurisdiction
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>

                        Area Code
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>

                        AO Type
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>

                        AO Number
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>

                        Range Code
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>

                        Email Id
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>

                        Building Name
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>

                        Building ID
                    </Text>

                </Box>

                <Box sx={{ position: 'absolute', left: '307px', top: '67px', display: 'flex', flexDirection: 'column', gap: '10px' }}>



                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px'
                    }}>
                        {profileData?.jurisdictionData?.jurisdiction || ''}
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px'
                    }}>
                        {profileData?.jurisdictionData?.areaCode || ""}
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px'
                    }}>
                        {profileData?.jurisdictionData?.aoType || ""}

                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px'
                    }}>
                        {profileData?.jurisdictionData?.aoNumber || ""}

                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px'
                    }}>
                        {profileData?.jurisdictionData?.rangeCode || ""}

                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px'
                    }}>
                        {profileData?.jurisdictionData?.judictionmail || ""}

                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px'
                    }}>
                        {profileData?.jurisdictionData?.buildingName || ""}

                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px'
                    }}>
                        {profileData?.aoBldgId || ""}

                    </Text>

                </Box>

            </Box>

            {ITSyncDialogPlaywright("")}

            <AddPanDialog />
            <UploadPanDialog />
            <ChangePasswordITPanDialog />

        </Box>
    )

}

export default ItPlaywright