export default function assessMentYears(){
    return(
        [
            {
                "assment_year_cd": 2024,
                "assment_year_desc": "2024-25",
                "itr_mode": "ON"
            },
            {
                "assment_year_cd": 2023,
                "assment_year_desc": "2023-24",
                "itr_mode": "OF"
            },
            {
                "assment_year_cd": 2022,
                "assment_year_desc": "2022-23",
                "itr_mode": "OF"
            },
            {
                "assment_year_cd": 2021,
                "assment_year_desc": "2021-22",
                "itr_mode": "OF"
            },
            {
                "assment_year_cd": 2020,
                "assment_year_desc": "2020-21",
                "itr_mode": "OF"
            },
            {
                "assment_year_cd": 2019,
                "assment_year_desc": "2019-20",
                "itr_mode": "OF"
            },
            {
                "assment_year_cd": 2018,
                "assment_year_desc": "2018-19",
                "itr_mode": "OF"
            },
            {
                "assment_year_cd": 2017,
                "assment_year_desc": "2017-18",
                "itr_mode": "OF"
            },
            {
                "assment_year_cd": 2016,
                "assment_year_desc": "2016-17",
                "itr_mode": "OF"
            },
            {
                "assment_year_cd": 2015,
                "assment_year_desc": "2015-16",
                "itr_mode": "OF"
            },
            {
                "assment_year_cd": 2014,
                "assment_year_desc": "2014-15",
                "itr_mode": "OF"
            },
            {
                "assment_year_cd": 2013,
                "assment_year_desc": "2013-14",
                "itr_mode": "OF"
            },
            {
                "assment_year_cd": 2012,
                "assment_year_desc": "2012-13",
                "itr_mode": "OF"
            },
            {
                "assment_year_cd": 2011,
                "assment_year_desc": "2011-12",
                "itr_mode": "OF"
            },
            {
                "assment_year_cd": 2010,
                "assment_year_desc": "2010-11",
                "itr_mode": "OF"
            },
            {
                "assment_year_cd": 2009,
                "assment_year_desc": "2009-10",
                "itr_mode": "OF"
            },
            {
                "assment_year_cd": 2008,
                "assment_year_desc": "2008-09",
                "itr_mode": "OF"
            },
            {
                "assment_year_cd": 2007,
                "assment_year_desc": "2007-08",
                "itr_mode": "OF"
            }
        ]
    )
}