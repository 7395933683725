import { Box, Button, Breadcrumbs, Text, Label, Link } from "@primer/react"
import { UnderlineNav } from '@primer/react/drafts'
import { Helmet } from "react-helmet"
import EmptyData from "../../Space/EmptyData"
import Header from "../../Portal/Header"
import SideNav from "../../Portal/SideNav"
import { useDispatch, useSelector } from "react-redux"
import customTheme from "../../../Theme.module.scss"
import customThemVariable from "../../../ThemeVariable.module.scss"
import { useNavigate } from "react-router-dom"
import { ChevronRightIcon } from "@primer/octicons-react"
import { ArrowUpIcon } from "@primer/octicons-react"
import React from "react"
import TaxHeroHeader from "../../Portal/TaxHeroHeader"
import { getSingleItportal } from "../../redux/features/portalSlice"
import assessMentYears from "../Objects/assessMentYears"
import { Toaster, Intent, Position, ProgressBar } from "@blueprintjs/core";
import keyPersonDesig from "../Objects/keyPersonDesig"
import rStatus from "../Objects/rStatus"
import { UpdateIcon } from "@radix-ui/react-icons"
const synchingToast = Toaster.create({ position: Position.TOP })
const assessmentYearsData = assessMentYears()
const keyPersonDesigData = keyPersonDesig()
const rStatusData = rStatus()

function ItGrievancePlaywright(props) {

    let hostName = window.location.hostname
    let host = window.location.host

    let dispatch = useDispatch()
    const navigate = useNavigate()

    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail) || localStorage.getItem("userEmail")
    const insertId = localStorage.getItem("insert_id") || ""
    const [allDataFeilds, setallDataFeilds] = React.useState([])
    const [isDisabledSyncing, setisDisabledSyncing] = React.useState(false);
    const [selectedIndex, setSelectedIndex] = React.useState(0)
    const [items, setitems] = React.useState([{ navigation: 'Grievance' }])
    const [clientName, setclientName] = React.useState("")
    const [grievances, setgrievances] = React.useState([])
    const [grievancesdetails, setgrievancesdetails] = React.useState([])
    const [progressPercentage,setprogressPercentage] = React.useState(0)
    const [isInvalidPassword,setisInvalidPassword] = React.useState(false)
    
    const getGrievancesFromDb = (props) => {


        dispatch(getSingleItportal({
            userId: userId,
            userEmail: userEmail,
            insertId: insertId,
            serviceType: "Grievance"
        })).then((data) => {
            console.log("getSingleItportal", data.payload)
            setclientName(data?.payload?.data[0]?.pan_name)
            setallDataFeilds(data.payload.data)
            setgrievances(data?.payload?.data[0]?.playwright_itr_grievances)
            setgrievancesdetails(data?.payload?.data[0]?.playwright_itr_grievances_details)
        })

    }

    const loadingToast = async (progressPercentage, key) => {

        synchingToast.show({
            icon: "cloud-upload",
            message: <Box sx={{ marginTop: '4px' }}>
                <ProgressBar
                    animate={true}
                    stripes={true}
                    intent={progressPercentage < 100 ? Intent.PRIMARY : Intent.SUCCESS}
                    value={progressPercentage / 100}

                />
            </Box>

            ,
            onDismiss: (didTimeoutExpire) => {
                if (!didTimeoutExpire) {

                    synchingToast.dismiss()

                }
            },
            timeout: progressPercentage < 100 ? 0 : 3000,


        }, key);

    }

    const disableSyncButton = (props) => {
        setisDisabledSyncing(true);
        setTimeout(() => {
            setisDisabledSyncing(false);
        },
            30 * 60 * 1000
        );
    };

    const handleSyncKeyperson = async (props) => {

        disableSyncButton("")
      //  loadingToast(10, "1")
        setprogressPercentage(1)
        setTimeout(() => {
            if (!isInvalidPassword) {
                setprogressPercentage(15)
            }
        }, 2000)
        setTimeout(() => {

            if (!isInvalidPassword) {
                setprogressPercentage(30)
            }
        }, 5000)
        setTimeout(() => {

            if (!isInvalidPassword) {
                setprogressPercentage(60)
            }
        }, 15000)

        try {

            const insertId = allDataFeilds[0]?.insert_id
            const response = await fetch(`${process.env.REACT_APP_SCRAPSERVERURL}/syncitgrievances?insertId=${insertId}&checkboxes=${JSON.stringify(["Grievances"])}`);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const responseData = await response.json();
            const errorCode = responseData?.code
            if (errorCode === "401") {
                // synchingToast.dismiss()
                synchingToast.clear()
                Toaster.create({ position: Position.TOP }).show({
                    message: "Sync error: Invalid username or password.",
                    intent: Intent.PRIMARY
                });
                setprogressPercentage(100)
                setisInvalidPassword(true)

            }

            if (errorCode === "200") {
               // loadingToast(100, "1")
                setprogressPercentage(100)
            }
            console.log("plinggst", responseData)
            getGrievancesFromDb("")

        } catch (error) {


        }

    }


    const GrievancesComponent = (props) => {

        return (
            <Box sx={{
                position: 'absolute',
                top: '162px',
                left: '26px',
                right: '81px',
                minHeight: 'fit-content',
                display: 'flex',
                flexDirection: 'column',
                paddingBottom: '80px'
            }}>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    minHeight: '40px',
                    borderBottomWidth: 1,
                    borderBottomStyle: 'solid',
                    borderColor: '#E0E0E0',
                    alignItems: 'center',

                }}>
                    <Box sx={{

                        display: 'flex',
                        flexDirection: 'row',
                        minWidth: '15%',
                    }}>
                        <Text sx={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: '#84919A',
                            position: 'relative',
                            margin: '0px 12px 0px 12px',
                            minWidth: '80px'
                        }}>
                            ACCOUNT
                        </Text>
                    </Box>
                    <Box sx={{

                        display: 'flex',
                        flexDirection: 'row',
                        minWidth: '18%',
                    }}>
                        <Text sx={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: '#84919A',
                            position: 'relative',
                            margin: '0px 12px 0px 12px',
                            minWidth: '18%',
                        }}>

                            Acknowledgement No
                        </Text>
                    </Box>
                    <Box sx={{

                        display: 'flex',
                        flexDirection: 'row',
                        minWidth: '15%',

                    }}>
                        <Text sx={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: '#84919A',
                            position: 'relative',
                            margin: '0px 12px 0px 12px',
                            minWidth: '15%',
                        }}>
                           Department
                        </Text>
                    </Box>
                    <Box sx={{

                        display: 'flex',
                        flexDirection: 'row',
                        minWidth: '12%',

                    }}>
                        <Text sx={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: '#84919A',
                            position: 'relative',
                            margin: '0px 12px 0px 12px',
                            minWidth: '10%',
                        }}>
                           Category
                        </Text>
                    </Box>
                    <Box sx={{

                        display: 'flex',
                        flexDirection: 'row',
                        minWidth: '12%',

                    }}>
                        <Text sx={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: '#84919A',
                            position: 'relative',
                            margin: '0px 12px 0px 12px',
                            minWidth: '10%',
                        }}>
                           Sub Category
                        </Text>
                    </Box>
                    <Box sx={{

                        display: 'flex',
                        flexDirection: 'row',
                        minWidth: '12%',

                    }}>
                        <Text sx={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: '#84919A',
                            position: 'relative',
                            margin: '0px 12px 0px 12px',
                            minWidth: '10%',
                        }}>
                           Date loggee
                        </Text>
                    </Box>
                    <Box sx={{

                        display: 'flex',
                        flexDirection: 'row',
                        minWidth: '12%',

                    }}>
                        <Text sx={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: '#84919A',
                            position: 'relative',
                            margin: '0px 12px 0px 12px',
                            minWidth: '10%',
                        }}>
                           Status
                        </Text>
                    </Box>
                     

                </Box>
                {
                    grievances?.map((row, index) => {
                        return (
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                minHeight: '40px',
                                borderBottomWidth: 1,
                                borderBottomStyle: 'solid',
                                borderColor: '#E0E0E0',
                                alignItems: 'center',
                                background: `${customThemVariable.whiteBgColor}`,
                                ":hover": {
                                    background: 'rgba(234, 238, 242, 0.5)'
                                },
                                cursor: 'pointer'
                            }}
                            onClick={() => { 

                                  const grievancesExp = grievancesdetails.find((gData)=> gData.cpcAcknowledgementNumber===row?.TICKETID)
                                  console.log("grievancesExp",grievancesExp)
                                  localStorage.setItem("grievancesExp",JSON.stringify(grievancesExp))
                                  navigate('/grievancedetails')
                            }}
                            >
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minWidth: '15%',
                                    width: '15%',
                                    maxWidth: '15%'
                                }}>
                                    <Text sx={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '24px',
                                        color: '#252C32',
                                        position: 'relative',
                                        margin: '0px 12px 0px 12px',
                                        minWidth: '100%',
                                        maxWidth: '100%',
                                        width: '100%',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap'
                                    }}>
                                        {clientName}
                                    </Text>
                                </Box>

                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minWidth: '18%',
                                }}>
                                    <Text sx={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '24px',
                                        color: '#252C32',
                                        position: 'relative',
                                        margin: '0px 12px 0px 12px',
                                        minWidth: '18%',
                                    }}>
                                        {row?.TICKETID || ""}
                                    </Text>
                                </Box>

                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minWidth: '15%',
                                }}>
                                    <Text sx={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '24px',
                                        color: '#252C32',
                                        position: 'relative',
                                        margin: '0px 12px 0px 12px',
                                        minWidth: '15%',
                                    }}>
                                        {row?.CPCDEPARTMENTMODULE || ""}
                                    </Text>
                                </Box>

                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minWidth: '12%',
                                    maxWidth: '12%',


                                }}>
                                    <Label sx={{
                                        position: 'relative',
                                        margin: '0px 0px 0px 12px',
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        fontSize: '12px',
                                        lineHeight: '18px',
                                        color: '#000000',
                                        borderRadius: '6px',
                                        minWidth: 'auto',
                                        width: 'auto',
                                        maxWidth: 'auto',
                                        background: '#fef3c7',
                                        border: 'none',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        {row?.CPCCATEGORY || ""}
                                    </Label>

                                </Box>

                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minWidth: '12%',
                                }}>
                                    <Label sx={{
                                        position: 'relative',
                                        margin: '0px 0px 0px 12px',
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        fontSize: '12px',
                                        lineHeight: '18px',
                                        color: '#365314',
                                        borderRadius: '6px',
                                        minWidth: 'auto',
                                        width: 'auto',
                                        maxWidth: 'auto',
                                        background: '#dbeafe',
                                        border: 'none',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        {row?.CPCSUBCATEGORY || ""}
                                    </Label>
                                </Box>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minWidth: '12%',
                                }}>
                                    <Text sx={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '24px',
                                        color: '#252C32',
                                        position: 'relative',
                                        margin: '0px 12px 0px 12px',
                                        minWidth: '12%',
                                    }}>
                                        {new Date(row?.REPORTDATE)?.toDateString()?.substring(3,100) || ""}
                                    </Text>
                                </Box>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minWidth: '12%',
                                }}>
                                     <Label sx={{
                                        position: 'relative',
                                        margin: '0px 0px 0px 12px',
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        fontSize: '12px',
                                        lineHeight: '18px',
                                        color: '#365314',
                                        borderRadius: '6px',
                                        minWidth: 'auto',
                                        width: 'auto',
                                        maxWidth: 'auto',
                                        background: '#d9f99d',
                                        border: 'none',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        {row?.STATUS || ""}
                                    </Label>
                                </Box>
                            </Box>
                        )
                    })
                }





            </Box>
        )

    }

   


    React.useState(() => {
        getGrievancesFromDb("")
    })

    React.useEffect(() => {

        if (progressPercentage > 0 && !isInvalidPassword) {
            loadingToast(progressPercentage, "1")//
        }

    }, [progressPercentage])


    return (
        <Box
            position={"relative"}
            display="flex"
            background={"#F6F8FA"}
            height="100vh"
            width={"100vw"}
            overflowY={"auto"}

        >
            <Helmet>
                <title>
                    {hostName === "www.app.taxherohq.com" || hostName === "www.get.taxherohq.com" ? 'IT - Grievance - taxhero' : 'IT - Grievance - deski'}
                </title>
            </Helmet>

            {
                hostName === "www.app.taxherohq.com" || "www.get.taxherohq.com"

                    ?

                    <>

                        <TaxHeroHeader />
                    </>
                    :
                    <>
                        <Header />
                        <SideNav />
                    </>
            }


            <Box sx={{
                top: '48px',
                position: 'fixed',
                left: hostName === "www.app.taxherohq.com" || hostName === "www.get.taxherohq.com" ? '0px' : '64px',
                right: '0px',
                minHeight: '48px',
                maxHeight: '48px',
                height: '48px',
                background: customThemVariable.whiteBgColor,
                display: 'flex',
                borderBottom: '1px solid #E5E9EB',
                zIndex: '1',
                alignItems: "center"
            }}>

                <Text sx={{
                    marginTop: '12px',
                    marginLeft: '32px',
                    marginBottom: '15px',
                    color: customThemVariable.textgray,
                    fontSize: '14px',
                    fontFamily: customThemVariable.primartFont,
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '20px',
                    cursor: 'pointer'
                }}
                    onClick={() => {
                        hostName === "www.app.taxherohq.com"
                            ||
                            hostName === "www.get.taxherohq.com"
                            ?
                            navigate('/taxheroportal')
                            :
                            navigate('/portal')
                    }}
                >
                    Home
                </Text>

                <Box sx={{
                    marginLeft: '6px',
                    marginTop: '14px',
                    marginBottom: '18px',
                    color: '#B0BABF'
                }}>

                    <ChevronRightIcon />
                </Box>

                <Text
                    sx={{
                        marginTop: '12px',
                        marginLeft: '6px',
                        marginBottom: '15px',
                        color: customThemVariable.textgraydark,
                        fontSize: '14px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '20px',
                        cursor: 'pointer'
                    }}
                    onClick={() => {
                        navigate('/itpanlist')
                    }}
                >
                    IT
                </Text>

                <Box sx={{
                    marginLeft: '6px',
                    marginTop: '14px',
                    marginBottom: '18px',
                    color: '#B0BABF'
                }}>

                    <ChevronRightIcon />
                </Box>

                <Text
                    sx={{
                        marginTop: '12px',
                        marginLeft: '6px',
                        marginBottom: '15px',
                        color: customThemVariable.textgraydark,
                        fontSize: '14px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '20px',
                        cursor: 'pointer'
                    }}
                    onClick={() => { }}
                >
                    {localStorage.getItem("panName")}

                </Text>


                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px', position: 'absolute', right: '160px' }}>
                    <Button
                        id="gstsyncBtn"
                        disabled={isDisabledSyncing}
                        variant="invisible"
                        leadingIcon={() => <Box sx={{ display: "flex" }}><UpdateIcon /></Box>}
                        sx={{
                            padding: '4px 8px 4px 8px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontFamily: customThemVariable.primartFont,
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '20px',
                        }}
                        onClick={() => {
                            handleSyncKeyperson("")
                        }}
                    >
                        Sync data
                    </Button>

                </Box>


            </Box>


            <Box sx={{
                position: 'absolute',
                top: '48px',
                bottom: '0px',
                left: '64px',
                right: '0px',
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'auto',
                overflowX: 'hidden'


            }}>

                <Text sx={{
                    position: 'absolute',
                    left: '26px',
                    top: '47px',
                    fontFamily: 'Inter',
                    fontWeight: '600',
                    fontSize: '24px',
                    lineHeight: '48px',
                    color: '#252C32'
                }}>
                    Grievance
                </Text>

                <UnderlineNav
                    key={"" + Math.random(100, 10000) + ""}
                    aria-label="Repository"
                    sx={{
                        position: 'absolute',
                        left: '26px',
                        top: '97px',
                        right: '81px',
                        minHeight: 48,
                        maxHeight: 48,
                        height: 48,
                        fontFamily: 'Inter',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '24px',
                        color: '#252C32',
                        paddingLeft: '0px',

                    }} >
                    {items.map((item, index) => (

                        <UnderlineNav.Item
                            key={item.navigation}
                            aria-current={index === selectedIndex ? 'page' : undefined}
                            onSelect={e => {



                                setSelectedIndex(index)
                                e.preventDefault()


                            }}

                            selected={index === selectedIndex}
                        >
                            {item.navigation}
                        </UnderlineNav.Item>

                    ))}
                </UnderlineNav>

                <Box sx={{
                    position: 'absolute',
                    right: '81px',
                    top: '67px',
                    display: 'none',
                    flexDirection: 'row',
                    gap: '8px'
                }}>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}>
                        <Button
                            leadingIcon={() => <ArrowUpIcon />}
                            sx={{
                                width: '85px',
                                maxWidth: '85px',
                                minWidth: '85px',
                                height: '32px',
                                minHeight: '32px',
                                maxHeight: '32px',
                                borderRadius: '6px',
                                border: '1px solid #DDE2E4',
                                background: '#ffffff',
                                fontFamily: 'Inter',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '24px',
                                color: '#252C32',
                            }}>
                            Filter
                        </Button>
                        <Label
                            sx={{
                                position: 'absolute',
                                top: '-8px',
                                left: '70px',
                                background: '#F76659',
                                display: 'flex',
                                flexDirection: 'column',
                                padding: '1px 8px 1px 8px',
                                alignItems: 'center',
                                justifyContent: 'center',
                                border: 'none',

                            }}

                        >
                            <Box
                                sx={{
                                    minWidth: '5px',
                                    height: '18px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    color: '#FFFFFF',
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    fontSize: '12px',
                                    lineHeight: '18px',

                                }}
                            >
                                {"1"}
                            </Box>

                        </Label>
                    </Box>

                    <Button
                        leadingIcon={() => <ArrowUpIcon />}
                        sx={{
                            width: '96px',
                            maxWidth: '96px',
                            minWidth: '96px',
                            height: '32px',
                            minHeight: '32px',
                            maxHeight: '32px',
                            borderRadius: '6px',
                            border: '1px solid #DDE2E4',
                            background: '#ffffff',
                            fontFamily: 'Inter',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '24px',
                            color: '#252C32',
                        }}>
                        Export
                    </Button>
                </Box>

                {selectedIndex == 0 ? <GrievancesComponent /> : ""}
               


            </Box>




        </Box>
    )

}

export default ItGrievancePlaywright