import React from "react"
import { Box, Button, TextInput, Text, Heading } from "@primer/react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { updateUserOrganizationArray } from "../redux/features/contactSlice"
import { getUserOrganizationById } from "../redux/features/spaceSlice"
import { Toaster, Intent, Position } from "@blueprintjs/core";
import axios from "axios"
const stepOneImg = `${process.env.REACT_APP_S3BUCKETURL}/AccountSetup/step-one.svg`  
             
export default function PortalOrgSetup() {

    let hostName = window.location.hostname
    let host = window.location.host

    let dispatch = useDispatch()
    const navigate = useNavigate()

    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail) || localStorage.getItem("userEmail")

    const orgnNameinputRef = React.useRef(null)
    const [showOrgSetup, setshowOrgSetup] = React.useState(false)
    const [workplaceProjects, setworkplaceProjects] = React.useState([])

    const [organizationData, setorganizationData] = React.useState({
        orgName: "",
        orgValidationStatus: "",
        gstIn: "",
        gstInValidationStatus: "",
        addressLine1: "",
        addressLine1ValidationStatus: "",
        addressLine2: "",
        pin: "",
        pinValidationStatus: "",
        phone: "",
        phoneValidationStatus: ""

    })

    const onChaneOrgInputs = (props, e) => {

        //onChnage event when orgName input change 
        if (props === "orgName") {

            setorganizationData((prevSate) => {
                const newState = { ...prevSate, orgName: e.target.value, orgValidationStatus: '' }
                return newState
            })
            //Gst In input onchange
        } else if (props === "gstIn") {

            setorganizationData((prevSate) => {
                const newState = { ...prevSate, gstIn: e.target.value }
                return newState
            })
            //Addressline1 is changing
        } else if (props === "addressLine1") {

            setorganizationData((prevSate) => {
                const newState = { ...prevSate, addressLine1: e.target.value, addressLine1ValidationStatus: '' }
                return newState
            })
            //Addressline 2 is changing
        } else if (props === "addressLine2") {

            setorganizationData((prevSate) => {
                const newState = { ...prevSate, addressLine2: e.target.value }
                return newState
            })
            //changing pin input 
        } else if (props === "pin") {

           // const inputValue = e.target.value.replace(/[^0-9]/g, '');
            const inputValue = e.target.value //.replace(/[^0-9]/g, '');
            setorganizationData((prevSate) => {
                const newState = { ...prevSate, pin: inputValue, pinValidationStatus: '' }
                return newState
            })
            //changing phone input
        } else if (props === "phone") {

            const inputValue = e.target.value.replace(/[^0-9]/g, '');

            setorganizationData((prevSate) => {
                const newState = { ...prevSate, phone: inputValue, phoneValidationStatus: '' }
                return newState
            })

        }

    }
    // Organization created Track 
    const postHogTracking = async (userId, userEmail) => {

        if (userId !== null && userEmail !== null) {

            const postData = {
                api_key: `${process.env.REACT_APP_POSTHOG_APIKEY_TAXHERO}`,
                event: 'OrganizationCreated',
                properties: {
                    distinct_id: `${userId}`,
                    user_id: `${userId}`,
                    user_email: `${userEmail}`,
                },
                timestamp: new Date().toISOString(),
            };

            const apiUrl = `${process.env.REACT_APP_POSTHOG_APIURL}/` //'https://app.posthog.com/capture/';

            const result = await axios.post(apiUrl, postData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            return result;
        }


    }




    const updateOrgDataInDb = (props) => {

        //------- update Organazation data in users db as objects 

        //checking organisation name is Empty
        if (organizationData.orgName === "") {

            setorganizationData((prevSate) => {
                return { ...prevSate, orgValidationStatus: 'error' }
            })
            //checking addressline is empty
        }else if (organizationData.phone === "") {

            setorganizationData((prevSate) => {
                return { ...prevSate, phoneValidationStatus: 'error' }
            })

        } else if (organizationData.addressLine1 === "") {

            setorganizationData((prevSate) => {
                return { ...prevSate, addressLine1ValidationStatus: 'error' }
            })
            //checking organisation pin is empty  
        } else if (organizationData.pin === "") {

            setorganizationData((prevSate) => {
                return { ...prevSate, pinValidationStatus: 'error' }
            })
            //checking organisation phone is empty   
        } 
        else {

            dispatch(updateUserOrganizationArray({
                userId: userId,
                userEmail: userEmail,
                organizationArray: props,
                projectId: workplaceProjects[0]?.project_id,
                selectedCategory: [],
                selectedSubcategory: []

            })).then((data) => {

                const postHogTrackingResponse = postHogTracking(userId, userEmail)
                console.log("postHogTrackingResponse", postHogTrackingResponse)

                console.log("org update response", data)
                Toaster.create({ position: Position.TOP }).show({
                    message: "Successfully Setup",
                    intent: Intent.SUCCESS
                });
                //redirect to taxhero portal after successfull update
                navigate('/taxheroportal')

            })
        }





    }

    const handleCompleteOrgSetup = (props) => {

        //OrganisationData as objects 
        updateOrgDataInDb([{
            orgName: organizationData.orgName,
            gstIn: organizationData.gstIn,
            addressLine1: organizationData.addressLine1,
            addressLine2: organizationData.addressLine2,
            pin: organizationData.pin,
            phone: organizationData.phone,
            organizationId: workplaceProjects[0]?.project_id
        }
        ])

    }


    const getUserDefaults = (props) => {


        dispatch(getUserOrganizationById({
            userId: userId,
            userEmail: userEmail
        })).then((data) => {

            console.log("InPortalsetup", data.payload)
            setworkplaceProjects(data.payload.data)

        })

    }


    React.useState(() => {


        getUserDefaults("")


    })


    return (


        <Box

            sx={{
                position: 'relative',
                margin: '91px auto auto auto',
                height: '526px',
                maxHeight: '526px',
                maxWidth: '921px',
                minWidth: '921px',
                minHeight: '526px',
                width: '921px',
                overflow: 'auto',
                background: '#FFFFFF',
                border: '1px solid #D0D7DE',
                borderRadius: '3px',
                zIndex: '1',

            }}
        >

            <Box sx={{
                position: 'absolute',
                top: '35px',
                left: '40px',
                right: '40px',
                height: 'fit-content',
                display: 'flex',
                flexDirection: 'column',
                gap: '5px',
            }}>

                <Heading sx={{
                    fontFamily: 'Segoe UI',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '20px',
                    lineHeight: '30px',
                    color: '#24292E',
                    letterSpacing: '0.3px'
                }}>
                    {
                        "Setup your account"

                    }
                    
                </Heading>
                <Text sx={{
                    fontFamily: 'Segoe UI',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '12px',
                    lineHeight: '16px',
                    color: '#24292E',

                }}>
                    {
                        "Get started with your account setup in just a few easy steps."

                    }

                </Text>



            </Box>



            <Box
                sx={{
                    position: 'absolute',
                    left: '40px',
                    right: '40px',
                    top: '120px',
                    bottom: '126px',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '21px'
                }}
            >

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '18px'
                }}>

                    <TextInput
                        ref={orgnNameinputRef}
                        sx={{
                            width: '440px',
                            minWidth: '440px',
                            maxWidth: '440px',
                            minHeight: '40px',
                            maxHeight: '40px',
                            height: '40px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',

                        }}
                        value={organizationData.orgName}
                        validationStatus={organizationData.orgValidationStatus}
                        onChange={(e) => onChaneOrgInputs("orgName", e)}
                        placeholder="Enter your organization's name."
                    />

                    <TextInput
                        sx={{
                            width: '440px',
                            minWidth: '440px',
                            maxWidth: '440px',
                            minHeight: '40px',
                            maxHeight: '40px',
                            height: '40px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',

                        }}
                        value={organizationData.phone}
                        validationStatus={organizationData.phoneValidationStatus}
                        onChange={(e) => onChaneOrgInputs("phone", e)}
                        placeholder="Phone"
                        maxLength={10}
                        minLength={10}
                    />

                   

                    {/* <TextInput
                        sx={{
                            width: '440px',
                            minWidth: '440px',
                            maxWidth: '440px',
                            minHeight: '40px',
                            maxHeight: '40px',
                            height: '40px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',

                        }}
                        value={organizationData.gstIn}
                        onChange={(e) => onChaneOrgInputs("gstIn", e)}
                        placeholder="GSTIN"
                    /> */}

                    <TextInput
                        sx={{
                            width: '440px',
                            minWidth: '440px',
                            maxWidth: '440px',
                            minHeight: '40px',
                            maxHeight: '40px',
                            height: '40px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',

                        }}
                        validationStatus={organizationData.addressLine1ValidationStatus}
                        value={organizationData.addressLine1}
                        onChange={(e) => onChaneOrgInputs("addressLine1", e)}
                        placeholder="Address line 1"
                    />

                    <TextInput
                        sx={{
                            width: '440px',
                            minWidth: '440px',
                            maxWidth: '440px',
                            minHeight: '40px',
                            maxHeight: '40px',
                            height: '40px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',

                        }}
                        value={organizationData.addressLine2}
                        onChange={(e) => onChaneOrgInputs("addressLine2", e)}
                        placeholder="Address line 2 (optional)"
                    />

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px'
                    }}>

                        <TextInput
                            sx={{
                                width: '440px',
                                minWidth: '440px',
                                maxWidth: '440px',
                                minHeight: '40px',
                                maxHeight: '40px',
                                height: '40px',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',
                            }}
                            placeholder="City"
                            onChange={(e) => onChaneOrgInputs("pin", e)}
                            validationStatus={organizationData.pinValidationStatus}
                            value={organizationData.pin}
                        />

                        {/* <TextInput
                            sx={{
                                width: '215px',
                                minWidth: '215px',
                                maxWidth: '215px',
                                minHeight: '40px',
                                maxHeight: '40px',
                                height: '40px',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',
                            }}
                            placeholder="Phone"
                            value={organizationData.phone}
                            validationStatus={organizationData.phoneValidationStatus}
                            onChange={(e) => onChaneOrgInputs("phone", e)}
                            maxLength={10}
                            minLength={10}
                        /> */}



                    </Box>




                </Box>

                <Box>
                    <img src={stepOneImg} />
                </Box>



            </Box>


            <Box sx={{
                position: 'absolute',
                bottom: '30px',
                left: '30px',
                right: '30px',
                height: 'fit-content',
                display: 'flex',
                flexDirection: 'row'
            }}>
                <Button
                    variant="outline"
                    sx={{
                        position: 'relative',
                        margin: '0px 0px 0px auto',
                        height: '40px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: '#0969DA',
                        borderRadius: '3px',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        fontSize: '14px',
                        lineHeight: '20px',
                        border: 'none',
                        color: '#FFFFFF',
                        paddingLeft: '8px',
                        paddingRight: '8px'
                    }}
                    onClick={() => {
                        handleCompleteOrgSetup("")
                    }}
                >
                    Complete
                </Button>

            </Box>










        </Box>





    )

}