import React from "react"
import { Box, Button, Breadcrumbs, Text, Label, Link } from "@primer/react"
import { UnderlineNav } from '@primer/react/drafts'
import { Helmet } from "react-helmet"
import EmptyData from "../../Space/EmptyData"
import Header from "../../Portal/Header"
import SideNav from "../../Portal/SideNav"
import TaxHeroHeader from "../../Portal/TaxHeroHeader"
import { useDispatch, useSelector } from "react-redux"
import customTheme from "../../../Theme.module.scss"
import customThemVariable from "../../../ThemeVariable.module.scss"
import { useNavigate } from "react-router-dom"
import { ChevronRightIcon } from "@primer/octicons-react"
import { ArrowUpIcon } from "@primer/octicons-react"
import { getSingleGstportal } from "../../redux/features/portalSlice"
import { Toaster, Intent, Position, ProgressBar } from "@blueprintjs/core";
import { UpdateIcon } from "@radix-ui/react-icons"
const synchingToast = Toaster.create({ position: Position.TOP })

function GstAdditionalNoticePlaywright(props) {

    let hostName = window.location.hostname
    let host = window.location.host

    let dispatch = useDispatch()
    const navigate = useNavigate()

    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail) || localStorage.getItem("userEmail")

    const insertId = localStorage.getItem("insert_id") || ""
    const [items, setitems] = React.useState([{ navigation: 'Additional Notice' }, { navigation: 'Cases' }, { navigation: 'Intimation' }])
    const [selectedIndex, setSelectedIndex] = React.useState(0)
    const [allDataFeilds, setallDataFeilds] = React.useState([])
    const [noticeData, setnoticeData] = React.useState([])
    const [caseData, setcaseData] = React.useState([])
    const [caseFiles, setcaseFiles] = React.useState([])
    const [intimationOrders, setintimationOrders] = React.useState([])
    const [intimationFiles,setintimationFiles] = React.useState([])
    const [isDisabledSyncing, setisDisabledSyncing] = React.useState(false);
    const [progressPercentage,setprogressPercentage] = React.useState(0)
    const [isInvalidPassword,setisInvalidPassword] = React.useState(false)
    const [syncErrorCode,setsyncErrorCode] = React.useState("")

    const handleDownloadCaseFile = (docId, docName) => {


        if (docId !== null && docId !== "") {

            const fileUrl = caseFiles?.find((fData) => fData?.docId === docId)?.fileUrl

            if (fileUrl !== undefined && fileUrl !== "") {

                window.open(fileUrl, "_blank")
            }

        }


    }

    const handleDownloadIntimationFile = (docId, docName) => {


        if (docId !== null && docId !== "") {

            const fileUrl = intimationFiles?.find((fData) => fData?.docId === docId)?.fileUrl

            if (fileUrl !== undefined && fileUrl !== "") {

                window.open(fileUrl, "_blank")
            }

        }


    }

    const disableSyncButton = (props) => {
        setisDisabledSyncing(true);
        setTimeout(() => {
            setisDisabledSyncing(false);
        },
            //  10000
            30 * 60 * 1000
        );
    };

    const handleSyncAdditionalNoticeIntimation = async (props) => {

        disableSyncButton("")
       // loadingToast(10, "1")
        setprogressPercentage(1)
        setTimeout(() => {
            if (!isInvalidPassword) {
                setprogressPercentage(15)
            }
        }, 2000)
        setTimeout(() => {

            if (!isInvalidPassword) {
                setprogressPercentage(30)
            }
        }, 5000)
        setTimeout(() => {

            if (!isInvalidPassword) {
                setprogressPercentage(60)
            }
        }, 15000)
        try {

            const insertId = allDataFeilds[0]?.insert_id
            const response = await fetch(`${process.env.REACT_APP_SCRAPSERVERURL}/gstIntimationOrder?insertId=${insertId}&checkboxes=${JSON.stringify(["IntimationOrder"])}`);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const responseData = await response.json();
            const profileDataResponse = responseData?.profileData || []
            const errorCode = responseData?.code
            setsyncErrorCode(errorCode)
            if (errorCode === "401") {
                synchingToast.clear()
                Toaster.create({ position: Position.TOP }).show({
                    message: "Sync error: Invalid username or password.",
                    intent: Intent.PRIMARY
                });
                setprogressPercentage(100)
                setisInvalidPassword(true)
            }

            if (errorCode === "200") {
               // loadingToast(100, "1")
                setprogressPercentage(100)
            }
            console.log("plinggst", responseData)
            getGstAdditionalNoticeData("")

        } catch (error) {


        }


    }

    const handleSyncAdditionalNotice = async (props) => {

        disableSyncButton("")
       // loadingToast(10, "1")
       setprogressPercentage(1)
        setTimeout(() => {
            if (!isInvalidPassword) {
                setprogressPercentage(15)
            }
        }, 2000)
        setTimeout(() => {

            if (!isInvalidPassword) {
                setprogressPercentage(30)
            }
        }, 5000)
        setTimeout(() => {

            if (!isInvalidPassword) {
                setprogressPercentage(60)
            }
        }, 15000)
        setTimeout(() => {

            if (!isInvalidPassword) {
                setprogressPercentage(85)
            }
        }, 75000)
        try {

            const insertId = allDataFeilds[0]?.insert_id
            const response = await fetch(`${process.env.REACT_APP_SCRAPSERVERURL}/gstAdditionalnotices?insertId=${insertId}&checkboxes=${JSON.stringify(["Additionalnotices"])}`);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const responseData = await response.json();
            const profileDataResponse = responseData?.profileData || []
            const errorCode = responseData?.code
            setsyncErrorCode(errorCode)
            if (errorCode === "401") {
                synchingToast.clear()
                Toaster.create({ position: Position.TOP }).show({
                    message: "Sync error: Invalid username or password.",
                    intent: Intent.PRIMARY
                });
                setprogressPercentage(100)
                setisInvalidPassword(true)
            }

            if (errorCode === "200") {
                //loadingToast(100, "1")
                setprogressPercentage(100)
                
            }
            console.log("plinggst", responseData)
            getGstAdditionalNoticeData("")

        } catch (error) {


        }

    }

    const loadingToast = (progressPercentage, key) => {

        synchingToast.show({
            icon: "cloud-upload",
            message:

                <Box sx={{ marginTop: '4px' }}>
                    <ProgressBar
                        animate={true}
                        stripes={true}
                        intent={progressPercentage < 100 ? Intent.PRIMARY : Intent.SUCCESS}
                        value={progressPercentage / 100}

                    />
                </Box>

            ,
            onDismiss: (didTimeoutExpire) => {
                if (!didTimeoutExpire) {

                    synchingToast.dismiss()

                }
            },
            timeout: progressPercentage < 100 ? 0 : 3000,

        }, key);

    }

    const IntimationListComponent = (props) => {

        return (
            <Box sx={{
                position: 'absolute',
                top: '162px',
                left: '26px',
                right: '81px',
                minHeight: 'fit-content',
                display: 'flex',
                flexDirection: 'column',
                paddingBottom: '80px'
            }}>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    minHeight: '40px',
                    borderBottomWidth: 1,
                    borderBottomStyle: 'solid',
                    borderColor: '#E0E0E0',
                    alignItems: 'center',

                }}>
                    <Box sx={{

                        display: 'flex',
                        flexDirection: 'row',
                        minWidth: '15%',
                    }}>
                        <Text sx={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: '#84919A',
                            position: 'relative',
                            margin: '0px 12px 0px 12px',
                            minWidth: '80px'
                        }}>
                            ACCOUNT
                        </Text>
                    </Box>
                    <Box sx={{

                        display: 'flex',
                        flexDirection: 'row',
                        minWidth: '20%',

                    }}>
                        <Text sx={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: '#84919A',
                            position: 'relative',
                            margin: '0px 12px 0px 12px',
                            minWidth: '20%',
                        }}>
                            Type,CaseId,ReferenceId
                        </Text>
                    </Box>


                    <Box sx={{

                        display: 'flex',
                        flexDirection: 'row',
                        minWidth: '20%',
                        maxWidth: '20%',
                    }}>
                        <Text sx={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: '#84919A',
                            position: 'relative',
                            margin: '0px 12px 0px 12px',
                            minWidth: '20%',
                        }}>
                            Description
                        </Text>
                    </Box>

                    <Box sx={{

                        display: 'flex',
                        flexDirection: 'row',
                        minWidth: '14%',

                    }}>
                        <Text sx={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: '#84919A',
                            position: 'relative',
                            margin: '0px 12px 0px 12px',
                            minWidth: '10%',
                        }}>
                            Date
                        </Text>
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        minWidth: '10%',
                    }}>
                        <Text sx={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: '#84919A',
                            position: 'relative',
                            margin: '0px 12px 0px 12px',
                            minWidth: '10%',
                        }}>
                            Files
                        </Text>
                    </Box>


                </Box>
                {
                    intimationOrders?.map((row, index) => {

                        return (<>

                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                minHeight: '40px',
                                borderBottomWidth: 1,
                                borderBottomStyle: 'solid',
                                borderColor: '#E0E0E0',
                                alignItems: 'center',
                                background: `${customThemVariable.whiteBgColor}`,
                                ":hover": {
                                    background: 'rgba(234, 238, 242, 0.5)'
                                },
                                cursor: 'pointer',
                                paddingTop: '8px',
                                paddingBottom: '8px'
                            }}
                                onClick={() => {

                                }}
                            >
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minWidth: '15%',
                                    width: '14%',
                                    maxWidth: '14%',

                                }}>
                                    <Text sx={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '24px',
                                        color: '#252C32',
                                        position: 'relative',
                                        margin: '0px 12px 0px 12px',
                                        minWidth: '95%',
                                        maxWidth: '95%',
                                        width: '95%',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap'
                                    }}>
                                        {allDataFeilds.length > 0 ? allDataFeilds[0].pan_name : ""}
                                    </Text>
                                </Box>

                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    minWidth: '20%',
                                    gap: '4px'
                                }}>

                                    <Label sx={{
                                        margin: '0px 12px 0px 12px',
                                        position: 'relative',
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        fontSize: '12px',
                                        color: '#252C32',
                                        borderRadius: '6px',
                                        background: '#ffedd5',
                                        border: 'none',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        padding: '2px 8px 2px 8px',
                                        width: 'fit-content'
                                    }}>
                                        <Text sx={{}}>
                                            {row?.caseName}
                                        </Text>
                                    </Label>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            gap: '4px',
                                            margin: '0px 12px 0px 12px',
                                        }}
                                    >
                                        <Label sx={{
                                            position: 'relative',
                                            fontFamily: 'Inter',
                                            fontStyle: 'normal',
                                            fontWeight: '600',
                                            fontSize: '12px',
                                            color: '#000000',
                                            borderRadius: '6px',
                                            background: '#cffafe',
                                            border: 'none',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            padding: '2px 8px 2px 8px',
                                            width: 'fit-content'
                                        }}>
                                            <Text sx={{
                                                marginLeft: '4px'
                                            }}>
                                                {row?.caseId}
                                            </Text>
                                        </Label>
                                        <Text sx={{
                                            fontFamily: 'Inter',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            fontSize: '14px',
                                            lineHeight: '24px',
                                            color: '#252C32',
                                            position: 'relative',
                                            minWidth: '14%',
                                        }}>
                                            {row?.arn}
                                        </Text>
                                    </Box>


                                </Box>

                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    minWidth: '20%',
                                    maxWidth: '20%',
                                    gap: '4px',

                                }}>
                                    <Text sx={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '24px',
                                        color: '#252C32',
                                        position: 'relative',
                                        margin: '0px 12px 0px 12px',
                                        minWidth: '100%',
                                        maxWidth: '100%',
                                        width: '100%',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap'
                                    }}>

                                        {row?.statusDesc}
                                    </Text>


                                </Box>


                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    minWidth: '14%',
                                }}>

                                    <Text sx={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '24px',
                                        color: '#252C32',
                                        position: 'relative',
                                        margin: '0px 12px 0px 12px',
                                        minWidth: '100%',
                                        maxWidth: '100%',
                                        width: '100%',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap'
                                    }}>
                                        
                                        Issue Date :
                                        {
                                            row?.caseCreationDate}
                                    </Text>

                                    <Text sx={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '24px',
                                        color: '#252C32',
                                        position: 'relative',
                                        margin: '0px 12px 0px 12px',
                                        minWidth: '100%',
                                        maxWidth: '100%',
                                        width: '100%',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap'
                                    }}>
                                        
                                        
                                    </Text>

                                </Box>

                                <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            minWidth: '10%',

                                        }}>
                                            {
                                                row?.appItem?.itemJson?.vp?.pysum?.dcupdtls.map((sData, index) => {

                                                    const docId = sData?.id
                                                    const docName = sData?.docName || sData?.docttl

                                                    return (<>
                                                        <Link
                                                            sx={{
                                                                fontFamily: 'Segoe UI',
                                                                fontStyle: 'normal',
                                                                fontWeight: '400',
                                                                fontSize: '14px',
                                                                lineHeight: '24px',
                                                                position: 'relative',

                                                            }}
                                                            href="#"
                                                            onClick={() => {
                                                                   handleDownloadIntimationFile(docId, docName)
                                                            }}
                                                        >{docName}</Link>
                                                    </>)
                                                })
                                            }
                                            
                                            
                                            </Box>



                            </Box>



                        </>)

                    })
                }





            </Box>
        )

    }

    const NoticeListComponent = (props) => {

        return (
            <Box sx={{
                position: 'absolute',
                top: '162px',
                left: '26px',
                right: '81px',
                minHeight: 'fit-content',
                display: 'flex',
                flexDirection: 'column',
                paddingBottom: '80px'
            }}>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    minHeight: '40px',
                    borderBottomWidth: 1,
                    borderBottomStyle: 'solid',
                    borderColor: '#E0E0E0',
                    alignItems: 'center',

                }}>
                    <Box sx={{

                        display: 'flex',
                        flexDirection: 'row',
                        minWidth: '15%',

                    }}>
                        <Text sx={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: '#84919A',
                            position: 'relative',
                            margin: '0px 12px 0px 12px',
                            minWidth: '80px'
                        }}>
                            ACCOUNT
                        </Text>
                    </Box>
                    <Box sx={{

                        display: 'flex',
                        flexDirection: 'row',
                        minWidth: '16%',

                    }}>
                        <Text sx={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: '#84919A',
                            position: 'relative',
                            margin: '0px 12px 0px 12px',
                            minWidth: '16%',
                        }}>
                            Type
                        </Text>
                    </Box>
                    <Box sx={{

                        display: 'flex',
                        flexDirection: 'row',
                        minWidth: '14%',

                    }}>
                        <Text sx={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: '#84919A',
                            position: 'relative',
                            margin: '0px 12px 0px 12px',
                            minWidth: '10%',
                        }}>
                            RefId
                        </Text>
                    </Box>

                    <Box sx={{

                        display: 'flex',
                        flexDirection: 'row',
                        minWidth: '38%',

                    }}>
                        <Text sx={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: '#84919A',
                            position: 'relative',
                            margin: '0px 12px 0px 12px',
                            minWidth: '38%',
                        }}>
                            Description
                        </Text>
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        minWidth: '10%',
                    }}>
                        <Text sx={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: '#84919A',
                            position: 'relative',
                            margin: '0px 12px 0px 12px',
                            minWidth: '10%',
                        }}>
                            Issued date
                        </Text>
                    </Box>


                </Box>
                {
                    noticeData?.map((row, index) => {
                        return (
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                minHeight: '40px',
                                borderBottomWidth: 1,
                                borderBottomStyle: 'solid',
                                borderColor: '#E0E0E0',
                                alignItems: 'center',
                                background: `${customThemVariable.whiteBgColor}`,
                                ":hover": {
                                    background: 'rgba(234, 238, 242, 0.5)'
                                },
                                cursor: 'pointer'
                            }}
                                onClick={() => {

                                }}
                            >
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minWidth: '15%',
                                    width: '14%',
                                    maxWidth: '14%',

                                }}>
                                    <Text sx={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '24px',
                                        color: '#252C32',
                                        position: 'relative',
                                        margin: '0px 12px 0px 12px',
                                        minWidth: '95%',
                                        maxWidth: '95%',
                                        width: '95%',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap'
                                    }}>
                                        {allDataFeilds.length > 0 ? allDataFeilds[0].pan_name : ""}
                                    </Text>
                                </Box>

                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minWidth: '16%',

                                }}>
                                    <Text sx={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '24px',
                                        color: '#252C32',
                                        position: 'relative',
                                        margin: '0px 12px 0px 12px',
                                        minWidth: '16%',
                                    }}>
                                        {row?.type}
                                    </Text>
                                </Box>

                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minWidth: '14%',
                                }}>
                                    <Text sx={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '24px',
                                        color: '#252C32',
                                        position: 'relative',
                                        margin: '0px 12px 0px 12px',
                                        minWidth: '14%',
                                    }}>
                                        {row?.refId}
                                    </Text>
                                </Box>

                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minWidth: '38%',
                                    maxWidth: '38%',


                                }}>
                                    <Text sx={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '24px',
                                        color: '#252C32',
                                        position: 'relative',
                                        margin: '0px 12px 0px 12px',
                                        minWidth: '100%',
                                        maxWidth: '100%',
                                        width: '100%',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap'
                                    }}>
                                        {row?.description}
                                    </Text>
                                </Box>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minWidth: '10%',

                                }}>
                                    <Text sx={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '24px',
                                        color: '#252C32',
                                        position: 'relative',
                                        margin: '0px 12px 0px 12px',
                                        minWidth: '10%',
                                    }}>
                                        {row?.issuedDate}
                                    </Text>

                                </Box>


                            </Box>
                        )
                    })
                }





            </Box>
        )

    }

    const CaseListComponent = (props) => {

        return (
            <Box sx={{
                position: 'absolute',
                top: '162px',
                left: '26px',
                right: '81px',
                minHeight: 'fit-content',
                display: 'flex',
                flexDirection: 'column',
                paddingBottom: '80px'
            }}>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    minHeight: '40px',
                    borderBottomWidth: 1,
                    borderBottomStyle: 'solid',
                    borderColor: '#E0E0E0',
                    alignItems: 'center',

                }}>
                    <Box sx={{

                        display: 'flex',
                        flexDirection: 'row',
                        minWidth: '15%',
                    }}>
                        <Text sx={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: '#84919A',
                            position: 'relative',
                            margin: '0px 12px 0px 12px',
                            minWidth: '80px'
                        }}>
                            ACCOUNT
                        </Text>
                    </Box>
                    <Box sx={{

                        display: 'flex',
                        flexDirection: 'row',
                        minWidth: '20%',

                    }}>
                        <Text sx={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: '#84919A',
                            position: 'relative',
                            margin: '0px 12px 0px 12px',
                            minWidth: '20%',
                        }}>
                            Type,CaseId,ReferenceId
                        </Text>
                    </Box>


                    <Box sx={{

                        display: 'flex',
                        flexDirection: 'row',
                        minWidth: '20%',
                        maxWidth: '20%',
                    }}>
                        <Text sx={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: '#84919A',
                            position: 'relative',
                            margin: '0px 12px 0px 12px',
                            minWidth: '20%',
                        }}>
                            Description
                        </Text>
                    </Box>

                    <Box sx={{

                        display: 'flex',
                        flexDirection: 'row',
                        minWidth: '14%',

                    }}>
                        <Text sx={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: '#84919A',
                            position: 'relative',
                            margin: '0px 12px 0px 12px',
                            minWidth: '10%',
                        }}>
                            Date
                        </Text>
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        minWidth: '10%',
                    }}>
                        <Text sx={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: '#84919A',
                            position: 'relative',
                            margin: '0px 12px 0px 12px',
                            minWidth: '10%',
                        }}>
                            Files
                        </Text>
                    </Box>


                </Box>
                {
                    caseData?.map((row, index) => {

                        const fItemArray = row?.fItemArray || []
                        return (
                            fItemArray.map((row2, index) => {


                                const supportDoc = row2?.itemJson?.sdtls?.srscn?.suppdocs ||
                                    row2?.itemJson?.sdtls?.remnd?.suppdocs ||
                                    row2?.itemJson?.sdtls?.dtscn?.suppdocs ||
                                    row2?.itemJson?.reply?.suppdocs ||
                                    row2?.itemJson?.sdtls?.drprcdt?.suppdocs ||
                                    row2?.itemJson?.enfDtls?.mov07?.suppdocs ||
                                    row2?.itemJson?.sdtls?.suppdocs ||
                                    row2?.itemJson?.orddtls?.mov02?.suppdocs ||
                                    row2?.itemJson?.orddtls?.mov06?.suppdocs ||
                                    row2?.itemJson?.orddtls?.mov09?.suppdocs ||
                                    row2?.itemJson?.orddtls?.mov05?.suppdocs ||
                                    row2?.itemJson?.sdtls?.acklgvp?.suppdocs ||
                                    row2?.itemJson?.sdtls?.drprcsr?.suppdocs ||
                                    row2?.itemJson?.sdtls?.dtorder?.suppdocs ||
                                    row2?.itemJson?.sdtls?.drprcvp?.suppdocs ||
                                    []

                                const mainDocsArray = row2?.itemJson?.sdtls?.srscn?.maindocs ||
                                    row2?.itemJson?.sdtls?.remnd?.maindocs ||
                                    row2?.itemJson?.sdtls?.dtscn?.maindocs ||
                                    row2?.itemJson?.reply?.maindocs ||
                                    row2?.itemJson?.sdtls?.drprcdt?.maindocs ||
                                    row2?.itemJson?.enfDtls?.mov07?.maindocs ||
                                    row2?.itemJson?.sdtls?.maindocs ||
                                    row2?.itemJson?.orddtls?.mov02?.maindocs ||
                                    row2?.itemJson?.orddtls?.mov06?.maindocs ||
                                    row2?.itemJson?.orddtls?.mov09?.maindocs ||
                                    row2?.itemJson?.orddtls?.mov05?.maindocs ||
                                    row2?.itemJson?.maindocs ||
                                    row2?.itemJson?.sdtls?.acklgvp?.maindocs ||
                                    row2?.itemJson?.sdtls?.drprcsr?.maindocs ||
                                    row2?.itemJson?.sdtls?.dtorder?.maindocs ||
                                    row2?.itemJson?.sdtls?.drprcvp?.maindocs ||
                                    []

                                const annexDocArray = row2?.itemJson?.sdtls?.srscn?.annxdocs ||
                                    row2?.itemJson?.sdtls?.remnd?.annxdocs ||
                                    row2?.itemJson?.sdtls?.dtscn?.annxdocs ||
                                    row2?.itemJson?.reply?.annxdocs ||
                                    row2?.itemJson?.enfDtls?.mov07?.annxdocs ||
                                    row2?.itemJson?.sdtls?.dtorder?.annxdocs ||
                                    []

                                const dcupdtlsArray = row2?.itemJson?.pysum?.dcupdtls ||
                                    row2?.itemJson?.vp?.pysum?.dcupdtls ||
                                    row2?.itemJson?.docupdtl ||
                                    []

                                const factdocsArray = row2?.itemJson?.sdtls?.factdocs || []


                                return (
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        minHeight: '40px',
                                        padding: '4px 0px 4px 0px',
                                        borderBottomWidth: 1,
                                        borderBottomStyle: 'solid',
                                        borderColor: '#E0E0E0',
                                        alignItems: 'center',
                                        background: `${customThemVariable.whiteBgColor}`,
                                        ":hover": {
                                            background: 'rgba(234, 238, 242, 0.5)'
                                        },
                                        cursor: 'pointer'
                                    }}
                                        onClick={() => {

                                        }}
                                    >
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            minWidth: '15%',
                                            width: '14%',
                                            maxWidth: '14%',

                                        }}>
                                            <Text sx={{
                                                fontFamily: 'Inter',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                fontSize: '14px',
                                                lineHeight: '24px',
                                                color: '#252C32',
                                                position: 'relative',
                                                margin: '0px 12px 0px 12px',
                                                minWidth: '95%',
                                                maxWidth: '95%',
                                                width: '95%',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap'
                                            }}>
                                                {allDataFeilds.length > 0 ? allDataFeilds[0].pan_name : ""}
                                            </Text>
                                        </Box>

                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            minWidth: '20%',
                                            gap: '4px'
                                        }}>

                                            <Label sx={{
                                                margin: '0px 12px 0px 12px',
                                                position: 'relative',
                                                fontFamily: 'Inter',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                                fontSize: '12px',
                                                color: '#252C32',
                                                borderRadius: '6px',
                                                background: '#ffedd5',
                                                border: 'none',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                padding: '2px 8px 2px 8px',
                                                width: 'fit-content'
                                            }}>
                                                <Text sx={{}}>
                                                    {row?.caseTypeName}
                                                </Text>
                                            </Label>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    gap: '4px',
                                                    margin: '0px 12px 0px 12px',
                                                }}
                                            >
                                                <Label sx={{
                                                    // margin: '0px 12px 0px 12px',
                                                    position: 'relative',
                                                    fontFamily: 'Inter',
                                                    fontStyle: 'normal',
                                                    fontWeight: '600',
                                                    fontSize: '12px',
                                                    color: '#000000',
                                                    borderRadius: '6px',
                                                    background: '#cffafe',
                                                    border: 'none',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    padding: '2px 8px 2px 8px',
                                                    width: 'fit-content'
                                                }}>
                                                    <Text sx={{
                                                        marginLeft: '4px'
                                                    }}>
                                                        {row?.caseId}
                                                    </Text>
                                                </Label>
                                                <Text sx={{
                                                    fontFamily: 'Inter',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    fontSize: '14px',
                                                    lineHeight: '24px',
                                                    color: '#252C32',
                                                    position: 'relative',
                                                    // margin: '0px 12px 0px 12px',
                                                    minWidth: '14%',
                                                }}>
                                                    {row2?.refId}
                                                </Text>
                                            </Box>


                                        </Box>

                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            minWidth: '20%',
                                            maxWidth: '20%',
                                            gap: '4px',

                                        }}>
                                            <Text sx={{
                                                fontFamily: 'Inter',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                fontSize: '14px',
                                                lineHeight: '24px',
                                                color: '#252C32',
                                                position: 'relative',
                                                margin: '0px 12px 0px 12px',
                                                minWidth: '100%',
                                                maxWidth: '100%',
                                                width: '100%',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap'
                                            }}>

                                                {
                                                    row2?.itemJson?.sdtls?.srscn?.type ||
                                                    row2?.itemJson?.sdtls?.remnd?.type ||
                                                    row2?.itemJson?.reply?.replyty ||
                                                    row2?.itemJson?.sdtls?.drprcdt?.type ||
                                                    row2?.itemJson?.sdtls?.dtscn?.type ||
                                                    row2?.itemJson?.enfDtls?.mov07?.ntcTp ||
                                                    row2?.itemJson?.sdtls?.prcedtyp ||
                                                    row2?.itemJson?.ordtyp ||

                                                    ""}
                                            </Text>


                                        </Box>

                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            minWidth: '14%',
                                        }}>

                                            <Text sx={{
                                                fontFamily: 'Inter',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                fontSize: '14px',
                                                lineHeight: '24px',
                                                color: '#252C32',
                                                position: 'relative',
                                                margin: '0px 12px 0px 12px',
                                                minWidth: '100%',
                                                maxWidth: '100%',
                                                width: '100%',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap'
                                            }}>
                                                {/* Due date */}
                                                Due date :
                                                {
                                                    row2?.itemJson?.sdtls?.srscn?.duedt ||
                                                    row2?.itemJson?.sdtls?.remnd?.duedt ||
                                                    row2?.itemJson?.reply?.duedt ||
                                                    row2?.itemJson?.sdtls?.drprcdt?.duedt ||
                                                    row2?.itemJson?.sdtls?.dtscn?.duedt ||
                                                    row2?.itemJson?.enfDtls?.mov07?.ntcDuDt ||
                                                    ""}
                                            </Text>

                                            <Text sx={{
                                                fontFamily: 'Inter',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                fontSize: '14px',
                                                lineHeight: '24px',
                                                color: '#252C32',
                                                position: 'relative',
                                                margin: '0px 12px 0px 12px',
                                                minWidth: '100%',
                                                maxWidth: '100%',
                                                width: '100%',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap'
                                            }}>
                                                {/* {date of order} */}
                                                Date of Order :
                                                {
                                                    row2?.itemJson?.sdtls?.refdt ||
                                                    row2?.itemJson?.refdt ||
                                                    ""}
                                            </Text>
                                            <Text sx={{
                                                fontFamily: 'Inter',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                fontSize: '14px',
                                                lineHeight: '24px',
                                                color: '#252C32',
                                                position: 'relative',
                                                margin: '0px 12px 0px 12px',
                                                minWidth: '100%',
                                                maxWidth: '100%',
                                                width: '100%',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap'
                                            }}>
                                                {/* {date of order} */}
                                                Case Date :
                                                {
                                                    row2?.itemJson?.sdtls?.refdt ||

                                                    ""}
                                            </Text>
                                            <Text sx={{
                                                fontFamily: 'Inter',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                fontSize: '14px',
                                                lineHeight: '24px',
                                                color: '#252C32',
                                                position: 'relative',
                                                margin: '0px 12px 0px 12px',
                                                minWidth: '100%',
                                                maxWidth: '100%',
                                                width: '100%',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap'
                                            }}>

                                                Hearing Date :
                                                {
                                                    row2?.itemJson?.sdtls?.refdt ||
                                                    row2?.itemJson?.sdtls?.remnd?.phdt ||
                                                    ""}
                                            </Text>
                                            <Text sx={{
                                                fontFamily: 'Inter',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                fontSize: '14px',
                                                lineHeight: '24px',
                                                color: '#252C32',
                                                position: 'relative',
                                                margin: '0px 12px 0px 12px',
                                                minWidth: '100%',
                                                maxWidth: '100%',
                                                width: '100%',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap'
                                            }}>

                                                Date of replay :
                                                {
                                                    row2?.itemJson?.sdtls?.refdt ||
                                                    row2?.updateDate ||
                                                    ""}
                                            </Text>

                                            <Text sx={{
                                                fontFamily: 'Inter',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                fontSize: '14px',
                                                lineHeight: '24px',
                                                color: '#252C32',
                                                position: 'relative',
                                                margin: '0px 12px 0px 12px',
                                                minWidth: '100%',
                                                maxWidth: '100%',
                                                width: '100%',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap'
                                            }}>

                                                Issue Date :
                                                {
                                                    row2?.itemJson?.refdt ||
                                                    row2?.insertDate ||
                                                    ""}
                                            </Text>

                                        </Box>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            minWidth: '10%',

                                        }}>
                                            {
                                                supportDoc?.map((sData, index) => {

                                                    const docId = sData?.dcupdtls?.id
                                                    const hash = sData?.dcupdtls?.hash
                                                    const docName = sData?.dcupdtls?.docName

                                                    return (<>
                                                        <Link
                                                            sx={{
                                                                fontFamily: 'Segoe UI',
                                                                fontStyle: 'normal',
                                                                fontWeight: '400',
                                                                fontSize: '14px',
                                                                lineHeight: '24px',
                                                                position: 'relative',

                                                            }}
                                                            href="#"
                                                            onClick={() => {
                                                                handleDownloadCaseFile(docId, docName)
                                                            }}
                                                        >{docName}</Link>
                                                    </>)
                                                })
                                            }
                                            {
                                                mainDocsArray?.map((sData, index) => {

                                                    const docId = sData?.dcupdtls?.id
                                                    const hash = sData?.dcupdtls?.hash
                                                    const docName = sData?.dcupdtls?.docName
                                                    const ct = sData?.dcupdtls?.ct


                                                    return (<>
                                                        <Link
                                                            sx={{
                                                                fontFamily: 'Segoe UI',
                                                                fontStyle: 'normal',
                                                                fontWeight: '400',
                                                                fontSize: '14px',
                                                                lineHeight: '24px',
                                                                position: 'relative',

                                                            }}
                                                            href="#"
                                                            onClick={() => {
                                                                handleDownloadCaseFile(docId, docName)
                                                            }}
                                                        >{docName}</Link>
                                                    </>)
                                                })
                                            }
                                            {
                                                annexDocArray?.map((sData, index) => {

                                                    const docId = sData?.dcupdtls?.id
                                                    const hash = sData?.dcupdtls?.hash
                                                    const docName = sData?.dcupdtls?.docName
                                                    const ct = sData?.dcupdtls?.ct

                                                    return (<>
                                                        <Link
                                                            sx={{
                                                                fontFamily: 'Segoe UI',
                                                                fontStyle: 'normal',
                                                                fontWeight: '400',
                                                                fontSize: '14px',
                                                                lineHeight: '24px',
                                                                position: 'relative',

                                                            }}
                                                            href="#"
                                                            onClick={() => {
                                                                handleDownloadCaseFile(docId, docName)
                                                            }}
                                                        >{docName}</Link>
                                                    </>)
                                                })
                                            }
                                            {
                                                dcupdtlsArray?.map((sData, index) => {

                                                    const docId = sData?.id
                                                    const hash = sData?.hash
                                                    const docName = sData?.docttl || sData?.docName
                                                    const ct = sData?.ct

                                                    return (<>
                                                        <Link
                                                            sx={{
                                                                fontFamily: 'Segoe UI',
                                                                fontStyle: 'normal',
                                                                fontWeight: '400',
                                                                fontSize: '14px',
                                                                lineHeight: '24px',
                                                                position: 'relative',

                                                            }}
                                                            href="#"
                                                            onClick={() => {
                                                                handleDownloadCaseFile(docId, docName)
                                                            }}
                                                        >{docName}</Link>
                                                    </>)
                                                })
                                            }
                                            {
                                                factdocsArray?.map((sData, index) => {

                                                    const docId = sData?.dcupdtls?.id
                                                    const hash = sData?.dcupdtls?.hash
                                                    const docName = sData?.dcupdtls?.docName || sData?.dcupdtls?.docttl
                                                    const ct = sData?.dcupdtls?.ct

                                                    return (<>
                                                        <Link
                                                            sx={{
                                                                fontFamily: 'Segoe UI',
                                                                fontStyle: 'normal',
                                                                fontWeight: '400',
                                                                fontSize: '14px',
                                                                lineHeight: '24px',
                                                                position: 'relative',

                                                            }}
                                                            href="#"
                                                            onClick={() => {
                                                                handleDownloadCaseFile(docId, docName)
                                                            }}
                                                        >{docName}</Link>
                                                    </>)
                                                })
                                            }



                                        </Box>


                                    </Box>
                                )

                            })
                        )





                    })
                }





            </Box>
        )

    }

    const getGstAdditionalNoticeData = (props) => {


        dispatch(getSingleGstportal({
            userId: userId,
            userEmail: userEmail,
            insertId: insertId,
            serviceType:"AdditionalNotice"
        })).then((data) => {

            console.log("getGstLedgerData", data.payload)
            setallDataFeilds(data?.payload?.data)
            setnoticeData(data?.payload.data[0]?.playwright_gst_additional_notices)
            setcaseData(data?.payload.data[0]?.playwright_gst_additional_notices_details)
            setcaseFiles(data?.payload.data[0]?.playwright_gst_case_files)
            setintimationOrders(data?.payload.data[0]?.playwright_gst_intimations)
            setintimationFiles(data?.payload.data[0]?.playwright_gst_intimations_files)
        })

    }




    React.useState(() => {
        getGstAdditionalNoticeData("")
    })

    React.useEffect(() => {

        if (progressPercentage > 0 && !isInvalidPassword) {
            loadingToast(progressPercentage, "1")//
        }

        if(progressPercentage==100){

            setTimeout(()=>{
                synchingToast.dismiss()
                synchingToast.clear()
            },2000)
            setisDisabledSyncing(false)
            
        }  

        if(syncErrorCode==="200"||syncErrorCode=="401"){

            setTimeout(()=>{
                synchingToast.dismiss()
                synchingToast.clear()
            },2000)
            setisDisabledSyncing(false)
        }

        

    }, [progressPercentage])

    return (
        <Box
            position={"relative"}
            display="flex"
            background={"#F6F8FA"}
            height="100vh"
            width={"100vw"}
            overflowY={"auto"}

        >
            <Helmet>
                <title>
                    {hostName === "www.app.taxherohq.com" || hostName === "www.get.taxherohq.com" ? 'GST - Additional Notice - taxhero' : 'GST - Additional Notice - deski'}
                </title>
            </Helmet>

            {
                hostName === "www.app.taxherohq.com" ||
                    hostName === "www.get.taxherohq.com"

                    ?

                    <>

                        <TaxHeroHeader />
                    </>
                    :
                    <>
                        <Header />
                        <SideNav />
                    </>
            }


            <Box sx={{
                top: '48px',
                position: 'fixed',
                left: hostName === "www.app.taxherohq.com" || hostName === "www.get.taxherohq.com" ? '0px' : '64px',
                right: '0px',
                minHeight: '48px',
                maxHeight: '48px',
                height: '48px',
                background: customThemVariable.whiteBgColor,
                display: 'flex',
                borderBottom: '1px solid #E5E9EB',
                zIndex: '1'
            }}>

                <Text sx={{
                    marginTop: '12px',
                    marginLeft: '32px',
                    marginBottom: '15px',
                    color: customThemVariable.textgray,
                    fontSize: '14px',
                    fontFamily: customThemVariable.primartFont,
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '20px',
                    cursor: 'pointer'
                }}
                    onClick={() => {
                        hostName === "www.app.taxherohq.com"
                            ||
                            hostName === "www.get.taxherohq.com"
                            ?
                            navigate('/taxheroportal')
                            :
                            navigate('/portal')
                    }}
                >
                    Home
                </Text>

                <Box sx={{
                    marginLeft: '6px',
                    marginTop: '14px',
                    marginBottom: '18px',
                    color: '#B0BABF'
                }}>

                    <ChevronRightIcon />
                </Box>

                <Text
                    sx={{
                        marginTop: '12px',
                        marginLeft: '6px',
                        marginBottom: '15px',
                        color: customThemVariable.textgraydark,
                        fontSize: '14px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '20px',
                        cursor: 'pointer'
                    }}
                    onClick={() => {
                        navigate('/gstpanlist')
                    }}
                >
                    GST
                </Text>

                <Box sx={{
                    marginLeft: '6px',
                    marginTop: '14px',
                    marginBottom: '18px',
                    color: '#B0BABF'
                }}>

                    <ChevronRightIcon />
                </Box>

                <Text
                    sx={{
                        marginTop: '12px',
                        marginLeft: '6px',
                        marginBottom: '15px',
                        color: customThemVariable.textgraydark,
                        fontSize: '14px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '20px',
                        cursor: 'pointer'
                    }}
                    onClick={() => { }}
                >
                    {localStorage.getItem("gstpanName")}
                </Text>

                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px', position: 'absolute', top: '9px', right: '160px' }}>
                    <Button
                        id="gstsyncBtn"
                        disabled={isDisabledSyncing}
                        variant="invisible"
                        leadingIcon={()=><Box sx={{display:"flex"}}><UpdateIcon/></Box>}  
                        sx={{
                            padding: '4px 8px 4px 8px',
                            display: selectedIndex==0 || selectedIndex==1?'flex':'none',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontFamily: customThemVariable.primartFont,
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '20px',
                        }}
                        onClick={() => { handleSyncAdditionalNotice("") }}
                    > Sync data 
                    </Button>
                    <Button
                        id="gstsyncBtn"
                        disabled={isDisabledSyncing}
                        variant="invisible"
                        leadingIcon={()=><Box sx={{display:"flex"}}><UpdateIcon/></Box>}  
                        sx={{
                            padding: '4px 8px 4px 8px',
                            display: selectedIndex==2?'flex':'none',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontFamily: customThemVariable.primartFont,
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '20px',
                        }}
                        onClick={() => { handleSyncAdditionalNoticeIntimation("") }}
                    >
                        Sync data 
                    </Button>

                </Box>


            </Box>

            

            <Box sx={{
                position: 'absolute',
                top: '48px',
                bottom: '0px',
                left: '64px',
                right: '0px',
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'auto',
                overflowX: 'hidden'
            }}>

                <Text sx={{
                    position: 'absolute',
                    left: '26px',
                    top: '47px',
                    fontFamily: 'Inter',
                    fontWeight: '600',
                    fontSize: '24px',
                    lineHeight: '48px',
                    color: '#252C32'
                }}>
                    Additional Notice
                </Text>

                <UnderlineNav
                    key={"" + Math.random(100, 10000) + ""}
                    aria-label="Repository"
                    sx={{
                        position: 'absolute',
                        left: '26px',
                        top: '97px',
                        right: '81px',
                        minHeight: 48,
                        maxHeight: 48,
                        height: 48,
                        fontFamily: 'Inter',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '24px',
                        color: '#252C32',
                        paddingLeft: '0px',

                    }} >
                    {items.map((item, index) => (

                        <UnderlineNav.Item
                            key={item.navigation}
                            aria-current={index === selectedIndex ? 'page' : undefined}
                            onSelect={e => {



                                setSelectedIndex(index)
                                e.preventDefault()


                            }}

                            selected={index === selectedIndex}
                        >
                            {item.navigation}
                        </UnderlineNav.Item>

                    ))}
                </UnderlineNav>

                <Box sx={{
                    position: 'absolute',
                    right: '81px',
                    top: '67px',
                    display: 'none',
                    flexDirection: 'row',
                    gap: '8px'
                }}>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}>
                        <Button
                            leadingIcon={() => <ArrowUpIcon />}
                            sx={{
                                width: '85px',
                                maxWidth: '85px',
                                minWidth: '85px',
                                height: '32px',
                                minHeight: '32px',
                                maxHeight: '32px',
                                borderRadius: '6px',
                                border: '1px solid #DDE2E4',
                                background: '#ffffff',
                                fontFamily: 'Inter',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '24px',
                                color: '#252C32',
                            }}>
                            Filter
                        </Button>
                        <Label
                            sx={{
                                position: 'absolute',
                                top: '-8px',
                                left: '70px',
                                background: '#F76659',
                                display: 'flex',
                                flexDirection: 'column',
                                padding: '1px 8px 1px 8px',
                                alignItems: 'center',
                                justifyContent: 'center',
                                border: 'none',

                            }}

                        >
                            <Box
                                sx={{
                                    minWidth: '5px',
                                    height: '18px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    color: '#FFFFFF',
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    fontSize: '12px',
                                    lineHeight: '18px',

                                }}
                            >
                                {"1"}
                            </Box>

                        </Label>
                    </Box>

                    <Button
                        leadingIcon={() => <ArrowUpIcon />}
                        sx={{
                            width: '96px',
                            maxWidth: '96px',
                            minWidth: '96px',
                            height: '32px',
                            minHeight: '32px',
                            maxHeight: '32px',
                            borderRadius: '6px',
                            border: '1px solid #DDE2E4',
                            background: '#ffffff',
                            fontFamily: 'Inter',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '24px',
                            color: '#252C32',
                        }}>
                        Export
                    </Button>
                </Box>


                {selectedIndex == 0 ? <NoticeListComponent /> : ""}
                {selectedIndex == 1 ? <CaseListComponent /> : ""}
                {selectedIndex == 2 ? <IntimationListComponent /> : ""}



            </Box>






        </Box>
    )

}

export default GstAdditionalNoticePlaywright