import { Box, Button, Breadcrumbs, Text, Heading, StyledOcticon, IconButton, Dialog, TextInput, Link, Spinner, Label } from "@primer/react"
import { Helmet } from "react-helmet"
import Header from "../../Portal/Header"
import SideNav from "../../Portal/SideNav"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import React from "react"
import customTheme from "../../../Theme.module.scss"
import customThemVariable from "../../../ThemeVariable.module.scss"
import { ArrowLeftIcon, CheckCircleFillIcon, CheckCircleIcon, CheckIcon, CopyIcon } from "@primer/octicons-react"
import { ExternalLinkIcon } from "@radix-ui/react-icons"
import { getItPortalByItrId } from "../../redux/features/portalSlice"
import { Toaster, Intent, Position } from "@blueprintjs/core";
import TaxHeroHeader from "../../Portal/TaxHeroHeader"
import assessMentYears from "../Objects/assessMentYears"
import itrFilingSecArray from "../Objects/filingSections"
import filingTypeArray from "../Objects/filingTypeArray"
import itrTypeArray from "../Objects/itrTypeArray"
const assessmentYearsData = assessMentYears();
const filingSectionData = itrFilingSecArray()
const filingTypeData = filingTypeArray()
const itrTypeData = itrTypeArray()

function ItrExpansionPlaywright(props) {

    let hostName = window.location.hostname
    let host = window.location.host
    let dispatch = useDispatch()
    const navigate = useNavigate()
    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail) || localStorage.getItem("userEmail")
    const clientId = localStorage.getItem("client_id") || ""
    const insertId = localStorage.getItem("insert_id") || ""
    // const itrId = localStorage.getItem("itr_id") || ""
    // const ackNumber = localStorage.getItem("ackNumber") || ""
    const pageRoute = localStorage.getItem("pageRoute") || ""
    
    const [expansionData,setexpansionData] = React.useState(JSON.parse(localStorage.getItem("expData")))
    const [expFileData,setexpFileData] = React.useState(JSON.parse(localStorage.getItem("expFileData"))|| [])
    
    const passwordRef = React.useRef(null)
    const [allDetails, setallDetails] = React.useState([])
    const [profileData, setprofileData] = React.useState([])
    const [fileditrs, setfileditrs] = React.useState([])
    const [timeLineArray, settimeLineArray] = React.useState([])
    const [pdfPassword, setpdfPassword] = React.useState("")
    const [itrFiles, setitrFiles] = React.useState({
        formUrl: '',
        receiptUrl: '',
        orders: []
    })
  
    const handledCopyPassword = (props) => {

        if (passwordRef.current) {

            navigator.clipboard.writeText(passwordRef.current.innerText)
                .then(() => {

                    Toaster.create({ position: Position.TOP }).show({
                        message: "Copied",
                        intent: Intent.SUCCESS
                    });

                }).catch((error) => {

                });

        }
    }

    
    const getItPortalByItrIdFromDb = (props) => {


       

        dispatch(getItPortalByItrId({
            userId: userId,
            userEmail: userEmail,
            insertId: insertId,
        })).then((data) => {

            console.log("getItPortalByItrId", data.payload)
            setallDetails(data?.payload?.data)
            setprofileData(data?.payload?.data[0]?.playwright_itr_profile)
            
            // setitrFiles((prevState)=>{
            //       return({...prevState,
            //         formUrl:itrArray[0]?.formUrl,
            //         receiptUrl:itrArray[0]?.receiptUrl,
            //         orders:itrArray[0]?.orderArray
            //        })
            // })
            const rawDate = new Date(data?.payload?.data[0]?.playwright_itr_profile[0]?.dob);
            let day = rawDate.getDate();
            day = (day < 10 ? '0' : '') + day;  // Add leading zero if day is less than 10
            let month = rawDate.getMonth() + 1;
            month = (month < 10 ? '0' : '') + month;  // Add leading zero if month is less than 10
            const year = rawDate.getFullYear();
            const dateOfBirth = `${day}${month}${year}`;
            setpdfPassword(data?.payload?.data[0]?.pan_number.toLowerCase() + "" + dateOfBirth)

           
            
        })

    }

      


    React.useState(() => {

        console.log("expansion",expansionData)
        if(expansionData!==undefined&&expansionData!==""){
            const updatedArray = expansionData?.itrPanDetlList.map((row, index) => {
                if (row?.activityTxt !== undefined && row?.activityTxt !== "") {

                    try {
                        return { ...row, activityTxt: JSON.parse(row.activityTxt) };
                    } catch (error) {
                        // If parsing fails, return activityTxt as it is
                        return { ...row, activityTxt: row.activityTxt };
                    }
                } else {
                    return ({ ...row })
                }
            })
            settimeLineArray(updatedArray)
            // const updatedArray = expansionData?.itrPanDetlList.map((row,index)=>{
            //       if(row?.activityTxt!==undefined&&row?.activityTxt!==""){
            //          return({...row,activityTxt:JSON.parse(row?.activityTxt)})
            //       }else{
            //          return({...row})
            //       }
            // })
            // settimeLineArray(updatedArray)
        }
        getItPortalByItrIdFromDb("")

    })

    React.useState(() => {

        (function (c, l, a, r, i, t, y) {
            c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
            t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
            y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
        })(window, document, "clarity", "script", "ibtrxlrmkf");

    })



    return (
        <Box
            position={"relative"}
            display="flex"
            background={"#F6F8FA"}
            height="100vh"
            width={"100vw"}
            overflowY={"auto"}

        >
            <Helmet>
                <title>
                    {hostName === "www.app.taxherohq.com" || hostName === "www.get.taxherohq.com" ? 'ITR expansion - taxhero' : 'ITR expansion - deski'}
                </title>
            </Helmet>

            {
                hostName === "www.app.taxherohq.com" || hostName === "www.get.taxherohq.com"

                    ?

                    <>

                        <TaxHeroHeader />
                    </>
                    :
                    <>
                        <Header />
                        <SideNav />
                    </>
            }

            <Box
                sx={{
                    position: 'absolute',
                    top: '48px',
                    bottom: '0px',
                    left: '64px',
                    right: '0px',
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'scroll',
                    overflowX: 'hidden',

                }}>
                {/* Header section */}
                <Box sx={{
                    position: 'absolute',
                    left: '26px',
                    top: '37px',
                    right: '61px',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '60px'
                }}>
                    <Text
                        className={customTheme.H5}
                        sx={{ color: customThemVariable.secondaryTextColor }}
                    >
                        {allDetails[0]?.pan_name}
                    </Text>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '13px',
                        marginTop: '4px',
                        minHeight: '36px',
                        maxHeight: '36px',
                        height: '36px',
                        alignItems: 'center'
                    }}>
                        <Text
                            className={customTheme.H3}
                            sx={{
                                color: customThemVariable.primaryTextColor
                            }}
                        >
                            {profileData.length > 0 ? profileData[0]?.name : ''}
                        </Text>
                        <Text
                            className={customTheme.H4}
                            sx={{ color: customThemVariable.secondaryTextColor }}
                        >
                            {profileData.length > 0 ? profileData[0]?.pan : ''}
                        </Text>
                        <Label sx={{
                            fontFamily: customThemVariable.primartFont,
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: customThemVariable.labelTextColorsecondary,
                            borderRadius: '4px',
                            minHeight: '18px',
                            maxHeight: '18px',
                            height: '18px',
                            background: customThemVariable.labelBgSecondary,
                            border: 'none',
                            display:expansionData?.verStatus==="Y"? 'flex':'none',
                            alignItems: 'center',
                        }}>
                            <Text sx={{ display: 'flex' }}>
                                Verified
                            </Text>
                            <Box
                                sx={{
                                    display: 'flex',
                                    color: customThemVariable.labelTertiaryTextColor,
                                    marginLeft: '4px'
                                }}>
                                <CheckIcon size={12} />
                            </Box>
                        </Label>

                    </Box>


                    <Button
                        leadingIcon={() => <Box>
                            <ArrowLeftIcon />
                        </Box>
                        }
                        variant="invisible"
                        sx={{
                            fontFamily: customThemVariable.primartFont,
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '20px',
                            position: 'absolute',
                            right: '64px',
                            bottom: '0px',
                            minWidth: '95px',
                            maxWidth: '95px',
                            width: '95px',
                            height: '32px',
                            minHeight: '32px',
                            maxHeight: '32px'

                        }}
                        onClick={() => {
                            if (pageRoute === "taxheroPortal") {
                                localStorage.setItem("pageRoute", "")
                                navigate('/taxheroportal')
                            } else {
                                localStorage.setItem("pageRoute", "")
                                navigate('/ItReturn')
                               
                            }
                        }}
                    >
                        Return
                    </Button>



                </Box>

                {/*  horizontal line */}

                <Box sx={{
                    position: 'absolute',
                    left: '26px',
                    right: '61px',
                    top: '109px',
                    height: '1px',
                    background: customThemVariable.borderColor
                }}>

                </Box>


                {/* -------------------- */}


                {/* ITR Details Section 1 */}
                <Box sx={{
                    position: 'absolute',
                    left: '26px',
                    top: '120px',
                    right: '61px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '24px',
                }}>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '144px',
                        maxWidth: '144px',
                        width: '144px'
                    }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                            }}
                        >
                            Acknowledgement no
                        </Text>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.primaryTextColor,
                                minHeight: '20px'
                            }}
                        >
                            {expansionData?.ackNum}

                        </Text>
                    </Box>

                    <Box sx={{
                        height: '32px',
                        minHeight: '32px',
                        maxHeight: '32px',
                        width: '1px',
                        minWidth: '1px',
                        maxWidth: '1px',
                        background: customThemVariable.borderColor
                    }}
                    ></Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '144px',
                        maxWidth: '144px',
                        width: '144px'
                    }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                            }}
                        >
                            Assessment year
                        </Text>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.primaryTextColor,
                                minHeight: '20px'
                            }}
                        >
                            AY
                            {expansionData?.assmentYear !== "" && expansionData?.assmentYear !== undefined ?

                                " " + assessmentYearsData.find((data) => "" + data.assment_year_cd + "" === expansionData?.assmentYear)?.assment_year_desc

                                : ""}
                        </Text>
                    </Box>
                    <Box sx={{
                        height: '32px',
                        minHeight: '32px',
                        maxHeight: '32px',
                        width: '1px',
                        minWidth: '1px',
                        maxWidth: '1px',
                        background: customThemVariable.borderColor
                    }}
                    ></Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '158px',
                        maxWidth: '158px',
                        width: '158px'
                    }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                            }}
                        >
                            Acknowledgement date
                        </Text>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.primaryTextColor,
                                minHeight: '20px'
                            }}
                        >

                            {new Date(expansionData?.ackDt).toDateString().substring(3, 30)}

                        </Text>
                    </Box>
                    <Box sx={{
                        height: '32px',
                        minHeight: '32px',
                        maxHeight: '32px',
                        width: '1px',
                        minWidth: '1px',
                        maxWidth: '1px',
                        background: customThemVariable.borderColor
                    }}
                    ></Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '144px',
                        maxWidth: '144px',
                        width: '144px'
                    }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                            }}
                        >
                            Form type
                        </Text>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.primaryTextColor,
                                minHeight: '20px'
                            }}
                        >
                            {expansionData?.formTypeCd !== "" && expansionData?.formTypeCd !== undefined ?

                                " " + itrTypeData.find((data) => data.key === expansionData?.formTypeCd).value

                                : ""}
                        </Text>
                    </Box>
                    <Box sx={{
                        height: '32px',
                        minHeight: '32px',
                        maxHeight: '32px',
                        width: '1px',
                        minWidth: '1px',
                        maxWidth: '1px',
                        background: customThemVariable.borderColor
                    }}
                    ></Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '144px',
                        maxWidth: '144px',
                        width: '144px'
                    }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                                minHeight: '20px'
                            }}
                        >
                            Filing type
                        </Text>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.primaryTextColor,
                                minHeight: '20px'
                            }}
                        >
                            {expansionData?.filingTypeCd !== "" && expansionData?.filingTypeCd !== undefined ?

                                " " + filingTypeData.find((data) => data.key === expansionData?.filingTypeCd)?.display

                                : ""}
                        </Text>
                    </Box>
                    <Box sx={{
                        height: '32px',
                        minHeight: '32px',
                        maxHeight: '32px',
                        width: '1px',
                        minWidth: '1px',
                        maxWidth: '1px',
                        background: customThemVariable.borderColor
                    }}
                    ></Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '144px',
                        maxWidth: '144px',
                        width: '144px'
                    }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                            }}
                        >
                            Filing section
                        </Text>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.primaryTextColor,
                                minHeight: '20px'
                            }}
                        >
                            {expansionData?.incmTaxSecCd !== "" && expansionData?.incmTaxSecCd !== undefined ?

                                " " + filingSectionData.find((data) => data.key === expansionData?.incmTaxSecCd)?.value

                                : ""}
                        </Text>
                    </Box>
                </Box>
                {/* ---------------------- */}


                {/* ITR Details Section 1 */}

                <Box sx={{
                    position: 'absolute',
                    left: '26px',
                    top: '182px',
                    right: '61px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '24px',
                }}>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '144px',
                        maxWidth: '144px',
                        width: '144px'
                    }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                            }}
                        >
                            Refund claimed
                        </Text>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.primaryTextColor,
                                minHeight: '20px'
                            }}
                        >
                            {expansionData?.refundAmt!=="null"&&expansionData?.refundAmt!==undefined ? expansionData?.refundAmt : ""}
                        </Text>
                    </Box>

                    <Box sx={{
                        height: '32px',
                        minHeight: '32px',
                        maxHeight: '32px',
                        width: '1px',
                        minWidth: '1px',
                        maxWidth: '1px',
                        background: customThemVariable.borderColor
                    }}
                    ></Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '144px',
                        maxWidth: '144px',
                        width: '144px'
                    }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                            }}
                        >
                            Computed refunds
                        </Text>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.primaryTextColor,
                                minHeight: '20px'
                            }}
                        >
                            
                            {

                                timeLineArray.length > 0 && timeLineArray.filter((tData) => tData.statusDesc === "Refund Paid").length > 0
                                    ?
                                    timeLineArray.filter((tData) => tData.statusDesc === "Refund Paid")[0]?.activityTxt?.computedRefndAmt
                                    : ""

                            }  
                        </Text>
                    </Box>
                    <Box sx={{
                        height: '32px',
                        minHeight: '32px',
                        maxHeight: '32px',
                        width: '1px',
                        minWidth: '1px',
                        maxWidth: '1px',
                        background: customThemVariable.borderColor
                    }}
                    ></Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '158px',
                        maxWidth: '158px',
                        width: '158px'
                    }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                            }}
                        >
                            Paid refund
                        </Text>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.primaryTextColor,
                                minHeight: '20px'
                            }}
                        >
                            {

                                timeLineArray.length > 0 && timeLineArray.filter((tData) => tData.statusDesc === "Refund Paid").length > 0
                                    ?
                                    timeLineArray.filter((tData) => tData.statusDesc === "Refund Paid")[0]?.activityTxt?.refundAmt
                                    : ""
                            }  
                        </Text>
                    </Box>
                    <Box sx={{
                        height: '32px',
                        minHeight: '32px',
                        maxHeight: '32px',
                        width: '1px',
                        minWidth: '1px',
                        maxWidth: '1px',
                        background: customThemVariable.borderColor
                    }}
                    ></Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '144px',
                        maxWidth: '144px',
                        width: '144px'
                    }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                            }}
                        >
                            Refund  date
                        </Text>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.primaryTextColor,
                                minHeight: '20px'
                            }}
                        >
                            {

                                timeLineArray.length > 0 && timeLineArray.filter((tData) => tData.statusDesc === "Refund Paid").length > 0
                                    ?
                                    new Date(timeLineArray.filter((tData) => tData.statusDesc === "Refund Paid")[0]?.activityTxt?.clearanceDt.replace("IST", "GMT+0530")).toDateString().substring(3, 30)
                                    : ""
                            }  

                            


                        </Text>
                    </Box>



                </Box>

                {/* ----------------------- */}

                {/* Timeline section ---- */}

                <Box sx={{
                    position: 'absolute',
                    left: '26px',
                    top: '276px',
                    right: '61px',
                    display: 'flex',
                    flexDirection: 'column',

                }}>
                    <Text
                        className={customTheme.H3}
                        sx={{ color: customThemVariable.primaryTextColor }}
                    >
                        Timeline
                    </Text>

                    <Box sx={{
                        marginTop: '7px',
                        width: '964px',
                        minWidth: '964px',
                        maxWidth: '964px',
                        height: '1px',
                        background: customThemVariable.borderColor
                    }}>
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '964px',
                        minWidth: '964px',
                        maxWidth: '964px',
                    }}>
                        {timeLineArray.map((row, index) => {
                            return (
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minHeight: '60px',
                                    height: '60px',
                                    maxHeight: '60px',
                                    borderBottom: `1px solid ${customThemVariable.borderColor}`
                                }}>

                                    <Box sx={{
                                        marginTop: '11px'

                                    }}>
                                        <StyledOcticon size={12} color="#1EA672" icon={CheckCircleFillIcon} />
                                    </Box>

                                    <Box sx={{
                                        marginTop: '11px',
                                        marginLeft: '16px',
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}>
                                        <Text
                                            className={customTheme.textnormal}
                                            sx={{
                                                color: customThemVariable.primaryTextColor
                                            }}
                                        >{row?.statusDesc}</Text>
                                        <Text
                                            className={customTheme.textnormal}
                                            sx={{
                                                fontSize: '12px',
                                                color: customThemVariable.secondaryTextColor
                                            }}
                                        >{new Date(row?.activityDt).toDateString()}</Text>
                                    </Box>

                                </Box>
                            )
                        })}

                    </Box>
                    {/* ITR Files Section -------------- */}
                    <Text
                        className={customTheme.H3}
                        sx={{
                            color: customThemVariable.primaryTextColor,
                            marginTop: '35px'
                        }}
                    >
                        Files
                    </Text>

                    <Box sx={{
                        marginTop: '7px',
                        width: '964px',
                        minWidth: '964px',
                        maxWidth: '964px',
                        height: '1px',
                        background: customThemVariable.borderColor
                    }}>
                    </Box>

                    <Box sx={{
                        marginTop: '13px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '15px'
                    }}>

                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px', minHeight: '20px', maxHeight: '20px', height: '20px', alignItems: 'center' }}>

                            <Text
                                className={customTheme.textnormal}
                                sx={{ color: customThemVariable.primaryTextColor }}
                            >
                                PDF Password
                            </Text>

                            <Text
                                className={customTheme.textnormal}
                                sx={{ color: customThemVariable.primaryTextColor}}
                                ref={passwordRef}
                            >
                                {pdfPassword}
                            </Text>

                            <IconButton
                                sx={{
                                    border: 'none',
                                    color: '#000000',
                                    marginLeft: '-8px'
                                }}
                                icon={() => <CopyIcon size={15} />}

                                onClick={() => {
                                    handledCopyPassword()
                                }}

                            >

                            </IconButton>



                        </Box>

                  
                        {expFileData!==undefined&&expFileData?.length > 0 ? 
                         
                            
                            expFileData.map((row, index) => {

                                 return(
                                    <>
                                         {row?.intimationFileArray?.map((row2, index2) => {
                                             return (<>
                                                 <Box sx={{
                                                     display: 'flex',
                                                     flexDirection: 'row',
                                                     gap: '10px',
                                                     minHeight: '20px',
                                                     maxHeight: '20px',
                                                     height: '20px',
                                                     alignItems: 'center'
                                                 }}>
                                                     <Text
                                                         className={customTheme.textnormal}
                                                         sx={{ color: customThemVariable.primaryTextColor }}
                                                     >
                                                         {row2.orderUrlFile}
                                                     </Text>

                                                     <IconButton
                                                         sx={{
                                                             border: 'none',
                                                             color: '#000000',
                                                             display: 'flex',
                                                             alignItems: 'center',
                                                             marginLeft: '-8px'
                                                         }}
                                                         icon={() => <Box sx={{ display: 'flex' }}><ExternalLinkIcon height={17} width={17}
                                                             onClick={() => {

                                                                 window.open(row2.orderUrl, '_blank')

                                                             }}
                                                         /></Box>}
                                                     >

                                                     </IconButton>

                                                 </Box>
                                             </>)
                                         })}
                                      
                                         <Box sx={{
                                             display: 'flex',
                                             flexDirection: 'row',
                                             gap: '10px',
                                             minHeight: '20px',
                                             maxHeight: '20px',
                                             height: '20px',
                                             alignItems: 'center'
                                         }}>

                                             <Text
                                                 className={customTheme.textnormal}
                                                 sx={{ color: customThemVariable.primaryTextColor, minWidth: '98px' }}>
                                                 ITR Form
                                             </Text>
                                             <IconButton
                                                 sx={{
                                                     border: 'none',
                                                     color: '#000000',
                                                     display: 'flex',
                                                     alignItems: 'center',
                                                     marginLeft: '-8px'
                                                 }}
                                                 icon={() => <Box sx={{ display: 'flex' }}><ExternalLinkIcon height={17} width={17}
                                                     onClick={() =>

                                                         row.formFileUrl !== "" ?
                                                             window.open(row.formFileUrl, '_blank')
                                                             : ""
                                                     }
                                                 /></Box>}
                                             >

                                             </IconButton>

                                         </Box>

                                         <Box
                                             sx={{
                                                 display: row.receiptFileUrl!==""?'flex':"none",
                                                 flexDirection: 'row',
                                                 gap: '10px',
                                                 minHeight: '20px',
                                                 maxHeight: '20px',
                                                 height: '20px',
                                                 alignItems: 'center'
                                             }}>

                                             <Text
                                                 className={customTheme.textnormal}
                                                 sx={{ color: customThemVariable.primaryTextColor, minWidth: '98px' }}>
                                                 ITR Receipt
                                             </Text>
                                             <IconButton
                                                 sx={{
                                                     border: 'none',
                                                     color: '#000000',
                                                     display: 'flex',
                                                     alignItems: 'center',
                                                     marginLeft: '-8px'
                                                 }}
                                                 icon={() => <Box sx={{ display: 'flex' }}><ExternalLinkIcon height={17} width={17}
                                                     onClick={() =>

                                                         row.receiptFileUrl !== ""?
                                                             window.open(row.receiptFileUrl, '_blank')
                                                             : ""

                                                     }
                                                 /></Box>}
                                             >

                                             </IconButton>

                                         </Box>

                                         <Box
                                             sx={{
                                                 display: row.jsonFileUrl!==""?'flex':"none",
                                                 flexDirection: 'row',
                                                 gap: '10px',
                                                 minHeight: '20px',
                                                 maxHeight: '20px',
                                                 height: '20px',
                                                 alignItems: 'center'
                                             }}>

                                             <Text
                                                 className={customTheme.textnormal}
                                                 sx={{ color: customThemVariable.primaryTextColor, minWidth: '98px' }}>
                                                 ITR Json
                                             </Text>
                                             <IconButton
                                                 sx={{
                                                     border: 'none',
                                                     color: '#000000',
                                                     display: 'flex',
                                                     alignItems: 'center',
                                                     marginLeft: '-8px'
                                                 }}
                                                 icon={() => <Box sx={{ display: 'flex' }}><ExternalLinkIcon height={17} width={17}
                                                     onClick={() =>

                                                         row.jsonFileUrl !== ""?
                                                             window.open(row.jsonFileUrl, '_blank')
                                                             : ""

                                                     }
                                                 /></Box>}
                                             >

                                             </IconButton>

                                         </Box>

                                    </>
                                 )

                            }) 
                            
                           
                        
                        
                        :""}
                        
                        
                        

                        

                        


                    </Box>

                    {/* ----------------- -------------- */}

                    <Box
                        sx={{
                            minHeight: '100px'
                        }}
                    >

                    </Box>




                </Box>


                {/* --------------------- */}







            </Box>



        </Box>


    )


}


export default ItrExpansionPlaywright