import { Box, IconButton, Heading, Text, Token, ActionMenu, Button, ActionList, Label } from "@primer/react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { ChevronLeftIcon, ChevronRightIcon, CheckIcon } from "@primer/octicons-react"
import React from "react"
import customTheme from "../../Theme.module.scss"
import customThemVariable from "../../ThemeVariable.module.scss"
import { getGstFilingSnapShots } from "../redux/features/portalSlice"
import { PersonIcon, ReaderIcon, UpdateIcon } from "@radix-ui/react-icons"
import { Toaster, Intent, Position, ProgressBar } from "@blueprintjs/core";
const synchingToast = Toaster.create({ position: Position.TOP })

function GstFilingSnapshotComponent(props) {

    let host = window.location.host
    let hostName = window.location.hostname
    let dispatch = useDispatch()
    const navigate = useNavigate()
    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail) || localStorage.getItem("userEmail")
    const [portal, setPortal] = React.useState(["IT", "GST"])
    const [selectedPortal, setselectedPortal] = React.useState("")
    const [isDisabledSyncing, setisDisabledSyncing] = React.useState(false);
    const [gstList, setgstList] = React.useState([])
    const [copygstList, setcopygstList] = React.useState([])
    const [selectedGstList, setselectedGstList] = React.useState("All Clients")
    const [progressPercentage,setprogressPercentage] = React.useState(0)
    //----on change client name
    const onChangeClient = (props) => {


        if (props === "All Clients") {
            setselectedGstList(props)
            setgstList(copygstList)

        } else {
            setselectedGstList(props)
            const filteredData = copygstList.filter((itData) => itData.insert_id === props)
            setgstList(filteredData)
        }

    }

    const loadingToast = (pPercent, key) => {
        
        synchingToast.show({
            icon: "cloud-upload",
            message:

                <Box sx={{ marginTop: '4px' }}>
                    <ProgressBar
                        animate={true}
                        stripes={true}
                        intent={progressPercentage < 100 ? Intent.PRIMARY : Intent.SUCCESS}
                        value={progressPercentage / 100}

                    />
                </Box>

            ,
            onDismiss: (didTimeoutExpire) => {
                if (!didTimeoutExpire) {

                    synchingToast.dismiss()

                }
            },
            timeout: progressPercentage < 100 ? 0 : 3000,

        }, key);

    }

    const handleSyncBulk =async (props) => {

        // alert(copygstList.length)  10 
        let countIndex = 0
        setprogressPercentage(0.5)
        setisDisabledSyncing(true)

        for (const copygstListItem of copygstList) {
            countIndex++  
            console.log("copygstListItem", copygstListItem.insert_id)
            try {

                const insertId = copygstListItem.insert_id
                const response = await fetch(`${process.env.REACT_APP_SCRAPSERVERURL}/gstBulkSnapshot?insertId=${insertId}&checkboxes=${JSON.stringify(["Profile"])}`);
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const responseData = await response.json();
                console.log(`responseData of ${copygstListItem.pan_name}`,responseData)
                const percentage = (countIndex/copygstList.length)*100
                console.log("percentage",percentage)
                setprogressPercentage(percentage)
               // loadingToast(percentage, "1")
                //const profileDataResponse = responseData?.profileData || []
                const errorCode = responseData?.code
                if(errorCode==="401"){

                    Toaster.create({ position: Position.TOP }).show({
                        message: `Sync error: Invalid username or password. ${copygstListItem?.pan_name}`,
                        intent: Intent.PRIMARY
                    }); 
                } 

                if(countIndex==copygstList.length){
                    setprogressPercentage(100)
                    setisDisabledSyncing(false)
                }  
     
            } catch (error) {
    
                setisDisabledSyncing(false)
            }


        }

    }

    const getGstPortalFromDb = (props) => {


        dispatch(getGstFilingSnapShots({
            userId: userId,
            userEmail: userEmail,
        })).then((data) => {

            console.log("getgstList", data.payload)
            const allList = [...data.payload.data].sort((a, b) => {
                const nameA = a.pan_name.toUpperCase(); // Convert to upper case for case-insensitive comparison
                const nameB = b.pan_name.toUpperCase(); // Convert to upper case for case-insensitive comparison

                if (nameA < nameB) {
                    return -1; // nameA comes first
                }
                if (nameA > nameB) {
                    return 1; // nameB comes first
                }
                return 0; // names are equal
            });
            setgstList(allList)
            setcopygstList(allList)
        })


    }

    React.useState(() => {
        getGstPortalFromDb("")
    })

    React.useEffect(()=>{

        if(progressPercentage >0){
            loadingToast(progressPercentage,"1") 
        }
         
    },[progressPercentage])

    return (
        <Box
            sx={{
                position: 'relative',
                background: '#FFFFFF',
                marginTop: '13px',
                borderRadius: '8px',
                minWidth: '1144px',
                maxWidth: '1144px',
                width: '1144px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}>


            <Box
                sx={{
                    width: '1054px',
                    minWidth: '1054px',
                    maxWidth: '1054px',
                    minHeight: '40px',
                    maxHeight: '40px',
                    height: '40px',
                    marginTop: '27px',
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center'
                }}>

                <Text sx={{
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '20px',
                    lineHeight: '26px',
                    color: '#24292e',
                }}>GST Returns Calendar (Last 5 return periods)</Text>

                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    minHeight: '40px',
                    maxHeight: '40px',
                    position: 'absolute',
                    right: '0px',
                    gap: '24px'
                }}>

                    <Button
                        id="gstsyncBtn"
                        disabled={isDisabledSyncing}
                        variant="invisible"
                        leadingIcon={() => <Box sx={{ display: "flex" }}><UpdateIcon /></Box>} 
                        sx={{
                            padding: '4px 8px 4px 8px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontFamily: customThemVariable.primartFont,
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '20px',
                        }}
                        onClick={() => {
                            handleSyncBulk("")
                        }}
                    >
                        Sync data
                    </Button>

                    <ActionMenu
                        onSelect={(newValue) => alert(`Pressed ${newValue}`)}
                    >
                        <ActionMenu.Anchor>
                            <Button sx={{
                                minHeight: '40px',
                                minWidth: '248px',
                                maxWidth: '248px',
                                width: '248px',
                                display: 'flex',
                                alignItems: 'center',
                                fontFamily: 'Inter',
                                fontWeight: '400',
                                fontSize: '14px'
                            }} leadingIcon={() => <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><PersonIcon /></Box>}>

                                {selectedGstList === "All Clients" ? "All Clients" :
                                    copygstList?.filter((itData) => itData?.insert_id === selectedGstList)[0]?.pan_name

                                }


                            </Button>


                        </ActionMenu.Anchor>
                        <ActionMenu.Overlay aria-label="GitHub features" sx={{ overflowY: 'auto', maxHeight: '300px' }}>
                            <ActionList sx={{
                                minWidth: '238px',
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',
                                display: 'flex',
                                flexDirection: 'column',
                                margin: '6px',
                                alignItems: 'center',
                                gap: '1px',
                            }}>
                                <ActionList.Item
                                    onSelect={() => {
                                        onChangeClient("All Clients")
                                    }}
                                >
                                    All
                                </ActionList.Item>
                                {

                                    copygstList.map((row, index) => {
                                        return (
                                            <ActionList.Item
                                                onSelect={() => {
                                                    onChangeClient(row.insert_id)
                                                }}
                                            >
                                                {row.pan_name}
                                            </ActionList.Item>
                                        )
                                    })

                                }


                            </ActionList>
                        </ActionMenu.Overlay>
                    </ActionMenu>
                </Box>


            </Box>




            <Box sx={{
                margin: '25px auto 0px auto',
                minWidth: '1098px',
                maxWidth: '1098px',
                width: '1098px',
                display: 'flex',
                flexDirection: 'column',
                fontFamily: customThemVariable.primartFont,

            }}>


                <Box sx={{
                    margin: '0px 0px 0px 0px',
                    minWidth: '1098px',
                    maxWidth: '1098px',
                    width: '1098px',
                    display: 'flex',
                    flexDirection: 'column',
                }}>

                    <Box sx={{
                        marginTop: '10px',
                        display: 'flex',
                        flexDirection: 'row',
                        minHeight: '36px',
                        borderBottomWidth: 1,
                        borderBottomStyle: 'solid',
                        borderColor: '#E0E0E0',
                        alignItems: 'center',
                        background: '#FFFFFF',
                        minWidth: 'fit-content',
                        maxWidth: 'fit-content',
                        width: 'fit-content',
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: '159px',
                            maxWidth: '159px',
                            width: '159px',

                        }}>
                            <Text sx={{
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '12px',
                                lineHeight: '18px',
                                color: '#414552',
                                position: 'relative',
                                margin: '0px 12px 0px 18px',
                                minWidth: '100%',
                                maxWidth: '100%',
                                width: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                Name
                            </Text>
                        </Box>

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: '159px',
                            maxWidth: '159px',
                            width: '159px',

                        }}>
                            <Text sx={{
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '12px',
                                lineHeight: '18px',
                                color: '#414552',
                                position: 'relative',
                                margin: '0px 12px 0px 18px',
                                minWidth: '100%',
                                maxWidth: '100%',
                                width: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                Filing Period
                            </Text>
                        </Box>

                        <Box sx={{

                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: '139px',
                            maxWidth: '139px',
                            width: '139px',

                        }}>
                            <Text sx={{
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '12px',
                                lineHeight: '16px',
                                color: '#414552',
                                position: 'relative',
                                margin: '0px 12px 0px 18px',
                                minWidth: '100%',
                                maxWidth: '100%',
                                width: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                Return Type
                            </Text>
                        </Box>

                        <Box sx={{

                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: '129px',
                            maxWidth: '129px',
                            width: '129px',
                        }}>
                            <Text sx={{
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '12px',
                                lineHeight: '16px',
                                color: '#414552',
                                position: 'relative',
                                margin: '0px 12px 0px 18px',
                                minWidth: '100%',
                                maxWidth: '100%',
                                width: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                Month Year Name
                            </Text>
                        </Box>


                        <Box sx={{

                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: '90px',
                            maxWidth: '90px',
                            width: '90px',

                        }}>
                            <Text sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '18px',
                                color: '#414552',
                                position: 'relative',
                                margin: '0px 12px 0px 18px',
                                minWidth: '100%',
                                maxWidth: '100%',
                                width: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                Status
                            </Text>
                        </Box>

                        <Box sx={{

                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: '129px',
                            maxWidth: '129px',
                            width: '129px',

                        }}>
                            <Text sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '18px',
                                color: '#414552',
                                position: 'relative',
                                margin: '0px 12px 0px 18px',
                                minWidth: '100%',
                                maxWidth: '100%',
                                width: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                Filing Status
                            </Text>
                        </Box>

                        <Box sx={{

                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: '90px',
                            maxWidth: '90px',
                            width: '90px',
                        }}>
                            <Text sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '18px',
                                color: '#414552',
                                position: 'relative',
                                margin: '0px 12px 0px 18px',
                                minWidth: '100%',
                                maxWidth: '100%',
                                width: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                Filing Date
                            </Text>
                        </Box>
                        <Box sx={{

                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: '159px',
                            maxWidth: '159px',
                            width: '159px',

                        }}>
                            <Text sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '18px',
                                color: '#414552',
                                position: 'relative',
                                margin: '0px 12px 0px 18px',
                                minWidth: '100%',
                                maxWidth: '100%',
                                width: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                Due Date
                            </Text>
                        </Box>


                    </Box>


                    {gstList.length > 0 ?

                        gstList.map((row0, index0) => {

                            const returnCalander = row0?.playwright_gst_filing_snapshot || []
                            return (

                                returnCalander.length > 0 && returnCalander[0]?.data?.formNames?.map((row, index) => {

                                    const retPrds = row?.retPrds || []
                                    return (
                                        retPrds.map((row2, index2) => {

                                            return (



                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    minHeight: '36px',
                                                    borderBottomWidth: 1,
                                                    borderBottomStyle: 'solid',
                                                    borderColor: '#E0E0E0',
                                                    alignItems: 'center',
                                                    background: '#FFFFFF',
                                                    minWidth: 'fit-content',
                                                    maxWidth: 'fit-content',
                                                    width: 'fit-content',
                                                }}>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        minWidth: '159px',
                                                        maxWidth: '159px',
                                                        width: '159px',

                                                    }}>
                                                        <Text sx={{
                                                            fontFamily: 'Inter',
                                                            fontStyle: 'normal',
                                                            fontWeight: '400',
                                                            fontSize: '12px',
                                                            lineHeight: '18px',
                                                            color: customThemVariable.primaryTextColor,
                                                            position: 'relative',
                                                            margin: '0px 12px 0px 18px',
                                                            minWidth: '100%',
                                                            maxWidth: '100%',
                                                            width: '100%',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis'
                                                        }}>
                                                            {row0?.pan_name + " " || " "}
                                                        </Text>
                                                    </Box>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        minWidth: '159px',
                                                        maxWidth: '159px',
                                                        width: '159px',

                                                    }}>
                                                        <Text sx={{
                                                            fontFamily: 'Inter',
                                                            fontStyle: 'normal',
                                                            fontWeight: '400',
                                                            fontSize: '12px',
                                                            lineHeight: '18px',
                                                            color: customThemVariable.primaryTextColor,
                                                            position: 'relative',
                                                            margin: '0px 12px 0px 18px',
                                                            minWidth: '100%',
                                                            maxWidth: '100%',
                                                            width: '100%',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis'
                                                        }}>
                                                            {returnCalander[0]?.data?.currFilingPrd + " " || " "}
                                                            {returnCalander[0]?.data?.currFilingPref || ""}
                                                        </Text>
                                                    </Box>

                                                    <Box sx={{

                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        minWidth: '139px',
                                                        maxWidth: '139px',
                                                        width: '139px',

                                                    }}>
                                                        <Text sx={{
                                                            fontFamily: 'Inter',
                                                            fontStyle: 'normal',
                                                            fontWeight: '400',
                                                            fontSize: '12px',
                                                            lineHeight: '16px',
                                                            color: customThemVariable.primaryTextColor,
                                                            position: 'relative',
                                                            margin: '0px 12px 0px 18px',
                                                            minWidth: '100%',
                                                            maxWidth: '100%',
                                                            width: '100%',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis'
                                                        }}>
                                                            {row?.formName || ""}
                                                        </Text>
                                                    </Box>

                                                    <Box sx={{

                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        minWidth: '129px',
                                                        maxWidth: '129px',
                                                        width: '129px',

                                                    }}>
                                                        <Text sx={{
                                                            fontFamily: 'Inter',
                                                            fontStyle: 'normal',
                                                            fontWeight: '400',
                                                            fontSize: '12px',
                                                            lineHeight: '16px',
                                                            color: customThemVariable.primaryTextColor,
                                                            position: 'relative',
                                                            margin: '0px 12px 0px 18px',
                                                            minWidth: '69px',
                                                            maxWidth: '69px',
                                                            width: '69px',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis'
                                                        }}>
                                                            {row2?.monthYearName || ""}
                                                        </Text>
                                                    </Box>


                                                    <Box sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        minWidth: '90px',
                                                        maxWidth: '90px',
                                                        width: '90px',

                                                    }}>
                                                        <Text sx={{
                                                            fontFamily: 'Segoe UI',
                                                            fontStyle: 'normal',
                                                            fontWeight: '400',
                                                            fontSize: '14px',
                                                            lineHeight: '18px',
                                                            color: customThemVariable.primaryTextColor,
                                                            position: 'relative',
                                                            margin: '0px 12px 0px 18px',
                                                            minWidth: '100%',
                                                            maxWidth: '100%',
                                                            width: '100%',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis'
                                                        }}>
                                                            {row2?.status || ""}
                                                        </Text>
                                                    </Box>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        minWidth: '129px',
                                                        maxWidth: '129px',
                                                        width: '129px',
                                                    }}>

                                                        {row2?.filingStatus === "Filed" ?

                                                            <>
                                                                <Label sx={{
                                                                    position: 'relative',
                                                                    margin: '0px 27px 0px 21px',
                                                                    padding: '2px 8px 2px 8px',
                                                                    fontFamily: 'Inter',
                                                                    fontStyle: 'normal',
                                                                    fontWeight: '600',
                                                                    fontSize: '12px',
                                                                    lineHeight: '18px',
                                                                    color: '#119C2B',
                                                                    borderRadius: '6px',
                                                                    background: '#EBFFF1',
                                                                    border: 'none',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center'
                                                                }}>

                                                                    <CheckIcon />
                                                                    <Text sx={{}}>
                                                                        {row2?.filingStatus}
                                                                    </Text>

                                                                </Label>
                                                            </>
                                                            :  //#ec9d09
                                                            row2?.filingStatus === "To be Filed" ? <>
                                                                <Label sx={{
                                                                    position: 'relative',
                                                                    margin: '0px 27px 0px 21px',
                                                                    padding: '2px 8px 2px 8px',
                                                                    fontFamily: 'Inter',
                                                                    fontStyle: 'normal',
                                                                    fontWeight: '600',
                                                                    fontSize: '12px',
                                                                    lineHeight: '18px',
                                                                    color: '#000000',
                                                                    borderRadius: '6px',
                                                                    // background: '#ec9d09',
                                                                    background: '#ebb94d',
                                                                    border: 'none',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center'
                                                                }}>
                                                                    <Text sx={{}}>
                                                                        {row2?.filingStatus}
                                                                    </Text>

                                                                </Label>
                                                            </> :
                                                                row2?.filingStatus === "Option expired" ? <>
                                                                    <Label sx={{
                                                                        position: 'relative',
                                                                        margin: '0px 27px 0px 21px',
                                                                        padding: '2px 8px 2px 8px',
                                                                        fontFamily: 'Inter',
                                                                        fontStyle: 'normal',
                                                                        fontWeight: '600',
                                                                        fontSize: '12px',
                                                                        lineHeight: '18px',
                                                                        borderRadius: '6px',
                                                                        background: '#ffe4e6',
                                                                        color: '#881337',
                                                                        border: 'none',
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center'
                                                                    }}>
                                                                        <Text sx={{}}>
                                                                            {row2?.filingStatus}
                                                                        </Text>

                                                                    </Label>
                                                                </> :
                                                                    <Text sx={{
                                                                        fontFamily: 'Segoe UI',
                                                                        fontStyle: 'normal',
                                                                        fontWeight: '400',
                                                                        fontSize: '14px',
                                                                        lineHeight: '18px',
                                                                        color: customThemVariable.primaryTextColor,
                                                                        position: 'relative',
                                                                        margin: '0px 12px 0px 18px',
                                                                        minWidth: '100%',
                                                                        maxWidth: '100%',
                                                                        width: '100%',
                                                                        whiteSpace: 'nowrap',
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis'
                                                                    }}>
                                                                        {row2?.filingStatus || ""}
                                                                    </Text>

                                                        }


                                                    </Box>

                                                    <Box sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        minWidth: '90px',
                                                        maxWidth: '90px',
                                                        width: '90px',
                                                    }}>
                                                        <Text sx={{
                                                            fontFamily: 'Segoe UI',
                                                            fontStyle: 'normal',
                                                            fontWeight: '400',
                                                            fontSize: '14px',
                                                            lineHeight: '18px',
                                                            color: customThemVariable.primaryTextColor,
                                                            position: 'relative',
                                                            margin: '0px 12px 0px 18px',
                                                            minWidth: '100%',
                                                            maxWidth: '100%',
                                                            width: '100%',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis'
                                                        }}>
                                                            {row2?.filingDate || ""}
                                                        </Text>
                                                    </Box>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        minWidth: '159px',
                                                        maxWidth: '159px',
                                                        width: '159px',

                                                    }}>
                                                        <Text sx={{
                                                            fontFamily: 'Segoe UI',
                                                            fontStyle: 'normal',
                                                            fontWeight: '400',
                                                            fontSize: '14px',
                                                            lineHeight: '18px',
                                                            color: customThemVariable.primaryTextColor,
                                                            position: 'relative',
                                                            margin: '0px 12px 0px 18px',
                                                            minWidth: '100%',
                                                            maxWidth: '100%',
                                                            width: '100%',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis'
                                                        }}>
                                                            {row2?.dueDate || ""}
                                                        </Text>
                                                    </Box>


                                                </Box>
                                            )

                                        })
                                    )


                                })



                            )


                        })



                        : ""}



                </Box>
                <Box sx={{ minHeight: '60px', width: '1200px' }}></Box>

            </Box>




        </Box>
    )

}

export default GstFilingSnapshotComponent