import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

export const postItPortal = createAsyncThunk("postItPortal", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "panName": data.panName,
        "panNumber": data.panNumber,
        "panPassword": data.panPassword
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/postItPortal`, requestOptions)
        .then(response => response.json())

    return result

})




export const postItPortalBulk = createAsyncThunk("postItPortalBulk", async (data) => {
    //postItPortalBulk
    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "panName": data.panName,
        "panNumber": data.panNumber,
        "panPassword": data.panPassword,
        "bulkPanData": data.bulkPanData
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/postItPortalBulk`, requestOptions)
        .then(response => response.json())

    return result

})


export const getItPortal = createAsyncThunk("getItPortal", async (data) => {

    const url = `${process.env.REACT_APP_API_URL}/getItPortal?userId=${data.userId}&userEmail=${data.userEmail}`;
    const response = await fetch(url);
    const result = await response.json();
    return result


})


export const updateItPortal = createAsyncThunk("updateItPortal", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "clientId": data.clientId,
        "insertId": data.insertId,
        "syncField": data.syncField
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/updateItPortal`, requestOptions)
        .then(response => response.json())

    return result


})

export const getSingleItportal = createAsyncThunk("getSingleItportal", async (data) => {

    const url = `${process.env.REACT_APP_API_URL}/getItPortal?userId=${data.userId}&userEmail=${data.userEmail}&insertId=${data.insertId}&serviceType=${data.serviceType}`;
    const response = await fetch(url);
    const result = await response.json();
    return result
 })


export const getItPortalByItrId = createAsyncThunk("getItPortalByItrId", async (data) => {

    const url = `${process.env.REACT_APP_API_URL}/getItPortalByItrId?userId=${data.userId}&userEmail=${data.userEmail}&insertId=${data.insertId}&itrId=${data.itrId}`;
    const response = await fetch(url);
    const result = await response.json();
    return result

})

export const deleteItPortal = createAsyncThunk("deleteItPortal", async (data) => {

    //deleteItPortal
    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "insertId": data.insertId,

    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/deleteItPortal`, requestOptions)
        .then(response => response.json())

    return result


})

export const deleteGstPortal = createAsyncThunk("deleteGstPortal", async (data) => {

    //deleteGstPortal
    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "insertId": data.insertId,

    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/deleteGstPortal`, requestOptions)
        .then(response => response.json())

    return result
})

export const updateProductTourTaxhero = createAsyncThunk("updateProductTourTaxhero", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "locationPath": data.locationPath,

    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/updateProductTourTaxhero`, requestOptions)
        .then(response => response.json())

    return result

})

export const getProductTourTaxhero = createAsyncThunk("getProductTourTaxhero", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,


    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/getProductTourTaxhero`, requestOptions)
        .then(response => response.json())

    return result

})

export const getTaxheroPortalStatic = createAsyncThunk("getTaxheroPortalStatic", async (data) => {


    //getTaxheroPortalStatic
    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,


    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/getTaxheroPortalStatic`, requestOptions)
        .then(response => response.json())

    return result


})



//----------- GST Portal Section ----------------------

export const postGstPortal = createAsyncThunk("postGstPortal", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "panName": data.panName,
        "panNumber": data.panNumber,
        "panPassword": data.panPassword
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/postGstPortal`, requestOptions)
        .then(response => response.json())

    return result

})


export const getGstPortal = createAsyncThunk("getGstPortal", async (data) => {

    const url = `${process.env.REACT_APP_API_URL}/getGstPortal?userId=${data.userId}&userEmail=${data.userEmail}&pageType=${data.pageType}`;
    const response = await fetch(url);
    const result = await response.json();
    return result

})

export const getTaxheroGstList = createAsyncThunk("getTaxheroGstList",async (data) => {

    const url = `${process.env.REACT_APP_API_URL}/getTaxheroGstList?userId=${data.userId}&userEmail=${data.userEmail}`;
    const response = await fetch(url);
    const result = await response.json();
    return result
   
})

//getGstFilingSnapShots
export const getGstFilingSnapShots = createAsyncThunk("getGstFilingSnapShots",async (data) => {
    const url = `${process.env.REACT_APP_API_URL}/getGstFilingSnapShots?userId=${data.userId}&userEmail=${data.userEmail}`;
    const response = await fetch(url);
    const result = await response.json();
    return result
})

//getTaxheroItList
export const getTaxheroItList = createAsyncThunk("getTaxheroItList",async (data) => {

    const url = `${process.env.REACT_APP_API_URL}/getTaxheroItList?userId=${data.userId}&userEmail=${data.userEmail}`;
    const response = await fetch(url);
    const result = await response.json();
    return result
   
})

export const postGstPortalBulk = createAsyncThunk("postGstPortalBulk", async (data) => {

    //postGstPortalBulk

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "panName": data.panName,
        "panNumber": data.panNumber,
        "panPassword": data.panPassword,
        "bulkPanData": data.bulkPanData
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/postGstPortalBulk`, requestOptions)
        .then(response => response.json())

    return result




})

export const getSingleGstportal = createAsyncThunk("getSingleGstportal", async (data) => {

    const url = `${process.env.REACT_APP_API_URL}/getGstPortal?userId=${data.userId}&userEmail=${data.userEmail}&insertId=${data.insertId}&serviceType=${data.serviceType}`;
    const response = await fetch(url);
    const result = await response.json();
    return result

})

export const getJwtToken = createAsyncThunk("getJwtToken", async (data) => {

    //getJwtToken
    const url = `${process.env.REACT_APP_API_URL}/getJwtToken?userId=${data.userId}&userEmail=${data.userEmail}`;
    const response = await fetch(url);
    const result = await response.json();
    return result

})

export const getSanboxGstProf = createAsyncThunk("getSanboxGstProf", async (data) => {

    const url = `${process.env.REACT_APP_API_URL}/getSanboxGstProf?gstIn=${data.gstIn}&userName=${data.userName}`;
    const response = await fetch(url);
    const result = await response.json();
    return result

})

export const syncGstPortal = createAsyncThunk("syncGstPortal", async (data) => {

    //syncGstPortal

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "userName": data.userName,
        "gstIn": data.gstIn,
        "insertId": data.insertId
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/syncGstPortal`, requestOptions)
        .then(response => response.json())

    return result



})

export const syncITPortal = createAsyncThunk("", async (data) => {

    //itProfileDataResponse

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "userName": data.userName,
        "userPassword": data.userPassword,
        "insertId": data.insertId
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/synchITPortal`, requestOptions)
        .then(response => response.json())

    return result



})

export const getSignzyGSTTrackReturn = createAsyncThunk("getSignzyGSTTrackReturn", async (data) => {

    //getSignzyGSTTrackReturn

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "gstIn": data.gstIn,
        "financialYear": data.financialYear,
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/getSignzyGSTTrackReturn`, requestOptions)
        .then(response => response.json())

    return result

})





export const updateGstPortal = createAsyncThunk("updateGstPortal", async (data) => {

    //updateGstPortal
    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "insertId": data.insertId,
        "gstIn": data.gstIn,
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/updateGstPortal`, requestOptions)
        .then(response => response.json())

    return result


})


export const getRazorpayPlans = createAsyncThunk("getRazorpayPlans", async (data) => {

    const url = `${process.env.REACT_APP_API_URL}/getRazorpayPlans?userId=${data.userId}&userEmail=${data.userEmail}`;
    const response = await fetch(url);
    const result = await response.json();
    return result
 
})


export const postRazorpaySubscription = createAsyncThunk("postRazorpaySubscription", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

     const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "selectedPlan":data.selectedPlan
     })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/postRazorpaySubscription`, requestOptions)
        .then(response => response.json())

    return result


})

export const updateGstPassword = createAsyncThunk("updateGstPassword",async (data) => {

    //updateGstPassword
    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "insertId": data.insertId,
        "gstuserName":data.gstuserName,
        "gstpassword":data.gstpassword
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/updateGstPassword`, requestOptions)
        .then(response => response.json())

    return result
})

//updateItPassword

export const updateItPassword = createAsyncThunk("updateItPassword",async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "insertId": data.insertId,
        "ituserName":data.ituserName,
        "itpassword":data.itpassword
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/updateItPassword`, requestOptions)
        .then(response => response.json())

    return result
})


export const changeGstPasswordOpenClose = createAsyncThunk("changeGstPasswordOpenClose", async (data) => {


    return ({
        openCloseGstChangePassword: data.openCloseGstChangePassword,
        insertId:data.insertId,
        gstName:data.gstName,
        gstUsername:data.gstUsername,
        gstPassword:data.gstPassword
    })

})

export const changeITPasswordOpenClose = createAsyncThunk("changeITPasswordOpenClose", async (data) => {


    return ({
        openCloseITChangePassword: data.openCloseITChangePassword,
        itinsertId:data.itinsertId,
        itName:data.itName,
        itUsername:data.itUsername,
        itPassword:data.itPassword
    })

})

//-----------------------------------------------------



export const portalSlice = createSlice({

    name: 'portalSlice',
    initialState: {
        loading: false,
        openCloseGstChangePassword:false,
        insertId:'',
        gstName:'',
        gstUsername:'',
        gstPassword:'',
        //it dialog
        openCloseITChangePassword:false,
        itinsertId:"",
        itName:"",
        itUsername:"",
        itPassword:"",
        serverTrigger:false
    },
    extraReducers: {

        [postItPortal.pending]: (state, action) => {
            state.loading = true;

        },
        [postItPortal.fulfilled]: (state, action) => {
            state.loading = action.payload.loading;
        },
        [postItPortal.rejected]: (state, action) => {
            state.loading = false
        },
        [changeGstPasswordOpenClose.pending]: (state, action) => {
            state.openCloseGstChangePassword = false;
            state.insertId = '';
            state.gstName='';
            state.gstUsername='';
            state.gstPassword='';
        },
        [changeGstPasswordOpenClose.fulfilled]: (state, action) => {
            state.openCloseGstChangePassword = action.payload.openCloseGstChangePassword;
            state.insertId = action.payload.insertId;
            state.gstName=action.payload.gstName;
            state.gstUsername=action.payload.gstUsername;
            state.gstPassword=action.payload.gstPassword;
        },
        [changeGstPasswordOpenClose.rejected]: (state, action) => {
            state.openCloseGstChangePassword = false;
            state.insertId = '';
            state.gstName='';
            state.gstUsername='';
            state.gstPassword=''
        },
        //-----------
        [changeITPasswordOpenClose.pending]: (state, action) => {
            state.openCloseITChangePassword = false;
            state.itinsertId = '';
            state.itName='';
            state.itUsername='';
            state.itPassword='';
        },
        [changeITPasswordOpenClose.fulfilled]: (state, action) => {
            state.openCloseITChangePassword = action.payload.openCloseITChangePassword;
            state.itinsertId = action.payload.itinsertId;
            state.itName=action.payload.itName;
            state.itUsername=action.payload.itUsername;
            state.itPassword=action.payload.itPassword;
        },
        [changeITPasswordOpenClose.rejected]: (state, action) => {
            state.openCloseITChangePassword = false;
            state.itinsertId = '';
            state.itName='';
            state.itUsername='';
            state.itPassword=''
        },
        //-----------
        [updateGstPassword.pending]: (state, action) => {
            state.serverTrigger = false;

        },
        [updateGstPassword.fulfilled]: (state, action) => {
            state.serverTrigger = true;
        },
        [updateGstPassword.rejected]: (state, action) => {
            state.serverTrigger = false
        },
        //---------
        [updateItPassword.pending]: (state, action) => {
            state.serverTrigger = false;

        },
        [updateItPassword.fulfilled]: (state, action) => {
            state.serverTrigger = true;
        },
        [updateItPassword.rejected]: (state, action) => {
            state.serverTrigger = false
        },



    }


})


export default portalSlice.reducer
