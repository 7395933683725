export default function keyPersonDesig(){
    return(
        [
            {
                "desig_cd": "MGR",
                "desig_desc": "Manager"
            },
            {
                "desig_cd": "CFO",
                "desig_desc": "Chief Financial Officer"
            },
            {
                "desig_cd": "CE",
                "desig_desc": "Chief Executive"
            },
            {
                "desig_cd": "MBR",
                "desig_desc": "Member"
            },
            {
                "desig_cd": "MTR",
                "desig_desc": "Managing Trustee"
            },
            {
                "desig_cd": "TRU",
                "desig_desc": "Trustee"
            },
            {
                "desig_cd": "DPR",
                "desig_desc": "Designated Partner"
            },
            {
                "desig_cd": "OPR",
                "desig_desc": "any other Partner"
            },
            {
                "desig_cd": "SEC",
                "desig_desc": "Secretary"
            },
            {
                "desig_cd": "OTH",
                "desig_desc": "Others"
            },
            {
                "desig_cd": "PO",
                "desig_desc": "Principal Officer"
            },
            {
                "desig_cd": "CEO",
                "desig_desc": "Chief Executive Officer"
            },
            {
                "desig_cd": "PTR",
                "desig_desc": "Partner"
            },
            {
                "desig_cd": "MPR",
                "desig_desc": "Managing Partner"
            },
            {
                "desig_cd": "MD",
                "desig_desc": "Managing Director"
            },
            {
                "desig_cd": "DIR",
                "desig_desc": "Director"
            },
            {
                "desig_cd": "KOH",
                "desig_desc": "Karta of HUF"
            },
            {
                "desig_cd": "MBH",
                "desig_desc": "One of the member of HUF"
            },
            {
                "desig_cd": "SLF",
                "desig_desc": "Self"
            },
            {
                "desig_cd": "OPO",
                "desig_desc": "Any other Principal Officer"
            },
            {
                "desig_cd": "OB",
                "desig_desc": "Office Bearer"
            },
            {
                "desig_cd": "OLQ",
                "desig_desc": "Official Liquidator"
            },
            {
                "desig_cd": "AR",
                "desig_desc": "Authorized Representative"
            },
            {
                "desig_cd": "AS",
                "desig_desc": "Authorised Signatory"
            }
        ]
    )
}