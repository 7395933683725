import React from "react"
import { Box, Button, Breadcrumbs, Text, Dialog, Spinner, FormControl, Checkbox, Label } from "@primer/react"
import { Helmet } from "react-helmet"
import EmptyData from "../../Space/EmptyData"
import Header from "../../Workplace/Header"
import SideNav from "../../Workplace/SideNav"
import { useDispatch, useSelector } from "react-redux"

import AddPanDialog from "../../Workplace/Dialogs/AddPanDialog"
import UploadPanDialog from "../../Workplace/Dialogs/UploadPanDialog"
import { useNavigate } from "react-router-dom"
import customTheme from "../../../Theme.module.scss"
import customThemVariable from "../../../ThemeVariable.module.scss"
import Workplacetheme from "../../Workplace/Workplacetheme"
import { CheckIcon, ChevronRightIcon } from "@primer/octicons-react"
import { changeGstPasswordOpenClose, getJwtToken, getProductTourTaxhero, getSingleGstportal, updateGstPortal, updateProductTourTaxhero } from "../../redux/features/portalSlice"
import { Auth } from 'aws-amplify';
import TaxHeroHeader from "../../Portal/TaxHeroHeader"
import { Toaster, Intent, Position, ProgressBar } from "@blueprintjs/core";
import axios from "axios"
import ChangePasswordGstPanDialog from "./ChangePasswordDialog"
import { UpdateIcon } from "@radix-ui/react-icons"

const synchingToast = Toaster.create({ position: Position.TOP })

function GstPlaywright(props) {

    let hostName = window.location.hostname
    let host = window.location.host
    const driver = window.driver.js.driver;

    let dispatch = useDispatch()
    const navigate = useNavigate()

    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail) || localStorage.getItem("userEmail")
    const serverTrigger = useSelector((state) => state.portalData.serverTrigger)

    const clientId = localStorage.getItem("client_id") || ""
    const insertId = localStorage.getItem("insert_id") || ""
    const gstIn = localStorage.getItem("gstIn") || ""
    const initialFocusRef = React.createRef()
    const [isDisabledSyncing, setisDisabledSyncing] = React.useState(false);
    const [allDataFeilds, setallDataFeilds] = React.useState([])
    const [gstData, setgstData] = React.useState(null)
    const [showLoadingBtn, setshowLoadingBtn] = React.useState(false)
    const [isShowSyncDialog, setisShowSyncDialog] = React.useState(false)
    const [selectedCheckBox, setselectedCheckBox] = React.useState(["Profile", "Returns", "Notices"])
    const [returnCalander, setreturnCalander] = React.useState([])
    const [progressPercentage, setprogressPercentage] = React.useState(0)
    const [isInvalidPassword, setisInvalidPassword] = React.useState(false)

    //Post Hog Events-----------------------------------
    const postHogTrackingGSTSyncBtnClick = async (userId, userEmail) => {

        if (userId !== null && userEmail !== null) {

            const postData = {
                api_key: `${process.env.REACT_APP_POSTHOG_APIKEY_TAXHERO}`,
                event: 'GSTSyncClick',
                properties: {
                    distinct_id: `${userId}`,
                    user_id: `${userId}`,
                    user_email: `${userEmail}`,
                },
                timestamp: new Date().toISOString(),
            };

            const apiUrl = `${process.env.REACT_APP_POSTHOG_APIURL}/`

            const result = await axios.post(apiUrl, postData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            return result;
        }


    }
    //------Product tour section ----------------
    const updateProductTourInDb = (props) => {

        const locationPath = [...props, { pathname: window.location.pathname, tour: true }]
        dispatch(updateProductTourTaxhero({
            userId: userId,
            userEmail: userEmail,
            locationPath: locationPath
        })).then((data) => {

            console.log("updateProductTourTaxhero", data.payload)
            const newLocationPath = data.payload.data
            console.log("newLocationPath", newLocationPath)
        })

    }

    const showProductTour = (props) => {

        const driverObj = driver({
            popoverClass: 'driverjs-theme',
            showProgress: true,
            allowClose: false,
            steps: [
                { element: '#gstsyncBtn', popover: { title: 'Sync data', description: `Use the sync button to update all client's latest compliance reports in the GSTIN portal.`, side: "top", align: 'start' } },
                { element: '#gstprofileDetails', popover: { title: 'GST Profile', description: `View detailed profile information about your client for quick insights.`, side: "top", align: 'start' } },

            ],
            onDestroyStarted: () => {
                console.log("driverObj", driverObj)

                if (driverObj.getActiveIndex() == 1) {
                    updateProductTourInDb(props)
                    if (!driverObj.hasNextStep() || driverObj.confirm("Are you sure?")) {
                        driverObj.destroy();

                    }

                }


            },
        });

        driverObj.drive();

    }

    const getUserProductTour = (props) => {


        dispatch(getProductTourTaxhero({
            userId: userId,
            userEmail: userEmail
        })).then((data) => {

            console.log("getProductTourTaxhero", data.payload.data)
            const tourPaths = [...data.payload.data]
            const checkProductTour = tourPaths?.find((pData) => pData.pathname === window.location.pathname)?.tour
            console.log("checkProductTour", checkProductTour)
            if (!checkProductTour) {
                showProductTour(tourPaths)
            }
        })

    }



    const handleSyncProfile = async (props) => {

        disableSyncButton("")
        // loadingToast(10, "1")

        setprogressPercentage(1)
        setTimeout(() => {
            if (!isInvalidPassword) {
                setprogressPercentage(15)
            }
        }, 2000)
        setTimeout(() => {

            if (!isInvalidPassword) {
                setprogressPercentage(30)
            }
        }, 5000)
        setTimeout(() => {

            if (!isInvalidPassword) {
                setprogressPercentage(60)
            }
        }, 8000)

        try {

            const insertId = allDataFeilds[0]?.insert_id
            const response = await fetch(`${process.env.REACT_APP_SCRAPSERVERURL}/gstProfile?insertId=${insertId}&checkboxes=${JSON.stringify(["Profile"])}`);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const responseData = await response.json();
            const profileDataResponse = responseData?.profileData || []
            const errorCode = responseData?.code
            if (errorCode === "401") {
                // synchingToast.dismiss()
                synchingToast.clear()
                Toaster.create({ position: Position.TOP }).show({
                    message: "Sync error: Invalid username or password.",
                    intent: Intent.PRIMARY
                });
                showChangePasssordDialog(insertId, allDataFeilds[0]?.pan_name, allDataFeilds[0]?.user_name, allDataFeilds[0]?.password)
                setprogressPercentage(100)
                setisInvalidPassword(true)
            }

            if (errorCode === "200") {
                //loadingToast(100, "1")
                setprogressPercentage(100)
            }
            console.log("plinggst", responseData)
            getSingleGSTportalFromDb("")

        } catch (error) {


        }

    }


    //------------------------------------------


    const getSingleGSTportalFromDb = (props) => {

        dispatch(getSingleGstportal({
            userId: userId,
            userEmail: userEmail,
            insertId: insertId,
            serviceType: "Profile"
        })).then((data) => {

            console.log("getSingleGSTportal", data.payload)
            setallDataFeilds(data.payload.data)
            setgstData(data?.payload.data[0]?.playwright_gst_profile[0])
            setreturnCalander(data?.payload.data[0]?.playwright_gst_filing_snapshot)
        })

    }

    const disableSyncButton = (props) => {
        setisDisabledSyncing(true);
        setTimeout(() => {
            setisDisabledSyncing(false);
        },
            //  10000
            30 * 60 * 1000
        );
    };

    const GSTSyncDialogPlaywright = (props) => {

        return (

            <Dialog
                sx={{
                    height: '480px',
                    width: '564px',

                }}
                isOpen={isShowSyncDialog}
                onDismiss={() => {
                    setisShowSyncDialog(false)
                }}
                aria-labelledby="header-id"
                initialFocusRef={initialFocusRef}
            >
                <Dialog.Header id="header-id">
                    <Text ref={initialFocusRef} sx={Workplacetheme.scheduleDalogeHeading}>Data Sync</Text>

                </Dialog.Header>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: 564,
                    height: 'fit-content',

                }}>

                    <Text sx={{
                        display: 'flex',
                        position: 'relative',
                        margin: '15px 20px 0px 15px',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: '#6A737D',
                    }}>Once synced for a GST account, you'll have to wait for the next 8 hrs to sync again.</Text>


                    <Box sx={{
                        position: 'relative',
                        margin: '40px 40px 0px 23px',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '20px'
                    }}>

                        <Box sx={{ flexBasis: 'calc(50% - 20px)' }}>
                            <FormControl>
                                <Checkbox id="default-checkbox" value={"Profile"} checked={selectedCheckBox.includes("Profile")} onChange={(e) => {
                                    if (e.target.checked) {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState, e.target.value]
                                            return newState
                                        })
                                    } else {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState].filter((row) => row !== e.target.value)
                                            return newState
                                        })
                                    }
                                }} />
                                <FormControl.Label
                                    sx={{
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                    }}
                                >Profile</FormControl.Label>
                            </FormControl>
                        </Box>

                        <Box sx={{ flexBasis: 'calc(50% - 20px)' }}>
                            <FormControl>
                                <Checkbox id="default-checkbox" value={"Returns"} checked={selectedCheckBox.includes("Returns")} onChange={(e) => {
                                    if (e.target.checked) {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState, e.target.value]
                                            return newState
                                        })
                                    } else {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState].filter((row) => row !== e.target.value)
                                            return newState
                                        })
                                    }
                                }} />
                                <FormControl.Label
                                    sx={{
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                    }}
                                >Returns</FormControl.Label>
                            </FormControl>


                        </Box>

                        <Box sx={{ flexBasis: 'calc(50% - 20px)' }}>
                            <FormControl>
                                <Checkbox id="default-checkbox" value={"Additionalnotices"} checked={selectedCheckBox.includes("Additionalnotices")} onChange={(e) => {
                                    if (e.target.checked) {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState, e.target.value]
                                            return newState
                                        })
                                    } else {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState].filter((row) => row !== e.target.value)
                                            return newState
                                        })
                                    }
                                }} />
                                <FormControl.Label
                                    sx={{
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                    }}
                                >Additional notices</FormControl.Label>
                            </FormControl>


                        </Box>
                        <Box sx={{ flexBasis: 'calc(50% - 20px)' }}>
                            <FormControl>
                                <Checkbox id="default-checkbox" value={"Notices"} checked={selectedCheckBox.includes("Notices")} onChange={(e) => {
                                    if (e.target.checked) {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState, e.target.value]
                                            return newState
                                        })
                                    } else {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState].filter((row) => row !== e.target.value)
                                            return newState
                                        })
                                    }
                                }} />
                                <FormControl.Label sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                }}>Notices</FormControl.Label>
                            </FormControl>


                        </Box>

                        <Box sx={{ flexBasis: 'calc(50% - 20px)' }}>
                            <FormControl >
                                <Checkbox id="default-checkbox" value={"Demand"} checked={selectedCheckBox.includes("Demand")} onChange={(e) => {
                                    if (e.target.checked) {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState, e.target.value]
                                            return newState
                                        })
                                    } else {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState].filter((row) => row !== e.target.value)
                                            return newState
                                        })
                                    }
                                }} />
                                <FormControl.Label sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                }}>Demand</FormControl.Label>
                            </FormControl>
                        </Box>
                        <Box sx={{ flexBasis: 'calc(50% - 20px)' }}>
                            <FormControl

                            >
                                <Checkbox id="default-checkbox" value={"ElectronicLedger"} checked={selectedCheckBox.includes("ElectronicLedger")} onChange={(e) => {
                                    if (e.target.checked) {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState, e.target.value]
                                            return newState
                                        })
                                    } else {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState].filter((row) => row !== e.target.value)
                                            return newState
                                        })
                                    }
                                }} />
                                <FormControl.Label sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                }}>Electronic Ledger</FormControl.Label>
                            </FormControl>


                        </Box>

                        <Box sx={{ flexBasis: 'calc(50% - 20px)' }} >
                            <FormControl>
                                <Checkbox id="default-checkbox" value={"Refund"} checked={selectedCheckBox.includes("Refund")} onChange={(e) => {
                                    if (e.target.checked) {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState, e.target.value]
                                            return newState
                                        })
                                    } else {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState].filter((row) => row !== e.target.value)
                                            return newState
                                        })
                                    }
                                }} />
                                <FormControl.Label sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                }}>Refund</FormControl.Label>
                            </FormControl>


                        </Box>
                        <Box sx={{ flexBasis: 'calc(50% - 20px)' }}>
                            <FormControl>
                                <Checkbox id="default-checkbox" value={"Challan"} checked={selectedCheckBox.includes("Challan")} onChange={(e) => {
                                    if (e.target.checked) {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState, e.target.value]
                                            return newState
                                        })
                                    } else {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState].filter((row) => row !== e.target.value)
                                            return newState
                                        })
                                    }
                                }} />
                                <FormControl.Label sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                }}>Challan</FormControl.Label>
                            </FormControl>


                        </Box>
                        <Box sx={{ flexBasis: 'calc(50% - 20px)' }}>
                            <FormControl>
                                <Checkbox id="default-checkbox" value={"Returnsfiles"} checked={selectedCheckBox.includes("Returnsfiles")} onChange={(e) => {
                                    if (e.target.checked) {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState, e.target.value]
                                            return newState
                                        })
                                    } else {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState].filter((row) => row !== e.target.value)
                                            return newState
                                        })
                                    }
                                }} />
                                <FormControl.Label sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                }}>Returns files</FormControl.Label>
                            </FormControl>
                        </Box>
                        <Box sx={{ flexBasis: 'calc(50% - 20px)' }}>
                            <FormControl>
                                <Checkbox id="default-checkbox" value={"Submission"} checked={selectedCheckBox.includes("Submission")} onChange={(e) => {
                                    if (e.target.checked) {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState, e.target.value]
                                            return newState
                                        })
                                    } else {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState].filter((row) => row !== e.target.value)
                                            return newState
                                        })
                                    }
                                }} />
                                <FormControl.Label sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                }}>Submission</FormControl.Label>
                            </FormControl>
                        </Box>
                        <Box sx={{ flexBasis: 'calc(50% - 20px)' }}>
                            <FormControl>
                                <Checkbox id="default-checkbox" value={"TDSReturn"} checked={selectedCheckBox.includes("TDSReturn")} onChange={(e) => {
                                    if (e.target.checked) {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState, e.target.value]
                                            return newState
                                        })
                                    } else {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState].filter((row) => row !== e.target.value)
                                            return newState
                                        })
                                    }
                                }} />
                                <FormControl.Label sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                }}>TDS Return</FormControl.Label>
                            </FormControl>
                        </Box>
                        <Box sx={{ flexBasis: 'calc(50% - 20px)' }}>
                            <FormControl>
                                <Checkbox id="default-checkbox" value={"BankAccount"} checked={selectedCheckBox.includes("BankAccount")} onChange={(e) => {
                                    if (e.target.checked) {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState, e.target.value]
                                            return newState
                                        })
                                    } else {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState].filter((row) => row !== e.target.value)
                                            return newState
                                        })
                                    }
                                }} />
                                <FormControl.Label sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                }}>Bank Account</FormControl.Label>
                            </FormControl>

                        </Box>
                        <Box sx={{ flexBasis: 'calc(50% - 20px)' }}>
                            <FormControl>
                                <Checkbox id="default-checkbox" value={"IntimationOrder"} checked={selectedCheckBox.includes("IntimationOrder")} onChange={(e) => {
                                    if (e.target.checked) {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState, e.target.value]
                                            return newState
                                        })
                                    } else {
                                        setselectedCheckBox((prevState) => {
                                            const newState = [...prevState].filter((row) => row !== e.target.value)
                                            return newState
                                        })
                                    }
                                }} />
                                <FormControl.Label sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                }}>Intimation of Voluntary Payment - DRC-03</FormControl.Label>
                            </FormControl>

                        </Box>
                    </Box>



                    <Box

                        sx={{
                            position: 'absolute',
                            bottom: '52px',
                            left: '0px',
                            right: '0px',
                        }}
                        borderColor="border.default"
                        borderBottomWidth={1}
                        borderBottomStyle="solid">


                    </Box>





                </Box>

                <Box
                    sx={{
                        position: 'absolute',
                        bottom: '10px',
                        left: '20px',
                        right: '20px',

                    }}
                    display="flex"
                    flexDirection="row"
                    justifyContent={"right"}
                >


                    {
                        showLoadingBtn === true ?

                            <Button
                                style={{ width: '92px' }}
                                variant="outline"
                                sx={Workplacetheme.createSpaceDialogFooterBox.submitBtn}
                            >
                                <Spinner sx={{
                                    position: 'absolute',
                                    left: '0px',
                                    right: '0px',
                                    margin: 'auto',
                                    top: '0px',
                                    bottom: '0px',
                                }} size="small" />
                            </Button>

                            :

                            <Button
                                style={{ width: '92px' }}
                                variant="outline"

                                sx={Workplacetheme.createSpaceDialogFooterBox.submitBtn}
                                onClick={() => {


                                    if (selectedCheckBox.length > 0) {
                                        showGstSyncDialog("")
                                    } else {

                                    }

                                    // showGstSyncDialog("")
                                }
                                }
                            >

                                Sync now
                            </Button>


                    }






                </Box>





            </Dialog>

        )

    }



    const showGstSyncDialog = async (props) => {

        setshowLoadingBtn(true)
        postHogTrackingGSTSyncBtnClick(userId, userEmail)
        disableSyncButton("")
        setisShowSyncDialog(false)
        //  loadingToast(10, "1")
        setprogressPercentage(1)
        setTimeout(() => {
            if (!isInvalidPassword) {
                setprogressPercentage(15)
            }
        }, 2000)
        setTimeout(() => {

            if (!isInvalidPassword) {
                setprogressPercentage(30)
            }
        }, 5000)
        setTimeout(() => {

            if (!isInvalidPassword) {
                setprogressPercentage(60)
            }
        }, 15000)
        setTimeout(() => {

            if (!isInvalidPassword) {
                setprogressPercentage(80)
            }
        }, 60000)

        try {

            const insertId = allDataFeilds[0]?.insert_id
            // const response = await fetch(`${process.env.REACT_APP_SCRAPSERVERURL}/syncgst?insertId=${insertId}`);
            const response = await fetch(`${process.env.REACT_APP_SCRAPSERVERURL}/syncgstdev?insertId=${insertId}&checkboxes=${JSON.stringify(selectedCheckBox)}`);
            setselectedCheckBox([])

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const responseData = await response.json();
            const profileDataResponse = responseData?.profileData || []
            const errorCode = responseData?.code
            if (errorCode === "401") {
                // synchingToast.dismiss()
                synchingToast.clear()
                Toaster.create({ position: Position.TOP }).show({
                    message: "Sync error: Invalid username or password.",
                    intent: Intent.PRIMARY
                });
                showChangePasssordDialog(insertId, allDataFeilds[0]?.pan_name, allDataFeilds[0]?.user_name, allDataFeilds[0]?.password)
                setshowLoadingBtn(false)
                setprogressPercentage(100)
                setisInvalidPassword(true)
            }

            if (errorCode === "200") {
                // loadingToast(100, "1")
                setshowLoadingBtn(false)
                setprogressPercentage(100)
            }

            // if (profileDataResponse?.length > 0) {
            //     loadingToast(100, "1")
            // }
            console.log("plinggst", responseData)
            getSingleGSTportalFromDb("")

        } catch (error) {


        }

    }

    const loadingToast = (progressPercentage, key) => {

        synchingToast.show({
            icon: "cloud-upload",
            message:

                <Box sx={{ marginTop: '4px' }}>
                    <ProgressBar
                        animate={true}
                        stripes={true}
                        intent={progressPercentage < 100 ? Intent.PRIMARY : Intent.SUCCESS}
                        value={progressPercentage / 100}

                    />
                </Box>

            ,
            onDismiss: (didTimeoutExpire) => {
                if (!didTimeoutExpire) {

                    synchingToast.dismiss()

                }
            },
            timeout: progressPercentage < 100 ? 0 : 3000,

        }, key);

    }

    const showChangePasssordDialog = (props, gstName, gstUsername, gstPassword) => {

        dispatch(changeGstPasswordOpenClose({
            openCloseGstChangePassword: true,
            insertId: props,
            gstName: gstName,
            gstUsername: gstUsername,
            gstPassword: gstPassword
        }))
    }

    React.useEffect(() => {

        if (serverTrigger) {
            getSingleGSTportalFromDb("")
        }

    }, [serverTrigger])

    React.useEffect(() => {

        if (progressPercentage > 0 && !isInvalidPassword) {
            loadingToast(progressPercentage, "1")//
        }

        if (progressPercentage == 100) {

            setTimeout(() => {
                synchingToast.dismiss()
                synchingToast.clear()
            }, 2000)
            setisDisabledSyncing(false)

        }

    }, [progressPercentage])

    React.useState(() => {
        getSingleGSTportalFromDb("")
        // getUserProductTour("")
    })

    React.useState(() => {

        (function (c, l, a, r, i, t, y) {
            c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
            t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
            y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
        })(window, document, "clarity", "script", "ibtrxlrmkf");

    })

    return (
        <Box
            position={"relative"}
            display="flex"
            background={"#F6F8FA"}
            height="100vh"
            width={"100vw"}
            overflowY={"auto"}

        >
            <Helmet>
                <title>
                    {hostName === "www.app.taxherohq.com" || hostName === "www.get.taxherohq.com" ? 'GST - info - taxhero' : 'GST - info - taxhero'}
                </title>
            </Helmet>
            {
                hostName === "www.app.taxherohq.com"
                    || hostName === "www.get.taxherohq.com"
                    ?

                    <><TaxHeroHeader /></>
                    :
                    <><TaxHeroHeader /></>
            }


            <Box sx={{
                top: '48px',
                position: 'fixed',
                left: hostName === "www.app.taxherohq.com" || hostName === "www.get.taxherohq.com" ? '0px' : '64px',
                right: '0px',
                minHeight: '48px',
                maxHeight: '48px',
                height: '48px',
                background: customThemVariable.whiteBgColor,
                display: 'flex',
                borderBottom: '1px solid #E5E9EB',
                zIndex: '1'
            }}>

                <Text sx={{
                    marginTop: '12px',
                    marginLeft: '32px',
                    marginBottom: '15px',
                    color: customThemVariable.textgray,
                    fontSize: '14px',
                    fontFamily: customThemVariable.primartFont,
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '20px',
                    cursor: 'pointer'
                }}
                    onClick={() => {

                        hostName === "www.app.taxherohq.com"
                            ||
                            hostName === "www.get.taxherohq.com"
                            ?
                            navigate('/taxheroportal')
                            :
                            navigate('/portal')
                    }}
                >
                    Home
                </Text>

                <Box sx={{
                    marginLeft: '6px',
                    marginTop: '14px',
                    marginBottom: '18px',
                    color: '#B0BABF'
                }}>

                    <ChevronRightIcon />
                </Box>

                <Text
                    sx={{
                        marginTop: '12px',
                        marginLeft: '6px',
                        marginBottom: '15px',
                        color: customThemVariable.textgraydark,
                        fontSize: '14px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '20px',
                        cursor: 'pointer'
                    }}
                    onClick={() => {
                        navigate('/gstpanlist')
                    }}
                >
                    GST
                </Text>

                <Box sx={{
                    marginLeft: '6px',
                    marginTop: '14px',
                    marginBottom: '18px',
                    color: '#B0BABF'
                }}>

                    <ChevronRightIcon />
                </Box>

                <Text
                    sx={{
                        marginTop: '12px',
                        marginLeft: '6px',
                        marginBottom: '15px',
                        color: customThemVariable.textgraydark,
                        fontSize: '14px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '20px',
                        cursor: 'pointer'
                    }}
                    onClick={() => { }}
                >
                    {allDataFeilds[0]?.pan_name.substring(0, 100)}
                </Text>

                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px', position: 'absolute', top: '9px', right: '160px' }}>
                    <Button
                        id="gstsyncBtn"
                        disabled={isDisabledSyncing}
                        variant="invisible"
                        leadingIcon={() => <Box sx={{ display: "flex" }}><UpdateIcon /></Box>}
                        sx={{
                            padding: '4px 8px 4px 8px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontFamily: customThemVariable.primartFont,
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '20px',
                        }}
                        onClick={() => { handleSyncProfile("") }}
                    >
                        Sync data
                    </Button>
                </Box>


            </Box>


            <Box
                id="gstprofileDetails"
                sx={{
                    position: 'absolute',
                    left: '93px',
                    top: '112px',
                    right: '80px',
                    minHeight: '433px',
                    background: '#FFFFFF',
                    borderRadius: '3px',
                    border: '1px solid #D0D7DE',
                    paddingBottom: '16px'
                }}>


                <Box sx={{
                    position: 'absolute',
                    top: '15px',
                    left: '25px',
                    right: '25px',
                    minHeight: '36px',
                    maxHeight: '36px',
                    height: '36px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}>

                    <Text
                        className={customTheme.H3}
                        sx={{
                            color: customThemVariable.textgraydark
                        }}>
                        {gstData?.gstin}
                    </Text>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        position: 'absolute',
                        left: '282px',
                        alignItems: 'center'
                    }}>

                        <Text
                            className={customTheme.H3}
                            sx={{
                                color: customThemVariable.textgraydark
                            }}>
                            {allDataFeilds[0]?.pan_name.substring(0, 100)}
                        </Text>

                        <Text
                            className={customTheme.H4}
                            sx={{
                                marginLeft: '30px',
                                color: customThemVariable.textgray
                            }}>
                            {allDataFeilds[0]?.user_name}
                        </Text>
                        <Text
                            className={customTheme.H4}
                            sx={{
                                marginLeft: '20px',
                                color: customThemVariable.textgray
                            }}>
                            {allDataFeilds[0]?.password}

                        </Text>

                    </Box>

                    <Text
                        sx={{
                            display: 'flex',
                            position: 'absolute',
                            right: '137px',
                            fontFamily: customThemVariable.primartFont,
                            fontWeight: '400',
                            fontSize: '12px',
                            lineHeight: '20px',
                            fontStyle: 'normal',
                            color: customThemVariable.textgraydark

                        }}
                    >

                        Last synced on :
                        {
                            allDataFeilds.length == 0 ? "Not Synched" :
                                "" + new Date(allDataFeilds[0]?.updated_timestamp).toDateString() + " "
                                + new Date((new Date(allDataFeilds[0]?.updated_timestamp).getTime()) - (5.5 * 60 * 60 * 1000)).toLocaleTimeString() + ""
                        }


                    </Text>

                    <Button
                        id="gstsyncBtn"
                        disabled={isDisabledSyncing}
                        variant="primary"
                        sx={{
                            height: '32px',
                            width: '100px',
                            position: 'absolute',
                            right: '0px',
                            top: '0px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontFamily: customThemVariable.primartFont,
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '20px',
                        }}
                        onClick={() => { setisShowSyncDialog(true) }}
                    >
                        SYNC
                    </Button>


                </Box>



                <Box sx={{ position: 'absolute', left: '25px', top: '67px', display: 'flex', flexDirection: 'column', gap: '10px' }}>


                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        Legal Name
                    </Text>


                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        Primary email
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        Contact
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        GSTIN System Turn over
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        PAN System Turn over
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        Address
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        Aggregate Turn over
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        Type
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        Status
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        Registration Date
                    </Text>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '119px' }}>
                        <Text sx={{
                            position: 'relative',
                            margin: '0px 0px 0px 0px',
                            fontFamily: customThemVariable.primartFont,
                            fontStyle: 'normal',
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '20px',
                            color: '#121212',
                            minWidth: '162px'
                        }}>
                            Central Jurisdiction
                        </Text>
                        <Text sx={{
                            position: 'relative',
                            margin: '0px 0px 0px 0px',
                            fontFamily: customThemVariable.primartFont,
                            fontStyle: 'normal',
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '20px',
                            color: customThemVariable.textgraydark,
                            minHeight: '20px',
                        }}>
                            {gstData !== null ?
                                gstData?.centreJurisdiction?.substring(0, 500)

                                : ""}
                        </Text>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '119px' }}>
                        <Text sx={{
                            position: 'relative',
                            margin: '0px 0px 0px 0px',
                            fontFamily: customThemVariable.primartFont,
                            fontStyle: 'normal',
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '20px',
                            color: '#121212',
                            minWidth: '162px'
                        }}>
                            State Jurisdiction
                        </Text>
                        <Text sx={{
                            position: 'relative',
                            margin: '0px 0px 0px 0px',
                            fontFamily: customThemVariable.primartFont,
                            fontStyle: 'normal',
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '20px',
                            color: customThemVariable.textgraydark,
                            minHeight: '20px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}>
                            {
                                gstData !== null ?
                                    gstData?.stateJurisdiction
                                    : ""
                            }

                        </Text>

                    </Box>


                </Box>

                <Box sx={{ position: 'absolute', left: '307px', top: '67px', display: 'flex', flexDirection: 'column', gap: '10px' }}>

                    <Text sx={{

                        visibility: gstData !== null ? "visible" : "hidden",
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}>
                        {gstData?.legalName}
                        ({gstData?.constitutionOfBusiness})
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}>
                        {gstData?.contactData[0]?.emailAddress}
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}>
                        {gstData?.contactData[0]?.mobileNumber}
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}>

                        {gstData?.turnOverArray?.length > 0 ?
                            gstData?.turnOverArray[(gstData?.turnOverArray?.length) - 1]?.aggTurnoverInfoList[0]?.gstinSysTo
                            : ""}
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}>

                        {gstData?.turnOverArray?.length > 0 ?
                            gstData?.turnOverArray[(gstData?.turnOverArray?.length) - 1]?.aggTurnoverInfoList[0]?.panSysTo
                            : ""}

                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px',
                        maxHeight: '20px',
                        height: '20px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}>

                        {gstData?.placeOfBustableData[0]?.address}
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}>
                        {gstData?.turnOverArray?.length > 0 ?
                            gstData?.turnOverArray[(gstData?.turnOverArray?.length) - 1]?.aggTurnoverInfoList[0]?.panAggTurnOver
                            : ""}
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}>
                        {/* Regular  */}
                        {gstData?.taxpayerType}
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: gstData?.gstinStatus === "Active" ? customThemVariable.textgreen : 'red',
                        minHeight: '20px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}>
                        {/* ACTIVE */}
                        {gstData?.gstinStatus}
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}>
                        {gstData !== null ?

                            gstData?.dateOfRegistration
                            : ""}


                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px',
                    }}>
                        {/* {gstData !== null ?
                            gstData?.centreJurisdiction?.substring(0, 500)

                            : ""} */}
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}>
                        {/* {
                            gstData !== null ?
                                gstData?.stateJurisdiction
                                : ""
                        } */}

                    </Text>
                </Box>


            </Box>
            {/* Turn over table--------- */}
            <Box sx={{
                position: 'absolute',
                left: '93px',
                top: '580px',
                right: '80px',
                minHeight: 'fit-content',
                paddingBottom: '60px',
                paddingLeft: '20px',
                display: 'flex',
                flexDirection: 'column',
                gap: '20px'
            }}>

                {/* Return Calander (Last 5 return periods)-------- */}

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <Text
                        className={customTheme.H3}
                        sx={{ color: customThemVariable.primaryTextColor }}
                    >
                        Returns Calendar (Last 5 return periods)
                    </Text>

                    <Box sx={{
                        marginTop: '7px',
                        width: '964px',
                        minWidth: '964px',
                        maxWidth: '964px',
                        height: '1px',
                        background: customThemVariable.borderColor
                    }}>
                    </Box>


                    {/* ----------------- -------------- */}
                    <Box sx={{
                        marginTop: '10px',
                        display: 'flex',
                        flexDirection: 'row',
                        minHeight: '36px',
                        borderBottomWidth: 1,
                        borderBottomStyle: 'solid',
                        borderColor: '#E0E0E0',
                        alignItems: 'center',
                        background: '#FFFFFF',
                        minWidth: 'fit-content',
                        maxWidth: 'fit-content',
                        width: 'fit-content',
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: '159px',
                            maxWidth: '159px',
                            width: '159px',

                        }}>
                            <Text sx={{
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '12px',
                                lineHeight: '18px',
                                color: '#414552',
                                position: 'relative',
                                margin: '0px 12px 0px 18px',
                                minWidth: '100%',
                                maxWidth: '100%',
                                width: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                Filing Period
                            </Text>
                        </Box>

                        <Box sx={{

                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: '139px',
                            maxWidth: '139px',
                            width: '139px',

                        }}>
                            <Text sx={{
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '12px',
                                lineHeight: '16px',
                                color: '#414552',
                                position: 'relative',
                                margin: '0px 12px 0px 18px',
                                minWidth: '100%',
                                maxWidth: '100%',
                                width: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                Return Type
                            </Text>
                        </Box>

                        <Box sx={{

                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: '129px',
                            maxWidth: '129px',
                            width: '129px',
                        }}>
                            <Text sx={{
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '12px',
                                lineHeight: '16px',
                                color: '#414552',
                                position: 'relative',
                                margin: '0px 12px 0px 18px',
                                minWidth: '100%',
                                maxWidth: '100%',
                                width: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                Month Year Name
                            </Text>
                        </Box>


                        <Box sx={{

                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: '119px',
                            maxWidth: '119px',
                            width: '119px',

                        }}>
                            <Text sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '18px',
                                color: '#414552',
                                position: 'relative',
                                margin: '0px 12px 0px 18px',
                                minWidth: '100%',
                                maxWidth: '100%',
                                width: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                Status
                            </Text>
                        </Box>

                        <Box sx={{

                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: '129px',
                            maxWidth: '129px',
                            width: '129px',

                        }}>
                            <Text sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '18px',
                                color: '#414552',
                                position: 'relative',
                                margin: '0px 12px 0px 18px',
                                minWidth: '100%',
                                maxWidth: '100%',
                                width: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                Filing Status
                            </Text>
                        </Box>

                        <Box sx={{

                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: '119px',
                            maxWidth: '119px',
                            width: '119px',

                        }}>
                            <Text sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '18px',
                                color: '#414552',
                                position: 'relative',
                                margin: '0px 12px 0px 18px',
                                minWidth: '100%',
                                maxWidth: '100%',
                                width: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                Filing Date
                            </Text>
                        </Box>
                        <Box sx={{

                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: '159px',
                            maxWidth: '159px',
                            width: '159px',

                        }}>
                            <Text sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '18px',
                                color: '#414552',
                                position: 'relative',
                                margin: '0px 12px 0px 18px',
                                minWidth: '100%',
                                maxWidth: '100%',
                                width: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                Due Date
                            </Text>
                        </Box>


                    </Box>

                    {returnCalander.length > 0 && returnCalander[0]?.data?.formNames?.map((row, index) => {

                        const retPrds = row?.retPrds || []
                        return (
                            retPrds.map((row2, index2) => {

                                return (



                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        minHeight: '36px',
                                        borderBottomWidth: 1,
                                        borderBottomStyle: 'solid',
                                        borderColor: '#E0E0E0',
                                        alignItems: 'center',
                                        background: '#FFFFFF',
                                        minWidth: 'fit-content',
                                        maxWidth: 'fit-content',
                                        width: 'fit-content',
                                    }}>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            minWidth: '159px',
                                            maxWidth: '159px',
                                            width: '159px',

                                        }}>
                                            <Text sx={{
                                                fontFamily: 'Inter',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                fontSize: '12px',
                                                lineHeight: '18px',
                                                color: customThemVariable.primaryTextColor,
                                                position: 'relative',
                                                margin: '0px 12px 0px 18px',
                                                minWidth: '100%',
                                                maxWidth: '100%',
                                                width: '100%',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                            }}>
                                                {returnCalander[0]?.data?.currFilingPrd + " " || " "}
                                                {returnCalander[0]?.data?.currFilingPref || ""}
                                            </Text>
                                        </Box>

                                        <Box sx={{

                                            display: 'flex',
                                            flexDirection: 'row',
                                            minWidth: '139px',
                                            maxWidth: '139px',
                                            width: '139px',

                                        }}>
                                            <Text sx={{
                                                fontFamily: 'Inter',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                fontSize: '12px',
                                                lineHeight: '16px',
                                                color: customThemVariable.primaryTextColor,
                                                position: 'relative',
                                                margin: '0px 12px 0px 18px',
                                                minWidth: '100%',
                                                maxWidth: '100%',
                                                width: '100%',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                            }}>
                                                {row?.formName || ""}
                                            </Text>
                                        </Box>

                                        <Box sx={{

                                            display: 'flex',
                                            flexDirection: 'row',
                                            minWidth: '129px',
                                            maxWidth: '129px',
                                            width: '129px',

                                        }}>
                                            <Text sx={{
                                                fontFamily: 'Inter',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                fontSize: '12px',
                                                lineHeight: '16px',
                                                color: customThemVariable.primaryTextColor,
                                                position: 'relative',
                                                margin: '0px 12px 0px 18px',
                                                minWidth: '69px',
                                                maxWidth: '69px',
                                                width: '69px',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                            }}>
                                                {row2?.monthYearName || ""}
                                            </Text>
                                        </Box>


                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            minWidth: '119px',
                                            maxWidth: '119px',
                                            width: '119px',

                                        }}>
                                            <Text sx={{
                                                fontFamily: 'Segoe UI',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                fontSize: '14px',
                                                lineHeight: '18px',
                                                color: customThemVariable.primaryTextColor,
                                                position: 'relative',
                                                margin: '0px 12px 0px 18px',
                                                minWidth: '100%',
                                                maxWidth: '100%',
                                                width: '100%',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                            }}>
                                                {row2?.status || ""}
                                            </Text>
                                        </Box>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            minWidth: '129px',
                                            maxWidth: '129px',
                                            width: '129px',
                                        }}>

                                            {row2?.filingStatus === "Filed" ?

                                                <>
                                                    <Label sx={{
                                                        position: 'relative',
                                                        margin: '0px 27px 0px 21px',
                                                        padding: '2px 8px 2px 8px',
                                                        fontFamily: 'Inter',
                                                        fontStyle: 'normal',
                                                        fontWeight: '600',
                                                        fontSize: '12px',
                                                        lineHeight: '18px',
                                                        color: '#119C2B',
                                                        borderRadius: '6px',
                                                        background: '#EBFFF1',
                                                        border: 'none',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}>

                                                        <CheckIcon />
                                                        <Text sx={{}}>
                                                            {row2?.filingStatus}
                                                        </Text>

                                                    </Label>
                                                </>
                                                :  //#ec9d09
                                                row2?.filingStatus === "To be Filed" ? <>
                                                    <Label sx={{
                                                        position: 'relative',
                                                        margin: '0px 27px 0px 21px',
                                                        padding: '2px 8px 2px 8px',
                                                        fontFamily: 'Inter',
                                                        fontStyle: 'normal',
                                                        fontWeight: '600',
                                                        fontSize: '12px',
                                                        lineHeight: '18px',
                                                        color: '#000000',
                                                        borderRadius: '6px',
                                                        // background: '#ec9d09',
                                                        background: '#ebb94d',
                                                        border: 'none',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}>
                                                        <Text sx={{}}>
                                                            {row2?.filingStatus}
                                                        </Text>

                                                    </Label>
                                                </> :
                                                    row2?.filingStatus === "Option expired" ? <>
                                                        <Label sx={{
                                                            position: 'relative',
                                                            margin: '0px 27px 0px 21px',
                                                            padding: '2px 8px 2px 8px',
                                                            fontFamily: 'Inter',
                                                            fontStyle: 'normal',
                                                            fontWeight: '600',
                                                            fontSize: '12px',
                                                            lineHeight: '18px',
                                                            borderRadius: '6px',
                                                            background: '#ffe4e6',
                                                            color: '#881337',
                                                            border: 'none',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center'
                                                        }}>
                                                            <Text sx={{}}>
                                                                {row2?.filingStatus}
                                                            </Text>

                                                        </Label>
                                                    </> :
                                                        <Text sx={{
                                                            fontFamily: 'Segoe UI',
                                                            fontStyle: 'normal',
                                                            fontWeight: '400',
                                                            fontSize: '14px',
                                                            lineHeight: '18px',
                                                            color: customThemVariable.primaryTextColor,
                                                            position: 'relative',
                                                            margin: '0px 12px 0px 18px',
                                                            minWidth: '100%',
                                                            maxWidth: '100%',
                                                            width: '100%',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis'
                                                        }}>
                                                            {row2?.filingStatus || ""}
                                                        </Text>

                                            }


                                        </Box>

                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            minWidth: '119px',
                                            maxWidth: '119px',
                                            width: '119px',
                                        }}>
                                            <Text sx={{
                                                fontFamily: 'Segoe UI',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                fontSize: '14px',
                                                lineHeight: '18px',
                                                color: customThemVariable.primaryTextColor,
                                                position: 'relative',
                                                margin: '0px 12px 0px 18px',
                                                minWidth: '100%',
                                                maxWidth: '100%',
                                                width: '100%',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                            }}>
                                                {row2?.filingDate || ""}
                                            </Text>
                                        </Box>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            minWidth: '159px',
                                            maxWidth: '159px',
                                            width: '159px',
                                        }}>
                                            <Text sx={{
                                                fontFamily: 'Segoe UI',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                fontSize: '14px',
                                                lineHeight: '18px',
                                                color: customThemVariable.primaryTextColor,
                                                position: 'relative',
                                                margin: '0px 12px 0px 18px',
                                                minWidth: '100%',
                                                maxWidth: '100%',
                                                width: '100%',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                            }}>
                                                {row2?.dueDate || ""}
                                            </Text>
                                        </Box>


                                    </Box>
                                )

                            })
                        )


                    })}





                </Box>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <Text
                        className={customTheme.H3}
                        sx={{ color: customThemVariable.primaryTextColor }}
                    >
                        Gst Aggregate Turnover Details
                    </Text>

                    <Box sx={{
                        marginTop: '7px',
                        width: '964px',
                        minWidth: '964px',
                        maxWidth: '964px',
                        height: '1px',
                        background: customThemVariable.borderColor
                    }}>
                    </Box>


                    {/* ----------------- -------------- */}
                    <Box sx={{
                        marginTop: '10px',
                        display: 'flex',
                        flexDirection: 'row',
                        minHeight: '36px',
                        borderBottomWidth: 1,
                        borderBottomStyle: 'solid',
                        borderColor: '#E0E0E0',
                        alignItems: 'center',
                        background: '#FFFFFF',
                        minWidth: 'fit-content',
                        maxWidth: 'fit-content',
                        width: 'fit-content',
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: '129px',
                            maxWidth: '129px',
                            width: '129px',

                        }}>
                            <Text sx={{
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '12px',
                                lineHeight: '18px',
                                color: '#414552',
                                position: 'relative',
                                margin: '0px 12px 0px 18px',
                                minWidth: '100%',
                                maxWidth: '100%',
                                width: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                Gstin
                            </Text>
                        </Box>

                        <Box sx={{

                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: '119px',
                            maxWidth: '119px',
                            width: '119px',

                        }}>
                            <Text sx={{
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '12px',
                                lineHeight: '16px',
                                color: '#414552',
                                position: 'relative',
                                margin: '0px 12px 0px 18px',
                                minWidth: '100%',
                                maxWidth: '100%',
                                width: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                FY
                            </Text>
                        </Box>

                        <Box sx={{

                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: '119px',
                            maxWidth: '119px',
                            width: '119px',
                        }}>
                            <Text sx={{
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '12px',
                                lineHeight: '16px',
                                color: '#414552',
                                position: 'relative',
                                margin: '0px 12px 0px 18px',
                                minWidth: '100%',
                                maxWidth: '100%',
                                width: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                Filing Date
                            </Text>
                        </Box>


                        <Box sx={{

                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: '189px',
                            maxWidth: '189px',
                            width: '189px',

                        }}>
                            <Text sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '18px',
                                color: '#414552',
                                position: 'relative',
                                margin: '0px 12px 0px 18px',
                                minWidth: '100%',
                                maxWidth: '100%',
                                width: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                Gstin Estimated Turnover
                            </Text>
                        </Box>

                        <Box sx={{

                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: '189px',
                            maxWidth: '189px',
                            width: '189px',

                        }}>
                            <Text sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '18px',
                                color: '#414552',
                                position: 'relative',
                                margin: '0px 12px 0px 18px',
                                minWidth: '100%',
                                maxWidth: '100%',
                                width: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                Gstin Final Turnover
                            </Text>
                        </Box>

                        <Box sx={{

                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: '189px',
                            maxWidth: '189px',
                            width: '189px',

                        }}>
                            <Text sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '18px',
                                color: '#414552',
                                position: 'relative',
                                margin: '0px 12px 0px 18px',
                                minWidth: '100%',
                                maxWidth: '100%',
                                width: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                Gstin System Turnover
                            </Text>
                        </Box>



                    </Box>

                    {gstData?.turnOverArray?.map((row, index) => {
                        return (
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                minHeight: '36px',
                                borderBottomWidth: 1,
                                borderBottomStyle: 'solid',
                                borderColor: '#E0E0E0',
                                alignItems: 'center',
                                background: '#FFFFFF',
                                minWidth: 'fit-content',
                                maxWidth: 'fit-content',
                                width: 'fit-content',
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minWidth: '129px',
                                    maxWidth: '129px',
                                    width: '129px',

                                }}>
                                    <Text sx={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '12px',
                                        lineHeight: '18px',
                                        color: customThemVariable.primaryTextColor,
                                        position: 'relative',
                                        margin: '0px 12px 0px 18px',
                                        minWidth: '100%',
                                        maxWidth: '100%',
                                        width: '100%',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}>
                                        {gstData?.gstin || ""}
                                    </Text>
                                </Box>

                                <Box sx={{

                                    display: 'flex',
                                    flexDirection: 'row',
                                    minWidth: '119px',
                                    maxWidth: '119px',
                                    width: '119px',

                                }}>
                                    <Text sx={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '12px',
                                        lineHeight: '16px',
                                        color: customThemVariable.primaryTextColor,
                                        position: 'relative',
                                        margin: '0px 12px 0px 18px',
                                        minWidth: '69px',
                                        maxWidth: '69px',
                                        width: '69px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}>
                                        {row?.finYear || ""}
                                    </Text>
                                </Box>

                                <Box sx={{

                                    display: 'flex',
                                    flexDirection: 'row',
                                    minWidth: '119px',
                                    maxWidth: '119px',
                                    width: '119px',

                                }}>
                                    <Text sx={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '12px',
                                        lineHeight: '16px',
                                        color: customThemVariable.primaryTextColor,
                                        position: 'relative',
                                        margin: '0px 12px 0px 18px',
                                        minWidth: '69px',
                                        maxWidth: '69px',
                                        width: '69px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}>
                                        {row?.aggTurnoverInfoList[0]?.filingDate || ""}
                                    </Text>
                                </Box>


                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minWidth: '189px',
                                    maxWidth: '189px',
                                    width: '189px',
                                }}>
                                    <Text sx={{
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '18px',
                                        color: customThemVariable.primaryTextColor,
                                        position: 'relative',
                                        margin: '0px 12px 0px 18px',
                                        minWidth: '100%',
                                        maxWidth: '100%',
                                        width: '100%',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}>
                                        {row?.aggTurnoverInfoList[0]?.gstinEstTo || ""}
                                    </Text>
                                </Box>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minWidth: '189px',
                                    maxWidth: '189px',
                                    width: '189px',
                                }}>
                                    <Text sx={{
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '18px',
                                        color: customThemVariable.primaryTextColor,
                                        position: 'relative',
                                        margin: '0px 12px 0px 18px',
                                        minWidth: '100%',
                                        maxWidth: '100%',
                                        width: '100%',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}>
                                        {row?.aggTurnoverInfoList[0]?.gstinFinalTo || ""}
                                    </Text>
                                </Box>

                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minWidth: '189px',
                                    maxWidth: '189px',
                                    width: '189px',
                                }}>
                                    <Text sx={{
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '18px',
                                        color: customThemVariable.primaryTextColor,
                                        position: 'relative',
                                        margin: '0px 12px 0px 18px',
                                        minWidth: '100%',
                                        maxWidth: '100%',
                                        width: '100%',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}>
                                        {row?.aggTurnoverInfoList[0]?.gstinSysTo || ""}
                                    </Text>
                                </Box>


                            </Box>
                        )
                    })}





                </Box>


            </Box>





            <AddPanDialog />
            <UploadPanDialog />
            <GSTSyncDialogPlaywright />
            <ChangePasswordGstPanDialog />

        </Box>
    )

}

export default GstPlaywright