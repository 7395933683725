import { Box, Button, Text, IconButton, Dialog, TextInput, Link, Spinner, ActionMenu, ActionList } from "@primer/react"
import { useDispatch, useSelector } from "react-redux"
import React from "react"
import { Toaster, Intent, Position, ProgressBar } from "@blueprintjs/core";
import Workplacetheme from "../../Workplace/Workplacetheme"  
import { changeGstPasswordOpenClose,changeITPasswordOpenClose,updateGstPassword, updateItPassword } from "../../redux/features/portalSlice"
  

export default function ChangePasswordITPanDialog(props){

    const dispatch = useDispatch()
    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail) || localStorage.getItem("userEmail")
    const openCloseITChangePassword = useSelector((state) => state.portalData.openCloseITChangePassword)
    const serverTrigger = useSelector((state) => state.portalData.serverTrigger)
    const itName = useSelector((state) => state.portalData.itName)
    const itUsername = useSelector((state) => state.portalData.itUsername)
    const itPassword = useSelector((state) => state.portalData.itPassword)
    const insertId = useSelector((state) => state.portalData.itinsertId)
    
    const initialFocusRef = React.createRef()
    const [currentRef, setcurrentRef] = React.useState("panName")
    const [showLoadingBtn, setshowLoadingBtn] = React.useState(false)
    const [panNameInput, setpanNameInput] = React.useState({ value: '', validationStatus: '' })
    const [userNameInput, setuserNameInput] = React.useState({ value: '', validationStatus: '' })
    const [userPassword, setuserPassword] = React.useState({ value: '', validationStatus: '' })

    const closeDialog = (props) => {
        
        dispatch(changeITPasswordOpenClose({
            openCloseITChangePassword: false,
            itinsertId: '',
            itName: '',
            itUsername: '',
            itPassword: ''
        }))

    }

    React.useEffect(() => {

        if (openCloseITChangePassword) {
            setpanNameInput({ value: itName, validationStatus: '' })
            setuserNameInput({ value: itUsername, validationStatus: '' })
            setuserPassword({ value: itPassword, validationStatus: '' })
        }

    }, [openCloseITChangePassword])

    const updateItPasswordInDb = (username, password) => {


      //  alert(JSON.stringify({ username: username, password: password }))

        if(username!==null&&password!==null){

            // updateItPassword
 

            dispatch(updateItPassword({
                "userId": userId,
                "userEmail": userEmail,
                "insertId": insertId,
                "ituserName": username,
                "itpassword": password
            })).then((data) => {
    
                   console.log("updateGstPassword",data)

                   const responseUsername = data.payload.data.pan_number
                   const responsePassword = data.payload.data.pan_password
                   if(username===responseUsername&&password===responsePassword){

                    Toaster.create({ position: Position.TOP }).show({
                        message: "Successfully  updated",
                        intent: Intent.SUCCESS
                    });
                    closeDialog("") 
                   }


            })

        }
   

       


    }
 
    return (
        <Dialog
            sx={{ height: '315px', width: '564px' }}
            isOpen={openCloseITChangePassword}
            // isOpen={true}
            onDismiss={() => closeDialog("")}
            aria-labelledby="header-id"
            initialFocusRef={initialFocusRef}
        >
            <Dialog.Header id="header-id"><Text sx={Workplacetheme.scheduleDalogeHeading}>Update password</Text></Dialog.Header>

            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                width: 564,
                height: 'fit-content',
            }}>

                <Text sx={{
                    display: 'flex',
                    position: 'relative',
                    margin: '20px 20px 0px 20px',
                    fontFamily: 'Segoe UI',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: '#6A737D',
                }}>Incorrect password detected update your password</Text>

                <Box sx={{
                    position: 'relative',
                    margin: '23px 20px 0px 20px',
                    fontFamily: 'Segoe UI'
                }}>

                    <TextInput
                        disabled
                        sx={{
                            minHeight: '40px',
                            fontFamily: 'Segoe UI',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '20px'
                        }}
                        ref={currentRef === "panName" ? initialFocusRef : null}
                        onFocus={() => setcurrentRef("panName")}
                        onBlur={() => setcurrentRef("")}
                        width={'100%'}
                        aria-label="panName"
                        name="panName"
                        placeholder="Enter name"
                        value={panNameInput.value}
                        validationStatus={panNameInput.validationStatus}
                        onChange={(e) => {
                            setpanNameInput((prevState) => {
                                const newState = { ...prevState, value: e.target.value, validationStatus: '' }
                                return newState
                            })
                        }}
                    />



                </Box>



                <Box sx={{
                    position: 'relative',
                    margin: '20px 20px 0px 20px',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '15px'
                }}>
                    <TextInput
                        sx={{
                            width: '255px',
                            height: '40px',
                            minHeight: '40px',
                            maxHeight: '40px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',

                        }}
                        ref={currentRef === "userName" ? initialFocusRef : null}
                        onFocus={() => setcurrentRef("userName")}
                        onBlur={() => setcurrentRef("")}
                        placeholder={"Username"}
                        value={userNameInput.value}
                        validationStatus={userNameInput.validationStatus}
                        onChange={(e) => {
                            setuserNameInput((prevState) => {
                                return ({ ...prevState, value: e.target.value, validationStatus: '' })
                            })
                        }}

                    />
                    <TextInput

                        sx={{
                            width: '255px',
                            height: '40px',
                            minHeight: '40px',
                            maxHeight: '40px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',

                        }}
                        type="password"
                        ref={currentRef === "userPassword" ? initialFocusRef : null}
                        onFocus={() => setcurrentRef("userPassword")}
                        onBlur={() => setcurrentRef("")}
                        placeholder={"Enter new password"}
                        value={userPassword.value}
                        validationStatus={userPassword.validationStatus}
                        onChange={(e) => {
                            setuserPassword((prevState) => {
                                return ({ ...prevState, value: e.target.value, validationStatus: '' })
                            })
                        }}

                    />




                </Box>

                <Box
                    sx={{
                        position: 'relative',
                        margin: '13px 20px 10px 20px',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: '#24292F',
                        cursor: 'pointer',
                        minHeight: '20px'
                    }}



                > </Box>

                <Box

                    sx={Workplacetheme.createSpaceDialogFooterLine}

                    borderColor="border.default" borderBottomWidth={1} borderBottomStyle="solid">


                </Box>





            </Box>

            <Box
                ml={24}
                mr={24}
                mb={10}
                display="flex"
                flexDirection="row"
                justifyContent={"right"}
            >

                <Button variant="invisible"
                    sx={Workplacetheme.createSpaceDialogFooterBox.cancelBtn}
                    onClick={() => 
                        closeDialog("")
                    }
                >Cancel</Button>

                {
                    showLoadingBtn === true ?

                        <Button
                            variant="outline"
                            sx={Workplacetheme.createSpaceDialogFooterBox.submitBtn}
                        >
                            <Spinner sx={{
                                position: 'absolute',
                                left: '0px',
                                right: '0px',
                                margin: 'auto',
                                top: '0px',
                                bottom: '0px',
                            }} size="small" />
                        </Button>

                        :

                        <Button
                            style={{ width: '58px' }}
                            variant="outline"

                            sx={Workplacetheme.createSpaceDialogFooterBox.submitBtn}
                            onClick={() => {
                                updateItPasswordInDb(userNameInput.value, userPassword.value)
                            }}
                        >
                            Save

                        </Button>


                }






            </Box>





        </Dialog>
    )
}