import { Box, IconButton, Heading, Text, Token, ActionMenu, Button, ActionList, Label } from "@primer/react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { ChevronLeftIcon, ChevronRightIcon } from "@primer/octicons-react"
import React from "react"
import customTheme from "../../Theme.module.scss"
import customThemVariable from "../../ThemeVariable.module.scss"
import { getGstPortal, getItPortal } from "../redux/features/portalSlice"
import { PersonIcon, ReaderIcon } from "@radix-ui/react-icons"

function CalandarComponent(props) {

    let host = window.location.host
    let hostName = window.location.hostname
    let dispatch = useDispatch()
    const navigate = useNavigate()
    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail) || localStorage.getItem("userEmail")
    const [portal, setPortal] = React.useState(["IT", "GST"])
    const [selectedPortal, setselectedPortal] = React.useState("")

    const [gstList, setgstList] = React.useState([])
    const [selectedGstList, setselectedGstList] = React.useState("")
    const [gstReturns, setgstReturns] = React.useState([])
    const [gstNotices, setgstNotices] = React.useState([])
    const [gstAdditionalNotices, setgstAdditionalNotices] = React.useState([])
    const [gstAdditionalNoticesDetails, setgstAdditionalNoticesDetails] = React.useState([])
    const [gstIntimations, setgstIntimations] = React.useState([])
    const [casesdueDates, setcasesdueDates] = React.useState([])

    const [itList, setItList] = React.useState([])
    const [selectedItList, setselectedItList] = React.useState("")
    const [orderData, setorderData] = React.useState([])
    const [itPnotice, setitPnotice] = React.useState([])
    const [weekdays, setweekdays] = React.useState(["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"])
    const [currentMonth, setCurrentMonth] = React.useState(new Date());
    const [days, setDays] = React.useState([]);

    React.useEffect(() => {
        generateCalendar(currentMonth);
    }, [currentMonth]);

    const generateCalendar = (date) => {
        const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
        const endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        const startDate = startOfMonth;
        const endDate = new Date(endOfMonth);
        startDate.setDate(startDate.getDate() - startDate.getDay());
        endDate.setDate(endDate.getDate() + (6 - endDate.getDay()));
        const daysArray = [];
        let current = new Date(startDate);
        while (current <= endDate) {
            daysArray.push(new Date(current));
            current.setDate(current.getDate() + 1);
        }
        setDays(daysArray);
    };

    const prevMonth = () => {
        setCurrentMonth(new Date(currentMonth.setMonth(currentMonth.getMonth() - 1)));
    };

    const nextMonth = () => {
        setCurrentMonth(new Date(currentMonth.setMonth(currentMonth.getMonth() + 1)));
    };

    //--onchangeItPortal
    const onChangePortal = (props) => {
        setselectedPortal(props)
        if (props === "IT") {
            if (userId !== "" && userEmail !== "") {

                getItPortalFromDb("")
            }
        } else if (props === "GST") {

            if (userId !== "" && userEmail !== "") {

                getGstPortalFromDb("")
                setselectedItList("")
                setItList([])
            }

        } else {
            setselectedItList("")
            setItList([])
        }
    }
    //----on change client name
    const onChangeClient = (props, pType) => {

        if (pType === "IT") {

            setselectedItList(props)
            const filteredData = itList.filter((itData) => itData.insert_id === props)
            console.log("filteredData", filteredData)
            const filteredReturns = filteredData.length > 0 ? filteredData[0]?.playwright_itr_returnfiles : []
            const itPnotices = filteredData.length > 0 ? filteredData[0]?.playwright_itr_pnotices_details : []
            if (filteredReturns?.length > 0) {
                console.log("return founs")
                const orderArray = [...filteredReturns].map((row, index) => {
                    const ackNumber = row.ackNumber
                    const orderArray = [...row.intimationFileArray]
                    return { ackNumber: ackNumber, orderArray: orderArray }
                })
                console.log("orderArray", orderArray)
                setorderData(orderArray)
            }
            if (itPnotices?.length > 0) {
                console.log("itpNotice founds")
                setitPnotice(itPnotices)
            }


        } else if (pType === "GST") {

            setselectedGstList(props)
            const filteredData = gstList.filter((itData) => itData.insert_id === props)
            console.log("filteredDataGST", filteredData)
            const filteredReturns = filteredData.length > 0 ? filteredData[0]?.playwright_gst_returns : []
            const gstNoticesData = filteredData.length > 0 ? filteredData[0]?.playwright_gst_notices : []
            const gstAdditionalNoticeData = filteredData.length > 0 ? filteredData[0]?.playwright_gst_additional_notices : []
            const gstIntimationData = filteredData.length > 0 ? filteredData[0]?.playwright_gst_intimations : []
            const gstAdditionalNoticeDetailsData = filteredData.length > 0 ? filteredData[0]?.playwright_gst_additional_notices_details : []
            console.log("gstNoticesData", gstNoticesData)
            console.log("gstAdditionalNoticeData", gstAdditionalNoticeData)
            console.log("filteredReturnsGST", filteredReturns)
            if (filteredReturns?.length > 0) {

                const newArray = [...filteredReturns].filter(fData => fData.status === "Filed").map((row) => {
                    const dateStr = row?.dateOfFiling
                    const [day, month, year] = dateStr.split('/');
                    const formattedDate = `${year}-${month}-${day}`;
                    return ({ ...row, insert_id: props, dateOfFiling: formattedDate })
                })
                console.log("filteredDataGST", newArray)
                setgstReturns(newArray)

            }
            if (gstNoticesData?.length > 0) {
                const newArray = [...gstNoticesData].map((row) => {
                    const dateOfIssuance = row?.dateOfIssuance
                    const [day, month, year] = dateOfIssuance.split('/');
                    const formattedDatedateOfIssuance = `${year}-${month}-${day}`;

                    const dueDate = row?.dueDate
                    const [dueday, duemonth, dueyear] = dueDate.split('/');
                    const formattedDueDate = `${dueyear}-${duemonth}-${dueday}`;
                    return ({ ...row, insert_id: props, dateOfIssuance: formattedDatedateOfIssuance, dueDate: formattedDueDate })
                })
                console.log("filteredNotices", newArray)
                setgstNotices(newArray)

            }

            if (gstAdditionalNoticeData?.length > 0) {

                const newArray = [...gstAdditionalNoticeData].map((row) => {
                    const dateOfIssuance = row?.issuedDate
                    const [day, month, year] = dateOfIssuance.split('/');
                    const formatteddateOfIssuance = `${year}-${month}-${day}`;
                    return ({ ...row, insert_id: props, issuedDate: formatteddateOfIssuance })
                })
                console.log("filteredAdditionalNotices", newArray)
                setgstAdditionalNotices(newArray)

            }
            if (gstIntimationData?.length > 0) {

                const newArray = [...gstIntimationData].map((row) => {


                    const caseCreationDate = row?.caseCreationDate
                    const [day, month, year] = caseCreationDate.split('/');
                    const formatteddatecaseCreationDate = `${year}-${month}-${day}`;





                    return ({ ...row, insert_id: props, caseCreationDate: formatteddatecaseCreationDate })
                })
                console.log("filteredgstIntimationData", newArray)
                setgstIntimations(newArray)
            }
            if (gstAdditionalNoticeDetailsData?.length > 0) {

                // Use a Map to store unique hearing dates with the associated insert_id
                const uniqueHearingDatesMap = new Map();
                const uniqueCaseDueDatesMap = new Map();

                gstAdditionalNoticeDetailsData.forEach((row) => {
                    const fItemArray = row?.fItemArray || [];

                    // Loop over fItemArray to extract and format hearing dates
                    fItemArray.forEach((row2) => {
                        const hearingdate = row2?.itemJson?.sdtls?.remnd?.phdt || "";
                        const casedueDate = row2?.itemJson?.sdtls?.remnd?.duedt || "";

                        if (hearingdate) {
                            const [day, month, year] = hearingdate.split('/');
                            const formattedhearingdate = `${year}-${month}-${day}`;

                            // Add to the Map with formatted date as the key and insert_id as the value
                            if (!uniqueHearingDatesMap.has(formattedhearingdate)) {
                                uniqueHearingDatesMap.set(formattedhearingdate, { insert_id: props, hearingdate: formattedhearingdate });
                            }
                        }

                        if (casedueDate) {
                            const [day, month, year] = casedueDate.split('/');
                            const formattedcasedueDate = `${year}-${month}-${day}`;
                            // Add to the Map with formatted date as the key and insert_id as the value
                            if (!uniqueCaseDueDatesMap.has(formattedcasedueDate)) {
                                uniqueCaseDueDatesMap.set(formattedcasedueDate, { insert_id: props, casedueDate: formattedcasedueDate });
                            }
                        }


                    });
                });

                // Convert the Map values to an array to get unique hearing dates with insert_id
                const uniqueHearingDates = Array.from(uniqueHearingDatesMap.values());
                const uniqueCaseDueDates = Array.from(uniqueCaseDueDatesMap.values());
                //  console.log("filteredhearingdate",uniqueHearingDates)
                console.log("filteredhearingdate", uniqueCaseDueDates)
                setgstAdditionalNoticesDetails(uniqueHearingDates)
                setcasesdueDates(uniqueCaseDueDates)
            }


        }



    }
    //----get data from database
    const getItPortalFromDb = (props) => {
        dispatch(getItPortal({
            userId: userId,
            userEmail: userEmail
        })).then((data) => {

            console.log("itList", data.payload.data)
            setItList(data.payload.data)

        })
    }
    //---- 
    const getGstPortalFromDb = (props) => {

        dispatch(getGstPortal({
            userId: userId,
            userEmail: userEmail,
            pageType: "Calander"
        })).then((data) => {
            console.log("getgstList", data.payload)
            setgstList(data.payload.data)
        })

    }

    return (
        <Box
            sx={{
                position: 'relative',
                background: '#FFFFFF',
                marginTop: '13px',
                borderRadius: '8px',
                minWidth: '1144px',
                maxWidth: '1144px',
                width: '1144px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}>


            <Box
                sx={{
                    width: '1054px',
                    minWidth: '1054px',
                    maxWidth: '1054px',
                    minHeight: '40px',
                    maxHeight: '40px',
                    height: '40px',
                    marginTop: '27px',
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center'
                }}>

                <Text sx={{
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '20px',
                    lineHeight: '26px',
                    color: '#24292e',
                }}>Calandar</Text>

                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    minHeight: '40px',
                    maxHeight: '40px',
                    position: 'absolute',
                    right: '0px',
                    gap: '24px'
                }}>
                    <ActionMenu
                        onSelect={(newValue) => alert(`Pressed ${newValue}`)}
                    >
                        <ActionMenu.Anchor>
                            <Button sx={{
                                minHeight: '40px',
                                minWidth: '248px',
                                minWidth: '248px',
                                maxWidth: '248px',
                                width: '248px',
                                display: 'flex',
                                alignItems: 'center',
                                fontFamily: 'Inter',
                                fontWeight: '400',
                                fontSize: '14px'
                            }} leadingIcon={() => <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><ReaderIcon /></Box>}>
                                {selectedPortal === "" ? "Select Compliance Type" : selectedPortal}

                            </Button>
                        </ActionMenu.Anchor>
                        <ActionMenu.Overlay aria-label="GitHub features">
                            <ActionList sx={{
                                minWidth: '238px',
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',
                                display: 'flex',
                                flexDirection: 'column',
                                margin: '6px',
                                alignItems: 'center',
                                gap: '1px'
                            }}>
                                {portal.map((row, index) => {
                                    return (
                                        <ActionList.Item
                                            onSelect={() => {
                                                onChangePortal(row)
                                            }}
                                        >
                                            {row}
                                        </ActionList.Item>
                                    )
                                })}


                            </ActionList>
                        </ActionMenu.Overlay>
                    </ActionMenu>
                    <ActionMenu
                        onSelect={(newValue) => alert(`Pressed ${newValue}`)}
                    >
                        <ActionMenu.Anchor>
                            <Button sx={{
                                minHeight: '40px',
                                minWidth: '248px',
                                maxWidth: '248px',
                                width: '248px',
                                display: 'flex',
                                alignItems: 'center',
                                fontFamily: 'Inter',
                                fontWeight: '400',
                                fontSize: '14px'
                            }} leadingIcon={() => <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><PersonIcon /></Box>}>

                                {/* {selectedItList==="" && selectedPortal === "IT"? "Select Client" :
                                 itList?.filter((itData)=> itData?.insert_id===selectedItList)[0]?.pan_name
                                }
                                {selectedGstList===""&& selectedPortal === "GST" ? "" :
                                 gstList?.filter((itData)=> itData?.insert_id===selectedGstList)[0]?.pan_name
                                } */}

                                {selectedPortal === "IT" ?
                                    (selectedItList === "" ? "Select Client" :
                                        itList?.filter((itData) => itData?.insert_id === selectedItList)[0]?.pan_name
                                    )
                                    :
                                    selectedPortal === "GST" ?
                                        (
                                            selectedGstList === "" ? "Select Client" :
                                                gstList?.filter((itData) => itData?.insert_id === selectedGstList)[0]?.pan_name
                                        )
                                        :

                                        "Select Client"}


                            </Button>


                        </ActionMenu.Anchor>
                        <ActionMenu.Overlay aria-label="GitHub features" sx={{ overflowY: 'auto', maxHeight: '300px' }}>
                            <ActionList sx={{
                                minWidth: '238px',
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',
                                display: 'flex',
                                flexDirection: 'column',
                                margin: '6px',
                                alignItems: 'center',
                                gap: '1px'
                            }}>
                                {selectedPortal === "IT" ? itList.map((row, index) => {
                                    return (
                                        <ActionList.Item
                                            onSelect={() => {
                                                onChangeClient(row.insert_id, "IT")
                                            }}
                                        >
                                            {row.pan_name}
                                        </ActionList.Item>
                                    )
                                }) :
                                    ""
                                }

                                {
                                    selectedPortal === "GST" ?
                                        gstList.map((row, index) => {
                                            return (
                                                <ActionList.Item
                                                    onSelect={() => {
                                                        onChangeClient(row.insert_id, "GST")
                                                    }}
                                                >
                                                    {row.pan_name}
                                                </ActionList.Item>
                                            )
                                        })
                                        : ""
                                }


                            </ActionList>
                        </ActionMenu.Overlay>
                    </ActionMenu>
                </Box>


            </Box>




            <Box sx={{
                margin: '25px auto 0px auto',
                minWidth: '1098px',
                maxWidth: '1098px',
                width: '1098px',
                display: 'flex',
                flexDirection: 'column',
                fontFamily: customThemVariable.primartFont,
            }}>

                {/*calandar header----  */}
                <Box sx={{
                    margin: '0px 0px 0px 0px',
                    minHeight: '40px',
                    height: '40px',
                    maxHeight: '40px',
                    minWidth: '1098px',
                    maxWidth: '1098px',
                    width: '1098px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative',
                    fontFamily: customThemVariable.primartFont,
                }}>
                    <IconButton
                        icon={() => <ChevronLeftIcon size={18} />}
                        sx={{ border: 'none', position: 'absolute', left: '0px' }}
                        onClick={() => { prevMonth("") }}
                    />
                    <Heading sx={{
                        fontSize: '16px', fontFamily: 'Inter'
                    }}>{currentMonth.toLocaleString('default', { month: 'long' })} {currentMonth.getFullYear()}</Heading>
                    <IconButton
                        icon={() => <ChevronRightIcon size={18} />}
                        sx={{ border: 'none', position: 'absolute', right: '0px' }}
                        onClick={() => { nextMonth("") }}
                    />
                </Box>
                {/* ------------------- */}


                <Box sx={{
                    margin: '0px 0px 0px 0px',
                    minWidth: '1098px',
                    maxWidth: '1098px',
                    width: '1098px',
                    display: 'flex',
                    flexDirection: 'column'
                }}>

                    {/* Header row */}

                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                        }}
                    >
                        {/* Create 7 columns */}
                        {weekdays.map((row, index) => (
                            <Box
                                key={index}
                                sx={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    minHeight: '28px',
                                    maxHeight: '28px',
                                    borderBottom: '1px solid #b7b7b7',
                                    borderRight: '1px solid #b7b7b7',
                                    borderTop: '1px solid #b7b7b7',
                                    borderLeft: index == 0 ? '1px solid #b7b7b7' : '0px solid #b7b7b7'
                                }}
                            >
                                <Text sx={{ fontSize: '14px' }}>{row}</Text>
                            </Box>
                        ))}

                    </Box>

                    {/* ---------- */}

                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            overflow: 'hidden',
                            gap: 0, // Space between columns
                        }}
                    >
                        {days.map((day, index) => (

                            <Box
                                key={index}
                                sx={{
                                    flex: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: "2px",
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    minWidth: '146px',
                                    minHeight: '100px',
                                    maxHeight: '100px',
                                    borderBottom: '1px solid #b7b7b7',
                                    borderRight: '1px solid #b7b7b7',
                                    borderLeft: index % 7 ? '0px solid #b7b7b7' : '1px solid #b7b7b7',
                                    background: day.getMonth() !== currentMonth.getMonth() ? '#EEEDEB' : "white",
                                    position: 'relative',
                                }}

                            >
                                <Text sx={{ position: 'absolute', left: '0px', top: '0px', fontSize: '14px' }}>{day.getDate()}</Text>
                                {selectedPortal === "IT" ? orderData?.map((row, index) => {

                                    return (

                                        row?.orderArray?.map((row2, index2) => {

                                            return (

                                                <>

                                                    <Token
                                                        sx={{
                                                            display: new Date(row2?.orderDate?.replace("IST", "GMT+0530")).toDateString() == new Date(day).toDateString() ? 'flex' : 'none',
                                                            visibility: day.getMonth() !== currentMonth.getMonth() ? "hidden" : "visible",
                                                            minHeight: '30px',
                                                            border: 'none',
                                                            background: 'red',
                                                            color: 'white',
                                                            fontSize: '11px',
                                                            boxShadow: 'shadow.large',
                                                            ':hover': {
                                                                background: 'red',
                                                                color: 'white',
                                                            }
                                                        }}
                                                        text="Intimation Issued"
                                                        onClick={() => {
                                                            const newSelectedList = itList.filter((data) => data?.insert_id === selectedItList)
                                                            const returnData = newSelectedList[0]?.playwright_itr_return_latest?.filter((fData) => fData?.ackNum === row?.ackNumber)
                                                            const returnFileList = newSelectedList[0]?.playwright_itr_returnfiles?.filter((fData) => fData?.ackNumber === row?.ackNumber) || []
                                                            if (returnData?.length > 0 && returnData !== undefined) {

                                                                localStorage.setItem("pageRoute", "")
                                                                localStorage.setItem("expData", JSON.stringify(returnData[0]))
                                                                localStorage.setItem("expFileData", JSON.stringify(returnFileList))
                                                                navigate('/Itrexpansion')

                                                            }

                                                        }}
                                                    />
                                                </>

                                            )
                                        })
                                    )
                                })
                                    : ""
                                }
                                {selectedPortal === "IT" ? itPnotice?.map((row, index) => {

                                    return (
                                        row?.responseDueDate !== null && row?.responseDueDate !== undefined ?
                                            <>

                                                <Token
                                                    sx={{
                                                        display: new Date(row?.responseDueDate).toDateString() == new Date(day).toDateString() ? 'flex' : 'none',
                                                        visibility: day.getMonth() !== currentMonth.getMonth() ? "hidden" : "visible",
                                                        minHeight: '30px',
                                                        border: 'none',
                                                        background: 'red',
                                                        color: 'white',
                                                        fontSize: '11px',
                                                        boxShadow: 'shadow.large',
                                                        ':hover': {
                                                            background: 'red',
                                                            color: 'white',
                                                        }
                                                    }}
                                                    text="Proceeding notice due"
                                                    onClick={() => {

                                                        localStorage.setItem("insert_id", selectedItList)
                                                        navigate('/proceeding')
                                                    }}
                                                />
                                            </> : ""
                                    )
                                })
                                    : ""
                                }
                                {selectedPortal === "IT" ? itPnotice?.map((row, index) => {

                                    return (
                                        row?.issuedOn !== null && row?.issuedOn !== undefined ?
                                            <>

                                                <Token
                                                    sx={{
                                                        display: new Date(row?.issuedOn).toDateString() == new Date(day).toDateString() ? 'flex' : 'none',
                                                        visibility: day.getMonth() !== currentMonth.getMonth() ? "hidden" : "visible",
                                                        minHeight: '30px',
                                                        border: 'none',
                                                        background: 'red',
                                                        color: 'white',
                                                        fontSize: '11px',
                                                        boxShadow: 'shadow.large',
                                                        ':hover': {
                                                            background: 'red',
                                                            color: 'white',
                                                        }
                                                    }}
                                                    text="Proceeding notice issued"
                                                    onClick={() => {

                                                        localStorage.setItem("insert_id", selectedItList)
                                                        navigate('/proceeding')
                                                    }}
                                                />
                                            </> : ""
                                    )
                                })
                                    : ""
                                }
                                {selectedPortal === "GST" &&
                                    (
                                        gstReturns.map((row2, index2) => {

                                            return (

                                                <Label
                                                    sx={{
                                                        display: new Date(row2.dateOfFiling).toDateString() == new Date(day).toDateString() ? 'flex' : 'none',
                                                        visibility: day.getMonth() !== currentMonth.getMonth() ? "hidden" : "visible",
                                                        width: 'fit-content',
                                                        minHeight: '20px',
                                                        border: 'none',
                                                        background: '#ccfbf1',
                                                        color: '#0f766e',
                                                        fontSize: '11px',
                                                        boxShadow: 'shadow.large',
                                                        ':hover': {
                                                            background: '#ccfbf1',
                                                            color: '#0f766e',
                                                            cursor: "pointer"
                                                        }
                                                    }}
                                                    // text="Return Filed"
                                                    onClick={() => {
                                                        // const newSelectedList = itList.filter((data) => data?.insert_id === selectedItList)
                                                        localStorage.setItem("insert_id", row2.insert_id)
                                                        localStorage.setItem("pageRoute", "taxheroPortalGst")
                                                        navigate('/gstexpansion')
                                                    }}
                                                >
                                                    Return Filed
                                                </Label>
                                            )
                                        })




                                    )


                                }
                                {selectedPortal === "GST" &&
                                    (
                                        gstNotices.map((row2, index2) => {

                                            return (
                                                <>
                                                    <Label
                                                        sx={{
                                                            display: new Date(row2.dateOfIssuance).toDateString() == new Date(day).toDateString() ? 'flex' : 'none',
                                                            visibility: day.getMonth() !== currentMonth.getMonth() ? "hidden" : "visible",
                                                            width: 'fit-content',
                                                            minHeight: '20px',
                                                            border: 'none',
                                                            background: 'linear-gradient(135deg, rgb(56, 189, 248) 24%, rgb(254, 243, 199) 24%)',
                                                            color: '#b45309',
                                                            fontSize: '11px',
                                                            boxShadow: 'shadow.large',
                                                            ':hover': {
                                                                background: 'linear-gradient(135deg, rgb(56, 189, 248) 24%, rgb(254, 243, 199) 24%)',
                                                                color: '#b45309',
                                                                cursor: 'pointer'
                                                            }
                                                        }}
                                                        //text="Notice Issued"
                                                        onClick={() => {
                                                            localStorage.setItem("insert_id", row2.insert_id)
                                                            navigate('/notice')
                                                        }}
                                                    >
                                                        Notice Issued
                                                    </Label>
                                                    <Label
                                                        sx={{
                                                            display: new Date(row2.dueDate).toDateString() == new Date(day).toDateString() ? 'flex' : 'none',
                                                            visibility: day.getMonth() !== currentMonth.getMonth() ? "hidden" : "visible",
                                                            width: 'fit-content',
                                                            minHeight: '20px',
                                                            border: 'none',
                                                            background: 'linear-gradient(135deg, rgb(249, 115, 22) 24%, rgb(254, 243, 199) 24%)',
                                                            color: '#7c2d12',
                                                            fontSize: '11px',
                                                            boxShadow: 'shadow.large',
                                                            ':hover': {
                                                                background: 'linear-gradient(135deg, rgb(249, 115, 22) 24%, rgb(254, 243, 199) 24%)',
                                                                color: '#7c2d12',
                                                                cursor: 'pointer'
                                                            }
                                                        }}
                                                        // text="Notice Due"
                                                        onClick={() => {
                                                            localStorage.setItem("insert_id", row2.insert_id)
                                                            navigate('/notice')
                                                        }}
                                                    >
                                                        Notice Due
                                                    </Label>
                                                </>

                                            )
                                        })


                                    )


                                }
                                {selectedPortal === "GST" &&
                                    (
                                        gstAdditionalNotices.map((row2, index2) => {

                                            return (
                                                <>
                                                    <Label
                                                        sx={{
                                                            display: new Date(row2.issuedDate).toDateString() == new Date(day).toDateString() ? 'flex' : 'none',
                                                            visibility: day.getMonth() !== currentMonth.getMonth() ? "hidden" : "visible",
                                                            width: 'fit-content',
                                                            minHeight: '20px',
                                                            border: 'none',
                                                            background: 'linear-gradient(135deg, rgb(182, 107, 253) 24%, rgb(170, 254, 233) 24%)',
                                                            color: '#6b7280',
                                                            fontSize: '11px',
                                                            boxShadow: 'shadow.large',
                                                            ':hover': {
                                                                background: 'linear-gradient(135deg, rgb(182, 107, 253) 24%, rgb(170, 254, 233) 24%)',
                                                                color: '#6b7280',
                                                                cursor: 'pointer'
                                                            }
                                                        }}
                                                        // text="Additional Notice Issued"
                                                        onClick={() => {
                                                            localStorage.setItem("insert_id", row2.insert_id)
                                                            navigate('/additionalnotice')
                                                        }}
                                                    >
                                                        Additional Notice Issued
                                                    </Label>

                                                </>

                                            )
                                        })


                                    )


                                }
                                {selectedPortal === "GST" &&
                                    (
                                        gstIntimations.map((row2, index2) => {

                                            return (
                                                <>
                                                    <Label
                                                        sx={{
                                                            display: new Date(row2.caseCreationDate).toDateString() == new Date(day).toDateString() ? 'flex' : 'none',
                                                            visibility: day.getMonth() !== currentMonth.getMonth() ? "hidden" : "visible",
                                                            width: 'fit-content',
                                                            minHeight: '20px',
                                                            border: 'none',
                                                            background: 'linear-gradient(135deg, rgb(56, 189, 248) 24%, rgb(254, 215, 170) 24%)',
                                                            color: '#b45309',
                                                            fontSize: '11px',
                                                            boxShadow: 'shadow.large',
                                                            ':hover': {
                                                                background: 'linear-gradient(135deg, rgb(56, 189, 248) 24%, rgb(254, 215, 170) 24%)',
                                                                color: '#b45309',
                                                                cursor: 'pointer'
                                                            }
                                                        }}
                                                        onClick={() => {
                                                            localStorage.setItem("insert_id", row2.insert_id)
                                                            navigate('/additionalnotice')
                                                        }}
                                                    >
                                                        Cases Issued
                                                    </Label>

                                                </>

                                            )
                                        })


                                    )


                                }
                                {selectedPortal === "GST" &&
                                    (
                                        gstAdditionalNoticesDetails?.map((row2, index2) => {

                                            return (
                                                <>
                                                    <Label
                                                        sx={{
                                                            display: new Date(row2.hearingdate).toDateString() == new Date(day).toDateString() ? 'flex' : 'none',
                                                            visibility: day.getMonth() !== currentMonth.getMonth() ? "hidden" : "visible",
                                                            width: 'fit-content',
                                                            minHeight: '20px',
                                                            border: 'none',
                                                            background: '#fcd34d',
                                                            color: '#92400e',
                                                            fontSize: '11px',
                                                            boxShadow: 'shadow.large',
                                                            ':hover': {
                                                                background: '#fcd34d',
                                                                color: '#92400e',
                                                                cursor: 'pointer'
                                                            }
                                                        }}

                                                        onClick={() => {
                                                            localStorage.setItem("insert_id", row2.insert_id)
                                                            navigate('/additionalnotice')
                                                        }}
                                                    >
                                                        Hearing date
                                                    </Label>

                                                </>

                                            )

                                        })


                                    )


                                }
                                {selectedPortal === "GST" &&
                                    (
                                        casesdueDates?.map((row2, index2) => {

                                            return (
                                                <>
                                                    <Label
                                                        sx={{
                                                            display: new Date(row2.casedueDate).toDateString() == new Date(day).toDateString() ? 'flex' : 'none',
                                                            visibility: day.getMonth() !== currentMonth.getMonth() ? "hidden" : "visible",
                                                            width: 'fit-content',
                                                            minHeight: '20px',
                                                            border: 'none',
                                                            background: 'linear-gradient(135deg, #F97316 24%, #FED7AA 24%)', //'blue',
                                                            color: '#b45309',
                                                            fontSize: '11px',
                                                            boxShadow: 'shadow.large',
                                                            ':hover': {
                                                                background: 'linear-gradient(135deg, #F97316 24%, #FED7AA 24%)',
                                                                color: '#b45309',
                                                                cursor: 'pointer'
                                                            }
                                                        }}

                                                        onClick={() => {
                                                            localStorage.setItem("insert_id", row2.insert_id)
                                                            navigate('/additionalnotice')
                                                        }}
                                                    >
                                                        Case due date
                                                    </Label>

                                                </>

                                            )

                                        })


                                    )


                                }


                            </Box>
                        ))}

                    </Box>


                </Box>
                <Box sx={{ minHeight: '60px', width: '1200px' }}></Box>

            </Box>




        </Box>
    )

}

export default CalandarComponent