import React from "react";
import { Box,IconButton } from "@primer/react";
import { useDispatch,useSelector } from "react-redux";
import {rightSidebarOpenClose} from "../redux/features/headerSlice"
import { ChevronLeftIcon } from "@radix-ui/react-icons";


function RightSideBarOpen(){

      const dispatch = useDispatch()
      const headerData = useSelector((state)=> state.headerData )  
       
      const showRightSideBar = (props) => {

            dispatch(rightSidebarOpenClose({
                  openClose:false
            }))
      }

      return(
        <Box 
        zIndex={2}
        display={headerData.openClose===true?'flex':"none"}
        sx={{
            position:'fixed',
            height:'42px',
            width:'64px',
           // background:'red',
            bottom:'0px',
            right:'0px',
            
        }}>

              <IconButton
                  sx={{
                      display:'flex',
                      position: "absolute",
                      height: '30px',
                      width: '30px',
                      left: '0px',
                      right: '0px',
                      bottom: '20px',
                      margin: 'auto',
                      display: 'flex',
                      alignItems:'center',
                      justifyContent: 'center',
                      border:'1px solid #D0D7DE',
                      borderRadius:'3px',
                      boxShadow:'0px 8px 24px rgba(140, 149, 159, 0.2)',
                      color:'#000000',
                      background:'white'
                  }}
                  aria-label="Search"
                  icon={() => <ChevronLeftIcon style={{display:'flex'}} />}
                  onClick={() => showRightSideBar("")}
                  size="small"
                  variant="invisible"
              />
           
        </Box>
      )
}

export default RightSideBarOpen;