


export default {

    logoStyle:{

        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        margin: 'auto 0px auto 30px',
        height: '24px',
        width: '84px',
        cursor:'pointer'
    },
    logoStyleTaxhero:{
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        margin: 'auto 0px auto 0px',
        height: '24px',
        width: '84px',
        cursor:'pointer'
    },
    headerBox:{
    
        height: '48px',
        width: '100%',
        
    },
    headerInnerStyle:{
        // width:1280,
        // height:48,
        // position:'relative',
        display:'flex',
        // margin:'0px auto 0px auto',
        border:'none',
        position:'absolute',
        left:50,
        right:80,
        top:2,
        
    },
    headerProfileBox:{
        width:147,
        height:32,
        display:'flex',
        flexDirection:'row',
        position:'relative',
        margin:'auto 10px auto auto'
    },
    headerProfileAvatar:{
        display:'flex',
        position:'relative',
        margin:'0px 0px 0px auto',
        cursor:'pointer',
        minWidth:'32px',
        minHeight:'32px',
        maxWidth:'32px',
        maxHeight:'32px',
        height:'32px',
        width:'32px',
       
         
    },

    profileActionMenuOverlay:{

        width:181,
        minWidth:181,
        maxWidth:181,
        height:'auto',
        minHeight:192,
        maxHeight:'auto',
        marginTop:'9px'
    },
    questionActionMenuOverlay:{

        width:181,
        minWidth:181,
        maxWidth:181,
        height:192,
        minHeight:192,
        maxHeight:192,
        // background:'red',
        marginTop:'14px'
    },
    profileActionMenuList:{
         
        minWidth:165,
        maxWidth:165,
        position:'relative',
        margin:'0px auto 0px auto'
    },
    profileActionMenuListItem:{

        position:'relative',
        margin:0,
        fontFamily:"Segoe UI",  
        fontStyle:"normal",
        fontSize:"14px",
        fontWeight:"400",  
        color:"#24292F",
        lineHeight:"20px"
    },
    profileActionMenuListItemDanger:{
        position:'relative',
        margin:0,
        fontFamily:"Segoe UI",  
        fontStyle:"normal",
        fontSize:"14px",
        fontWeight:"400",  
        color:"#CF222E",
        lineHeight:"20px"
    },
    headerIcons:{
       
        position:'relative',
        margin:'auto 22px auto 0px',
        cursor:'pointer'
    },
    sideNavMain:{
        position:'fixed',
        width:64,
        height:'100%',
        background:'#FFFFFF'
    },
    sideNavIconset:{
        width:46,
        height:382,
        display:'flex',
        flexDirection:'column',
        position:'relative',
        margin:'78px auto 0px auto',
       
    },
    sideNavIconsetIconBox:{
        width:46,
        height:58,
        display:'flex',
        flexDirection:'column',
        position:'relative',
        margin:'0px auto 20px auto',
        cursor:'pointer',
        alignItems:'center',
        iconButton:{
            height:'40px',
            width:'40px',
            borderRadius:'12px',
            color:'#586069',
            boxSizing:'border-box',
            borderWidth:'1px',
            borderColor:'#E1E4E8',
            background:'#ffffff' 
        },
        iconButtonSelected:{
            height:'40px',
            width:'40px',
            borderRadius:'12px',
            color:'#1F5EFF',
            boxSizing:'border-box',
            borderWidth:'1.4px',
            borderColor:'#1F5EFF',
            background:'#ffffff' 
        },
        text:{
            fontFamily:"Avenir LT Std",
            fontStyle:'normal',
            fontWeight:'600',
            fontSize:'11px',
            lineHeight:'13px',
            color:'#24292F',
            display:'flex',
            position:'relative',
            margin:'5px auto 0px auto',
        },
        textSelected:{
            fontFamily:'Avenir LT Std',
            fontStyle:'normal',
            fontWeight:'600',
            fontSize:'11px',
            lineHeight:'13px',
            color:'#1F5EFF',
            display:'flex',
            position:'relative',
            margin:'5px auto 0px auto',
        } 
        
    },
    workplaceMain:{
        display:'flex',
        flexDirection:'column',
        position:'relative',
        margin:'48px 0px 0px 64px',
        width:'100%',
        minHeight:'100vh',
         
    },
    
    workplaceMainSubheader:{
        display:'flex',
        flexDirection:'row',
        position:'absolute',
        top:38,
        left:50,
        right:80,
        height:'fit-content',
        
    },

    workplaceMainHeading:{
        display:'flex',
        position:'relative',
        margin:'0px 0px 0px 0px',
        height:'52px',
        width:'181px',
        fontFamily:'Avenir LT Std',
        fontStyle:'normal',
        fontWeight:'400',
        fontSize:'27px',
        lineHeight:'52px',
        textAlign:'center',
        color:'#121212'
    },
    createWorkplaceBtn:{
        display:"flex",
        position:'relative',
        margin:'auto 0px auto auto',
        height:'32px',
        width:'142px',
        background: '#0965CE',
        color: '#FFFFFF',
        fontFamily:'Segoe UI',
        fontStyle:'normal',
        fontWeight:'600',
        fontSize:'14px',
        lineHeight:'20px',
    },
    worplaceOpenByText:{
        position: 'absolute',
        height: 30,
        width: 130,
        padding:0, 
        top:89, 
        left:51,
        display:'flex',
        alignItems:'center',
        color: '#586069',
        fontFamily: 'Segoe UI',
        fontSize: '12px',
        fontWeight: '400',
        dropDownIcon:{
            display:'flex',
            position:'relative',
            margin:'auto 0px auto 0px'
        }

    },
    workplaceListMainBox:{
        display:'flex',
        flexDirection:'column',
        position:'absolute',
        top:135,
        left:50,
        right:80,
        height:'fit-content',
    },
    workplaceListItemBox:
    {

        // height:'auto',
        // dispay:'flex',
        // flexWrap:'wrap',
        // minHeight:'453px',
        // width:'100%',
        // background:'#FFFFFF',
        // position:'relative',
        // margin:'0px 0px 32px 0px',
        // border:'1px solid #D0D7DE',
        // borderRadius:'6px',
        // boxSizing:'border-box'


        height:'auto',
        minHeight:'453px',
        width:'100%',
        background:'#FFFFFF',
        position:'relative',
        margin:'0px 0px 32px 0px',
        border:'1px solid #D0D7DE',
        borderRadius:'6px',
        boxSizing:'border-box'
       
    },
    workplaceNameTxt:
    {
        dispay:'flex',
        position:'relative',
        height:52,
       // width:104,
        margin:'0px 0px 0px 0px',
        fontFamily:'Segoe UI',
        fontStyle:'normal',
        fontSize:'17px',
        fontWeight:'400',
        lineHeight:'52px',
        color:'#121212',
        cursor:'pointer',
    },
    workplaceSettingsIcon:
    {
        position:'absolute',
        top:28,
        right:31,
        background:'#ffffff',
        color:'#000000',
        border:'none',
    },
    workplaceShareIcon:{
        position:'absolute',
        top:28,
        right:67,
        background:'#ffffff',
        color:'#000000',
        border:'none',
    },
    spaceList:{
        height:'auto',
        display:'flex',
        flexDirection:'row',
        flexWrap:'wrap',
        position:'relative',
        margin:'21px 30px 0px 30px',
        background:'white',
       
    },
    spaceBox:{
        display:'flex',
        flexDirection:'column',
        position:'relative',
        borderStyle:'solid',
        borderWidth:1,
        borderColor:"border.default",
        height:150,
        width:150,
        margin:'0px 25px 25px 0px',
        border:'1px solid #D0D7DE',
        borderRadius:'6px',
        boxSizing:'border-box',
        cursor:'pointer'
    },
    spaceSettingsIcon:{

        height:15,
        width:15,
        position:'absolute',
        top:'10px',
        right:'10px',
      //  background:'#ffffff',
        color:'#000000',
        border:'none',
        display:'none',
        alignItems:'center',
        justifyContent:'center'
        
    },
    spaceSettingsIconHover:{

        height:15,
        width:15,
        position:'absolute',
        top:'10px',
        right:'10px',
      //  background:'#ffffff',
        color:'#000000',
        border:'none',
        display:'flex',
        alignItems:'center',
        justifyContent:'center'
        
    },
    spaceBoxHeading:{
        display:'flex',
        
        position:'relative',
        margin:'33px auto 0px auto',
        height:'52px',
       // width:'24px',
        fontFamily:'Segoe UI',
        fontStyle:'normal',
        fontSize:'35px',
        lineHeight:'52px',
        color:'#DE2424'
    },
    spaceAddBox:{
        display:'flex',
        position:'relative',
        borderStyle:'solid',
        borderWidth:1,
        borderColor:"border.default",
        height:150,
        width:150,
        margin:'0px 25px 25px 0px',
        alignItems:'center',
        justifyContent:'center',
        border:'1px solid #D0D7DE',
        borderRadius:'6px',
        boxSizing:'border-box',
        cursor:'pointer',
        background:'white'
    },
    spaceBoxAddBoxIcon:{
        height:30,width:30,color:'#000000',cursor:'pointer'
    },
    spaceBoxText:{
        width:'107px',
        minWidth:'107px',
        maxWidth:'107px',
        textOverflow:'ellipsis',
        whiteSpace:'nowrap',
        overflow:'hidden',
        display:'flex',
        position:'absolute',
        top:85,
        left:21,
        right:21,
        bottom:13,
        height:'52px',
        width:'107px',
        fontFamily:'Segoe UI',
        fontSize:'14px',
        lineHeight:'52px',
        color:'#000000',
    },
    sheduleDialog:{
        height:455,
        width:564
    },
    scheduleDalogeHeading:{
        fontFamily:'Segoe UI',
        fontSize:'14px',
        fontStyle:'normal',
        fontWeight:'600',
        lineHeight:'20px',
        color:'#000000',
    },
    scheduleDialogContentBox:{
        display:'flex',
        flexDirection:'column',
        width:564,
        height:350,
    },
    schedulefirstNameLastNameBox:{
        display:'flex',
        flexDirection:'row',
        height:32,
        position:'relative',
        margin:'44px 24px 0px 24px',
        firstNameInput:{
            display:'flex',
            width:248,
            fontFamily:'Segoe UI',
            fontSize:'14px',
            position:'relative',
            margin:'0px 0px 0px 0px'
        },
        lastNameInput:{
            display:'flex',
            width:248,
            fontFamily:'Segoe UI',
            fontSize:'14px',
            position:'relative',
            margin:'0px 0px 0px 20px'
        }
    },
    scheduleEmailPhoneBox:{
        display:'flex',
        flexDirection:'row',
        height:32,
        position:'relative',
        margin:'30px 24px 0px 24px',
        emailInput:{
            display:'flex',
            width:248,
            fontFamily:'Segoe UI',
            fontSize:'14px',
            position:'relative',
            margin:'0px 0px 0px 0px'
        },
        phoneInput:{
            display:'flex',
            width:248,
            fontFamily:'Segoe UI',
            fontSize:'14px',
            position:'relative',
            margin:'0px 0px 0px 20px'
        }
    },
    scheduleDropdownBox:
    {
           display: 'flex',
           flexDirection: 'row',
           height: 32,
           position: 'relative',
           margin: '30px 24px 0px 24px',
           firstBox:{
            display:'flex',
            position:'relative',
            margin:'0px 0px 0px 0px',
            height:32,
            width:248
           },
           secondBox:{
            display:'flex',
            position:'relative',
            margin:'0px 0px 0px 20px',
            height:32,
            width:248
           },
           selectComponent:{
            fontFamily: 'Segoe UI',
            fontStyle: 'normal',
            fontWeight:'400',
            fontSize: '14px',
            color:'#24292F',
           }
    },
    scheduleTextAreaBox:{
        display: 'flex',
        flexDirection: 'row',
        height: 102,
        position: 'relative',
        margin: '30px 24px 0px 24px',
        textAreaInput:{
            width:'100%',
            fontFamily: 'Segoe UI',
            fontStyle: 'normal',
            fontWeight:'400',
            fontSize: '14px',
            color:'#24292F',
        }
    },
    scheduleFooterBox:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'right',
        margin:'0px 24px 0px 24xp',
        cancelBtn:{
            display:'flex',
            position:'relative',
            margin:'9px 0px 0px 0px',
            height:32,
            width:77,
            fontFamily: 'Segoe UI',
            fontWeight: '600',
            fontStyle: 'normal',
            fontSize: '14px',
        },
        submitBtn:{
            display:'flex',
            position:'relative',
            margin:'9px 0px 0px 8px',
            height:32,
            width:77,
            fontFamily: 'Segoe UI',
            fontWeight: '600',
            fontStyle: 'normal',
            fontSize: '14px',
            background:'#0965CE',
            color:'#FFFFFF'
        }
    },
    createSpaceDialodBoxText:{
        display:'flex',
        position:'relative',
        margin:'20px 0px 0px 20px',
        fontFamily: 'Segoe UI',
        fontStyle: 'normal',
        fontWeight:'400',
        fontSize: '14px',
        lineHeight:'20px',
        color:'#6A737D',
    },
    createSpaceDialodBoxNameInput:
    {
        display: 'flex',
        position: 'relative',
        margin: '30px 20px 0px 20px',
        fontFamily: 'Segoe UI',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#6A737D',
        height: 40,
    },
    createSpaceDialodBoxTextArea:
    {
        display: 'flex',
        position: 'relative',
        margin: '30px 20px 0px 20px',
        fontFamily: 'Segoe UI',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#6A737D',
        height: 56,
        maxHeight:56,
        textArea:{
            width:'100%',
            fontFamily: 'Segoe UI',
            fontStyle: 'normal',
            fontWeight:'400',
            fontSize: '14px',
            color:'#24292F',
        }
    },
    createSpaceDialodBoxTextTemplate:
    {
        display: 'flex',
        position: 'relative',
        margin: '20px 20px 0px 20px',
        fontFamily: 'Segoe UI',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#6A737D',
        textDecoration:'underline',
        cursor:'pointer'
    },
    createSpaceDialogFooterLine:{
        width:564,
        display:'flex',
        position:'relative',
        margin:'auto 0px 0px 0px'
    },
    createSpaceDialogFooterBox:{

        cancelBtn:{
            display:'flex',
            position:'relative',
            margin:'9px 0px 0px 0px',
            height:32,
            width:77,
            fontFamily: 'Segoe UI',
            fontWeight: '600',
            fontStyle: 'normal',
            fontSize: '14px',
        },
        submitBtn:{
            display:'flex',
            position:'relative',
            margin:'9px 0px 0px 8px',
            height:32,
            width:120,
            fontFamily: 'Segoe UI',
            fontWeight: '600',
            fontStyle: 'normal',
            fontSize: '14px',
            background:'#0965CE',
            color:'#FFFFFF'
        }
    },
    deleteWorkSpaceDialog:{
        height:200,
        width:564
    },
    deleteWorkplaceDialogContentBox:{
        display:'flex',
        flexDirection:'column',
        width:564,
        height:92,
       // background:'red'
    },
    deleteworkplaceDialogFooterLine:{
        width:564,
        display:'flex',
        position:'relative',
        margin:'auto 0px 0px 0px'
    },
    deleteworkplaceDialogFooterBox:{
        cancelBtn:{
            display:'flex',
            position:'relative',
            margin:'9px 0px 0px 0px',
            height:32,
            width:77,
            fontFamily: 'Segoe UI',
            fontWeight: '600',
            fontStyle: 'normal',
            fontSize: '14px',
        },
        submitBtn:{
            display:'flex',
            position:'relative',
            margin:'9px 0px 0px 8px',
            height:32,
            width:73,
            fontFamily: 'Segoe UI',
            fontWeight: '600',
            fontStyle: 'normal',
            fontSize: '14px',
            background:'#C11F2A',
            color:'#FFFFFF'
        }
    },
    //----------- sharing Dialog
    shareDialogWorkplaceSpace:{
        height:455,
        width:564
        
    },
    shareDialogContentBox:
    {
        display:'flex',
        flexDirection:'column',
        width:564,
        height:402, 
        overflowY:'auto',
          
    },
    shareDialogEmailBox:{
        display: 'flex',
        flexDirection:'row',
        position: 'relative',
        margin: '30px 20px 0px 20px',
        height: 32,
       
        emailInput:{
            width:395,
            minHeight:32,
            maxHeight:32,
            fontFamily: 'Segoe UI',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '20px',
            color: '#6E7781',
          
        },
        selectBox:{
            display:'flex',
            position:'relative',
            margin:'0px 0px 0px 10px',
            width:115,
            height:32
        },
        selectComponent:{
            width:118,
            fontFamily: 'Segoe UI',
            fontStyle: 'normal',
            fontWeight:'400',
            fontSize: '14px',
            color:'#24292F',
            

        }
        
    }
    ,
    shareDialogText:{
            display:'flex',
            position:'relative',
            margin:'20px 0px 0px 20px',
            fontFamily: 'Segoe UI',
            fontStyle: 'normal',
            fontWeight:'400',
            fontSize: '14px',
            lineHeight:'20px',
            color:'#24292F',
           
   },
   shareDialogTextAreaBox:
   {
    display: 'flex',
    position: 'relative',
    margin: '20px 24px 0px 20px',
    height: 56,
    minHeight: 56,
    maxHeight: 56,
    textAreaInput:{
        width: '100%',
        minHeight: 56,
        maxHeight: 56,
        height: 56,
        fontFamily: 'Segoe UI',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#6E7781',
    }
   },
   shareDialogCollaboratorText:
   {
    display:'flex',
    position:'relative',
    margin:'50px 0px 0px 20px',
    fontFamily: 'Segoe UI',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#586069',
   },
   shareDialogTextInputWithToken:{
       height: 20,
       minHeight: 20,
       maxHeight: 20,
       minWidth: 380,
       maxWidth: 380,
       fontFamily: 'Segoe UI',
       fontWeight: '400',
       fontSize: '14px',
       lineHeight: '20px',
    //   color: '#0969DA',
       color:'#586069'
   },
   sharePeopleSearchActionList:{
    background:'white',
    width:390,
    minWidth:390,
    maxWidth:390,
    height:'fit-content',
    position:'absolute',
    top:35,
    zIndex:10,
    boxShadow:'0px 8px 24px rgba(66, 74, 83, 0.12), 0px 1px 3px rgba(27, 31, 36, 0.12)',
    borderRadius:'12px',
    actionListItem:{
        width:358,
        textField:{
            fontFamily: 'Segoe UI',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '20px',
            color: '#24292F'
        },
        descriptionText:{
            fontFamily: 'Segoe UI',
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '18px',
            color: '#57606A'
        }
    }
   },
   sharePeopleBottomCollabBox:{
    
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    margin: '30px 30px 0px 20px',
    avatarComp:{

        display: 'flex',
        position: 'relative',
        margin: 'auto 0px auto 0px',
        height: 28,
        width: 28,
    },
    nameEmailBox:{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        margin: '0px 0px 0px 20px',
        nameText:{
            display: 'flex',
            position: 'relative',
            margin: '0px 0px 0px 0px',
            fontFamily: 'Segoe UI',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '20px',
            color: '#24292F'
        },
        emailText:{
            display: 'flex',
            position: 'relative',
            margin: '0px 0px 0px 0px',
            fontFamily: 'Segoe UI',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '20px',
            color: '#6A737D'
        }
    },
    collabDateText:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position:'absolute',
        right:'100px',
        top:'0px',
        bottom:'0px',
        margin:'auto',
        // position: 'relative',
        // margin: 'auto 0px auto 131px',
        fontFamily: 'Segoe UI',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '20px',
        color: '#6A737D'
    },
    collabstatusBtn:{
        height: 24,
        width: 91,
        display: 'flex',
        position:'absolute',
        right:'0px',
        top:'0px',
        bottom:'0px',
        margin:'auto',
        // position: 'relative',
        // margin: 'auto 0px auto 22px',
        fontFamily: 'Segoe UI',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '18px',
        color: '#24292F',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'white'
    },
    bottomDivider:{
        width: 564,
        position: 'absolute',
        bottom: 52,
        left: 0,
        right: 0
    }

   },
   //---------- move workplace dialog ------------
   moveWorkplaceDialog:{

      height:306,
      width:564
   },
   moveSpaceDialogContentBox:{

       display:'flex',
       flexDirection:'column',
       width:564,
       height:252, 
       minHeight:252,
       overflowY:'auto',
   },
   moveSpaceDialogFooter:{

    cancelBtn:{
        display:'flex',
        position:'relative',
        margin:'9px 0px 0px 0px',
        height:32,
        width:77,
        fontFamily: 'Segoe UI',
        fontWeight: '600',
        fontStyle: 'normal',
        fontSize: '14px',
    },
    submitBtn:{
        display:'flex',
        position:'relative',
        margin:'9px 0px 0px 8px',
        height:32,
        width:115,
        fontFamily: 'Segoe UI',
        fontWeight: '600',
        fontStyle: 'normal',
        fontSize: '14px',
        background:'#0965CE',
        color:'#FFFFFF'
    }
       
   },
   moveSpaceDialogContentSelectBox:{
       
        display:'flex',
        position:'relative',
        margin:'30px 24px 0px 20px',
        height:32
   },
   //---------- create workplace -------------

    createworkplaceListMainBox:{
    display:'flex',
    flexDirection:'column',
    position:'absolute',
    top:'28px',
    left:50,
    right:80,
    height:'fit-content',
   },

   createWorkplaceOpenByBtn:{

    position: 'absolute',
    height: 30,
    width: 130,
    padding:0, 
    top:62, 
    left:30,
    display:'flex',
    alignItems:'center',
    color: '#586069',
    fontFamily: 'Segoe UI',
    fontSize: '12px',
    fontWeight: '400',
    alignItems:'center',
    dropDownIcon:{
        display:'flex',
        position:'relative',
        margin:'auto 0px auto 0px'
    }
    
   },
   createWorkplaceSpaceList:{
    height:'auto',
    display:'flex',
    flexDirection:'row',
    flexWrap:'wrap',
    position:'relative',
    margin:'58px 30px 0px 30px',
    background:'white',
   },
   workplacePeopleIcon:{

    position:'absolute',
    top:28,
    right:102,
    background:'#ffffff',
    color:'#000000',
    border:'none',
   },
   listCollabActionList:{
   
    actionListItem:{
        width:248,
        textField:{
            fontFamily: 'Segoe UI',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '20px',
            color: '#24292F'
        },
        descriptionText:{
            fontFamily: 'Segoe UI',
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '18px',
            color: '#57606A'
        }
    }
   },
   createWorkplaceNameInput:{
    display:'flex',
    position:'relative',
    margin:'21px 0px 0px 30px',
    width:254,
    height:32,
    fontFamily:'Segoe UI',
    fontStyle:'normal',
    fontWeight:'400',
    fontSize:'17px',
    lineHeight:'20px',
   
   },
   WorkplaceNameInput:{
    display:'flex',
    position:'relative',
    margin:'0px 0px 0px 0px',
    width:254,
    height:32,
    fontFamily:'Segoe UI',
    fontStyle:'normal',
    fontWeight:'400',
    fontSize:'17px',
    lineHeight:'20px',
   
   },
   searchDialogMain:
   {
    height:350,
    width:750,
    background:'white'
    
   },
   hideDialogCloseBtn:{
    position:'absolute',
    background:'white',
    height:40,
    width:40,
    right:1,
    top:1
   },
   searchDialogInput:{

    height:32,
    display:'flex',
    position:'relative',
    margin:'9px 12px 0px 12px',
    alignItems:'center',
    fontFamily:'Segoe UI',
    fontSize:'14px',
   // fontSize:'17px',
    fontStyle:'normal',
    lineHeight:'20px',
    color:'#121212'
   },
   searchDialogSmallText:{
    height:52,
    width:41,
    display:"flex",
    position:'relative',
    margin:'5px 0px 0px 20px',
    fontFamily:'Segoe UI',
    fontSize:'12px',
    fontWeight:'400',
    fontStyle:'normal',
    lineHeight:'52px',
    color:'#586069'
   },
   searchDialogActionList:{
    background:'white',
    width:710,
    minWidth:710,
    maxWidth:710,
    height:'fit-content',
    position:'relative',
    margin:'0px auto 0px auto',
    actionListItem:{
        display:'flex',
        marginTop:'4px',
        marginLeft:0,
        width:678,
        leadingVisual:{
            position:'relative',
            margin:'auto 18px auto 0px',
            height:32,
            width:32,
            minWidth:32,
            minHeight:32
        },
        textField:{
            position: 'relative',
            display: 'flex',
            height: 'fit-content',
            width: 'fit-content',
            margin: 'auto 0px auto 0px',
            fontFamily: 'Segoe UI',
            fontWeight: '600',
            fontSize: '14px',
            lineHeight: '32px',
            color: '#24292F'
        },
        descriptionText:{
            position:'relative',
            margin:'auto 30px auto 8px',
            fontFamily: 'Segoe UI',
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '18px',
            color: '#57606A',
            
        },
        trailingVisual:{
            position:'relative',
            margin:'auto 0px auto 0px',
        }
    }
   },
   filessearchDialogMain:
   {
    height:170,
    width:544,
    background:'white'
    
   }, 
   filesearchDialogInput:{
    height:32,
    display:'flex',
    position:'relative',
    margin:'9px 12px 0px 12px',
    alignItems:'center',
    fontFamily:'Segoe UI',
    fontSize:'14px',
    fontWeight:'400',
    fontStyle:'normal',
    lineHeight:'20px',
    color:'#121212'
   },
   filesearchDialogActionList:{
    background:'white',
    width:544,
    minWidth:544,
    maxWidth:544,
    height:'fit-content',
    position:'relative',
    margin:'0px auto 0px auto',
    actionListItem:{
        
        display:'flex',
        marginTop:'4px',
        marginLeft:'12px',
        marginRight:'12px',
        width:503,
        textField:{
            position: 'relative',
            display: 'flex',
            height: 'fit-content',
            width: 'fit-content',
            margin: 'auto 0px auto 0px',
            fontFamily: 'Segoe UI',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '20px',
            color: '#24292F'
        },
       
    }
   },
  
}