import { Box, Button, IconButton,Text, Dialog, TextInput, Spinner, Label } from "@primer/react"
import { Helmet } from "react-helmet"
import Header from "../../Portal/Header"
import SideNav from "../../Portal/SideNav"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import React from "react"
import customTheme from "../../../Theme.module.scss"
import customThemVariable from "../../../ThemeVariable.module.scss"
import { ArrowLeftIcon} from "@primer/octicons-react"
import { ExternalLinkIcon } from "@radix-ui/react-icons"
import TaxHeroHeader from "../../Portal/TaxHeroHeader"

function GSTTDSReturnExpPlaywright(props) {

    let hostName = window.location.hostname
    let host = window.location.host

    const navigate = useNavigate()
    const [expansionDetails,setexpansionDetails] = React.useState(JSON.parse(localStorage.getItem("tdsreturndetails")))
 
    
    React.useState(() => {

        

        (function (c, l, a, r, i, t, y) {
            c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
            t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
            y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
        })(window, document, "clarity", "script", "ibtrxlrmkf");

    })



    return (
        <Box
            position={"relative"}
            display="flex"
            background={"#F6F8FA"}
            height="100vh"
            width={"100vw"}
            overflowY={"auto"}

        >
            <Helmet>
                <title>
                    {hostName === "www.app.taxherohq.com"
                        ||
                        hostName === "www.get.taxherohq.com"
                        ? 'GST TDS Return expansion - taxhero' : 'GST TDS Return - deski'}
                </title>
            </Helmet>

            {
                hostName === "www.app.taxherohq.com"
                    ||
                    hostName === "www.get.taxherohq.com"
                    ?

                    <>

                        <TaxHeroHeader />
                    </>
                    :
                    <>
                        <Header />
                        <SideNav />
                    </>
            }

            <Box
                sx={{
                    position: 'absolute',
                    top: '48px',
                    bottom: '0px',
                    left: '64px',
                    right: '0px',
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'scroll',
                    overflowX: 'hidden',

                }}>
                {/* Header section */}

               
                <Box sx={{
                    position: 'absolute',
                    left: '26px',
                    top: '37px',
                    right: '61px',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '60px'
                }}>
                    <Text
                        className={customTheme.H5}
                        sx={{ color: customThemVariable.secondaryTextColor }}
                    >
                        
                    </Text>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '13px',
                        marginTop: '4px',
                        minHeight: '36px',
                        maxHeight: '36px',
                        height: '36px',
                        alignItems: 'center'
                    }}>
                        <Text
                            className={customTheme.H3}
                            sx={{
                                color: customThemVariable.primaryTextColor
                            }}
                        >
                            {expansionDetails?.pan_name}
                        </Text>
                        <Text
                            className={customTheme.H4}
                            sx={{ color: customThemVariable.secondaryTextColor }}
                        >
                            {expansionDetails?.user_name} 
                        </Text>

 

                    </Box>


                    <Button
                        leadingIcon={() => <Box>
                            <ArrowLeftIcon />
                        </Box>
                        }
                        variant="invisible"
                        sx={{
                            fontFamily: customThemVariable.primartFont,
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '20px',
                            position: 'absolute',
                            right: '64px',
                            bottom: '0px',
                            minWidth: '95px',
                            maxWidth: '95px',
                            width: '95px',
                            height: '32px',
                            minHeight: '32px',
                            maxHeight: '32px'

                        }}
                        onClick={() => {

                               navigate('/tdsreturn')

                        }}
                    >
                        Return


                    </Button>



                </Box>

                {/*  horizontal line */}

                <Box sx={{
                    position: 'absolute',
                    left: '26px',
                    right: '61px',
                    top: '109px',
                    height: '1px',
                    background: customThemVariable.borderColor
                }}>

                </Box>

                {/* -------------------- */}


                {/* GST Cash ledger Details Section 1 */}
                <Box sx={{
                    position: 'absolute',
                    left: '26px',
                    top: '120px',
                    right: '61px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '24px',
                }}>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '144px',
                        maxWidth: '144px',
                        width: '144px'
                    }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                            }}
                        >
                            ARN
                        </Text>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.primaryTextColor,
                                minHeight: '20px'
                            }}
                        >
                             {expansionDetails?.summary?.arn}
                        </Text>
                    </Box>

                    <Box sx={{
                        height: '32px',
                        minHeight: '32px',
                        maxHeight: '32px',
                        width: '1px',
                        minWidth: '1px',
                        maxWidth: '1px',
                        background: customThemVariable.borderColor
                    }}
                    ></Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '144px',
                        maxWidth: '144px',
                        width: '144px'
                    }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                            }}
                        >
                            Return Period
                        </Text>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.primaryTextColor,
                                minHeight: '20px'
                            }}
                        >

                            {expansionDetails?.returnPeriod}
                        </Text>
                    </Box>
                    <Box sx={{
                        height: '32px',
                        minHeight: '32px',
                        maxHeight: '32px',
                        width: '1px',
                        minWidth: '1px',
                        maxWidth: '1px',
                        background: customThemVariable.borderColor
                    }}
                    ></Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '158px',
                        maxWidth: '158px',
                        width: '158px'
                    }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                            }}
                        >
                            Return Type
                        </Text>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.primaryTextColor,
                                minHeight: '20px',
                                width:'100%',
                                overflow:'hidden',
                                whiteSpace:'nowrap',
                                textOverflow:'ellipsis'
                            }}
                        >
                              {expansionDetails?.return_ty}
                        </Text>
                    </Box>
                    <Box sx={{
                        height: '32px',
                        minHeight: '32px',
                        maxHeight: '32px',
                        width: '1px',
                        minWidth: '1px',
                        maxWidth: '1px',
                        background: customThemVariable.borderColor
                    }}
                    ></Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '144px',
                        maxWidth: '144px',
                        width: '144px'
                    }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                            }}
                        >
                            Name
                        </Text>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.primaryTextColor,
                                minHeight: '20px'
                            }}
                        >
                            {expansionDetails?.summary?.name}

                        </Text>
                    </Box>
                    <Box sx={{
                        height: '32px',
                        minHeight: '32px',
                        maxHeight: '32px',
                        width: '1px',
                        minWidth: '1px',
                        maxWidth: '1px',
                        background: customThemVariable.borderColor
                    }}
                    ></Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '144px',
                        maxWidth: '144px',
                        width: '144px'
                    }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                                minHeight: '20px'
                            }}
                        >
                            Arn Date
                        </Text>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.primaryTextColor,
                                minHeight: '20px'
                            }}
                        >
                            {expansionDetails?.summary?.arn_dt} 
                        </Text>
                    </Box>
                    <Box sx={{
                        height: '32px',
                        minHeight: '32px',
                        maxHeight: '32px',
                        width: '1px',
                        minWidth: '1px',
                        maxWidth: '1px',
                        background: customThemVariable.borderColor
                    }}
                    ></Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '144px',
                        maxWidth: '144px',
                        width: '144px'
                    }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                            }}
                        >
                            Due Date
                        </Text>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.primaryTextColor,
                                minHeight: '20px'
                            }}
                        > 
                             {expansionDetails?.due_dt}
                        </Text>
                    </Box>




                </Box>
                {/* ---------------------- */}
                

                {/* GST Cash ledger Section 2 */}

                <Box sx={{
                    position: 'absolute',
                    left: '26px',
                    top: '182px',
                    right: '61px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '24px',
                }}>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '174px',
                        maxWidth: '174px',
                        width: '174px',
                    }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                            }}
                        >
                            GSTIN
                        </Text>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row'
                            }}
                        >
                            <Text
                                className={customTheme.textnormal}
                                sx={{
                                    color: customThemVariable.primaryTextColor,
                                    minHeight: '20px'
                                }}
                            >
                               
                               {expansionDetails?.summary?.gstin} 
                            </Text>
                            

                        </Box>


                    </Box>






                </Box>

                {/* ----------------------- */}

                {/* Info section ---- */}

                <Box sx={{
                    position: 'absolute',
                    left: '26px',
                    top: '276px',
                    right: '61px',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px',

                }}>

                    <Box sx={{

                        display: 'flex',
                        flexDirection: 'column',

                    }}>
                        <Text
                            className={customTheme.H3}
                            sx={{ color: customThemVariable.primaryTextColor }}
                        >
                            TDS
                        </Text>

                        <Box sx={{
                            marginTop: '7px',
                            width: '594px',
                            minWidth: '594px',
                            maxWidth: '594px',
                            height: '1px',
                            background: customThemVariable.borderColor
                        }}>
                        </Box>


                        {/* ----------------- -------------- */}
                        <Box sx={{
                            marginTop: '10px',
                            display: 'flex',
                            flexDirection: 'row',
                            minHeight: '36px',
                            borderBottomWidth: 1,
                            borderBottomStyle: 'solid',
                            borderColor: '#E0E0E0',
                            alignItems: 'center',
                            background: '#FFFFFF',
                            minWidth: 'fit-content',
                            maxWidth: 'fit-content',
                            width: 'fit-content',
                        }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    fontSize: '12px',
                                    lineHeight: '18px',
                                    color: '#414552',
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>

                                </Text>
                            </Box>

                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    fontSize: '12px',
                                    lineHeight: '16px',
                                    color: '#414552',
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    Original
                                </Text>
                            </Box>

                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    fontSize: '12px',
                                    lineHeight: '16px',
                                    color: '#414552',
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>

                                </Text>
                            </Box>


                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    fontSize: '14px',
                                    lineHeight: '18px',
                                    color: '#414552',
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '89px',
                                    maxWidth: '89px',
                                    width: '89px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    Ammmended
                                </Text>
                            </Box>

                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    fontSize: '14px',
                                    lineHeight: '18px',
                                    color: '#414552',
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>

                                </Text>
                            </Box>


                        </Box>

                        <Box sx={{
                            marginTop: '0px',
                            display: 'flex',
                            flexDirection: 'row',
                            minHeight: '36px',
                            borderBottomWidth: 1,
                            borderBottomStyle: 'solid',
                            borderColor: '#E0E0E0',
                            alignItems: 'center',
                            background: '#FFFFFF',
                            minWidth: 'fit-content',
                            maxWidth: 'fit-content',
                            width: 'fit-content',
                        }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    fontSize: '12px',
                                    lineHeight: '18px',
                                    color: '#414552',
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>

                                </Text>
                            </Box>

                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    fontSize: '12px',
                                    lineHeight: '16px',
                                    color: '#414552',
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    Accepted
                                </Text>
                            </Box>

                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    fontSize: '12px',
                                    lineHeight: '16px',
                                    color: '#414552',
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    Rejected
                                </Text>
                            </Box>


                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    fontSize: '14px',
                                    lineHeight: '18px',
                                    color: '#414552',
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    Accepted
                                </Text>
                            </Box>

                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    fontSize: '14px',
                                    lineHeight: '18px',
                                    color: '#414552',
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    Rejected
                                </Text>
                            </Box>


                        </Box>



                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            minHeight: '36px',
                            borderBottomWidth: 1,
                            borderBottomStyle: 'solid',
                            borderColor: '#E0E0E0',
                            alignItems: 'center',
                            background: '#FFFFFF',
                            minWidth: 'fit-content',
                            maxWidth: 'fit-content',
                            width: 'fit-content',
                        }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '12px',
                                    lineHeight: '18px',
                                    color: customThemVariable.primaryTextColor,
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    IGST
                                </Text>
                            </Box>

                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '12px',
                                    lineHeight: '16px',
                                    color: customThemVariable.primaryTextColor,
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    {expansionDetails?.summary?.tds?.accepted?.tot_iamt}
                                </Text>
                            </Box>

                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '12px',
                                    lineHeight: '16px',
                                    color: customThemVariable.primaryTextColor,
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    {expansionDetails?.summary?.tds?.rejected?.tot_iamt}
                                </Text>
                            </Box>


                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '18px',
                                    color: customThemVariable.primaryTextColor,
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    {expansionDetails?.summary?.tdsa?.accepted?.tot_iamt}
                                </Text>
                            </Box>

                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '18px',
                                    color: customThemVariable.primaryTextColor,
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    {expansionDetails?.summary?.tdsa?.rejected?.tot_iamt}
                                </Text>
                            </Box>

                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            minHeight: '36px',
                            borderBottomWidth: 1,
                            borderBottomStyle: 'solid',
                            borderColor: '#E0E0E0',
                            alignItems: 'center',
                            background: '#FFFFFF',
                            minWidth: 'fit-content',
                            maxWidth: 'fit-content',
                            width: 'fit-content',
                        }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '12px',
                                    lineHeight: '18px',
                                    color: customThemVariable.primaryTextColor,
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    CGST
                                </Text>
                            </Box>

                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '12px',
                                    lineHeight: '16px',
                                    color: customThemVariable.primaryTextColor,
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    {expansionDetails?.summary?.tds?.accepted?.tot_camt}
                                </Text>
                            </Box>

                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '12px',
                                    lineHeight: '16px',
                                    color: customThemVariable.primaryTextColor,
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    {expansionDetails?.summary?.tds?.rejected?.tot_camt}
                                </Text>
                            </Box>


                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '18px',
                                    color: customThemVariable.primaryTextColor,
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    {expansionDetails?.summary?.tdsa?.accepted?.tot_camt}
                                </Text>
                            </Box>

                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '18px',
                                    color: customThemVariable.primaryTextColor,
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    {expansionDetails?.summary?.tdsa?.rejected?.tot_camt}
                                </Text>
                            </Box>

                        </Box>

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            minHeight: '36px',
                            borderBottomWidth: 1,
                            borderBottomStyle: 'solid',
                            borderColor: '#E0E0E0',
                            alignItems: 'center',
                            background: '#FFFFFF',
                            minWidth: 'fit-content',
                            maxWidth: 'fit-content',
                            width: 'fit-content',
                        }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '12px',
                                    lineHeight: '18px',
                                    color: customThemVariable.primaryTextColor,
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    SGST
                                </Text>
                            </Box>

                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '12px',
                                    lineHeight: '16px',
                                    color: customThemVariable.primaryTextColor,
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    {expansionDetails?.summary?.tds?.accepted?.tot_samt}
                                </Text>
                            </Box>

                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '12px',
                                    lineHeight: '16px',
                                    color: customThemVariable.primaryTextColor,
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    {expansionDetails?.summary?.tds?.rejected?.tot_samt}
                                </Text>
                            </Box>


                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '18px',
                                    color: customThemVariable.primaryTextColor,
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    {expansionDetails?.summary?.tdsa?.accepted?.tot_samt}
                                </Text>
                            </Box>

                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '18px',
                                    color: customThemVariable.primaryTextColor,
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    {expansionDetails?.summary?.tdsa?.rejected?.tot_samt}
                                </Text>
                            </Box>

                        </Box>

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            minHeight: '36px',
                            borderBottomWidth: 1,
                            borderBottomStyle: 'solid',
                            borderColor: '#E0E0E0',
                            alignItems: 'center',
                            background: '#FFFFFF',
                            minWidth: 'fit-content',
                            maxWidth: 'fit-content',
                            width: 'fit-content',
                        }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '12px',
                                    lineHeight: '18px',
                                    color: customThemVariable.primaryTextColor,
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    Count
                                </Text>
                            </Box>

                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '12px',
                                    lineHeight: '16px',
                                    color: customThemVariable.primaryTextColor,
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    {expansionDetails?.summary?.tds?.accepted?.tot_count}
                                </Text>
                            </Box>

                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '12px',
                                    lineHeight: '16px',
                                    color: customThemVariable.primaryTextColor,
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    {expansionDetails?.summary?.tds?.rejected?.tot_count}
                                </Text>
                            </Box>


                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '18px',
                                    color: customThemVariable.primaryTextColor,
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    {expansionDetails?.summary?.tdsa?.accepted?.tot_count}
                                </Text>
                            </Box>

                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '18px',
                                    color: customThemVariable.primaryTextColor,
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    {expansionDetails?.summary?.tdsa?.rejected?.tot_count}
                                </Text>
                            </Box>

                        </Box>

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            minHeight: '36px',
                            borderBottomWidth: 1,
                            borderBottomStyle: 'solid',
                            borderColor: '#E0E0E0',
                            alignItems: 'center',
                            background: '#FFFFFF',
                            minWidth: 'fit-content',
                            maxWidth: 'fit-content',
                            width: 'fit-content',
                        }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '12px',
                                    lineHeight: '18px',
                                    color: customThemVariable.primaryTextColor,
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '79px',
                                    maxWidth: '79px',
                                    width: '79px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    Taxable Value
                                </Text>
                            </Box>

                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '12px',
                                    lineHeight: '16px',
                                    color: customThemVariable.primaryTextColor,
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    {expansionDetails?.summary?.tds?.accepted?.tot_amt}
                                </Text>
                            </Box>

                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '12px',
                                    lineHeight: '16px',
                                    color: customThemVariable.primaryTextColor,
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    {expansionDetails?.summary?.tds?.rejected?.tot_amt}
                                </Text>
                            </Box>


                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '18px',
                                    color: customThemVariable.primaryTextColor,
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    {expansionDetails?.summary?.tdsa?.accepted?.tot_amt}
                                </Text>
                            </Box>

                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '18px',
                                    color: customThemVariable.primaryTextColor,
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    {expansionDetails?.summary?.tdsa?.rejected?.tot_amt}
                                </Text>
                            </Box>

                        </Box>

                        <Box sx={{ minHeight: '100px', minWidth: '20px' }}></Box>

                    </Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        
                    }}>
                        <Text
                            className={customTheme.H3}
                            sx={{ color: customThemVariable.primaryTextColor }}
                        >
                            TCS
                        </Text>

                        <Box sx={{
                            marginTop: '7px',
                            width: '594px',
                            minWidth: '594px',
                            maxWidth: '594px',
                            height: '1px',
                            background: customThemVariable.borderColor
                        }}>
                        </Box>


                        {/* ----------------- -------------- */}
                        <Box sx={{
                            marginTop: '10px',
                            display: 'flex',
                            flexDirection: 'row',
                            minHeight: '36px',
                            borderBottomWidth: 1,
                            borderBottomStyle: 'solid',
                            borderColor: '#E0E0E0',
                            alignItems: 'center',
                            background: '#FFFFFF',
                            minWidth: 'fit-content',
                            maxWidth: 'fit-content',
                            width: 'fit-content',
                        }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    fontSize: '12px',
                                    lineHeight: '18px',
                                    color: '#414552',
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>

                                </Text>
                            </Box>

                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    fontSize: '12px',
                                    lineHeight: '16px',
                                    color: '#414552',
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    Original
                                </Text>
                            </Box>

                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    fontSize: '12px',
                                    lineHeight: '16px',
                                    color: '#414552',
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>

                                </Text>
                            </Box>


                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    fontSize: '14px',
                                    lineHeight: '18px',
                                    color: '#414552',
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '89px',
                                    maxWidth: '89px',
                                    width: '89px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    Ammmended
                                </Text>
                            </Box>

                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    fontSize: '14px',
                                    lineHeight: '18px',
                                    color: '#414552',
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>

                                </Text>
                            </Box>


                        </Box>

                        <Box sx={{
                            marginTop: '0px',
                            display: 'flex',
                            flexDirection: 'row',
                            minHeight: '36px',
                            borderBottomWidth: 1,
                            borderBottomStyle: 'solid',
                            borderColor: '#E0E0E0',
                            alignItems: 'center',
                            background: '#FFFFFF',
                            minWidth: 'fit-content',
                            maxWidth: 'fit-content',
                            width: 'fit-content',
                        }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    fontSize: '12px',
                                    lineHeight: '18px',
                                    color: '#414552',
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>

                                </Text>
                            </Box>

                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    fontSize: '12px',
                                    lineHeight: '16px',
                                    color: '#414552',
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    Accepted
                                </Text>
                            </Box>

                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    fontSize: '12px',
                                    lineHeight: '16px',
                                    color: '#414552',
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    Rejected
                                </Text>
                            </Box>


                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    fontSize: '14px',
                                    lineHeight: '18px',
                                    color: '#414552',
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    Accepted
                                </Text>
                            </Box>

                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    fontSize: '14px',
                                    lineHeight: '18px',
                                    color: '#414552',
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    Rejected
                                </Text>
                            </Box>


                        </Box>



                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            minHeight: '36px',
                            borderBottomWidth: 1,
                            borderBottomStyle: 'solid',
                            borderColor: '#E0E0E0',
                            alignItems: 'center',
                            background: '#FFFFFF',
                            minWidth: 'fit-content',
                            maxWidth: 'fit-content',
                            width: 'fit-content',
                        }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '12px',
                                    lineHeight: '18px',
                                    color: customThemVariable.primaryTextColor,
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    IGST
                                </Text>
                            </Box>

                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '12px',
                                    lineHeight: '16px',
                                    color: customThemVariable.primaryTextColor,
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    {expansionDetails?.summary?.tcs?.accepted?.tot_iamt}
                                </Text>
                            </Box>

                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '12px',
                                    lineHeight: '16px',
                                    color: customThemVariable.primaryTextColor,
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    {expansionDetails?.summary?.tcs?.rejected?.tot_iamt}
                                </Text>
                            </Box>


                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '18px',
                                    color: customThemVariable.primaryTextColor,
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    {expansionDetails?.summary?.tcsa?.accepted?.tot_iamt}
                                </Text>
                            </Box>

                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '18px',
                                    color: customThemVariable.primaryTextColor,
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    {expansionDetails?.summary?.tcsa?.rejected?.tot_iamt}
                                </Text>
                            </Box>

                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            minHeight: '36px',
                            borderBottomWidth: 1,
                            borderBottomStyle: 'solid',
                            borderColor: '#E0E0E0',
                            alignItems: 'center',
                            background: '#FFFFFF',
                            minWidth: 'fit-content',
                            maxWidth: 'fit-content',
                            width: 'fit-content',
                        }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '12px',
                                    lineHeight: '18px',
                                    color: customThemVariable.primaryTextColor,
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    CGST
                                </Text>
                            </Box>

                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '12px',
                                    lineHeight: '16px',
                                    color: customThemVariable.primaryTextColor,
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    {expansionDetails?.summary?.tcs?.accepted?.tot_camt}
                                </Text>
                            </Box>

                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '12px',
                                    lineHeight: '16px',
                                    color: customThemVariable.primaryTextColor,
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    {expansionDetails?.summary?.tcs?.rejected?.tot_camt}
                                </Text>
                            </Box>


                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '18px',
                                    color: customThemVariable.primaryTextColor,
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    {expansionDetails?.summary?.tcsa?.accepted?.tot_camt}
                                </Text>
                            </Box>

                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '18px',
                                    color: customThemVariable.primaryTextColor,
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    {expansionDetails?.summary?.tcsa?.rejected?.tot_camt}
                                </Text>
                            </Box>

                        </Box>

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            minHeight: '36px',
                            borderBottomWidth: 1,
                            borderBottomStyle: 'solid',
                            borderColor: '#E0E0E0',
                            alignItems: 'center',
                            background: '#FFFFFF',
                            minWidth: 'fit-content',
                            maxWidth: 'fit-content',
                            width: 'fit-content',
                        }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '12px',
                                    lineHeight: '18px',
                                    color: customThemVariable.primaryTextColor,
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    SGST
                                </Text>
                            </Box>

                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '12px',
                                    lineHeight: '16px',
                                    color: customThemVariable.primaryTextColor,
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    {expansionDetails?.summary?.tcs?.accepted?.tot_samt}
                                </Text>
                            </Box>

                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '12px',
                                    lineHeight: '16px',
                                    color: customThemVariable.primaryTextColor,
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    {expansionDetails?.summary?.tcs?.rejected?.tot_samt}
                                </Text>
                            </Box>


                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '18px',
                                    color: customThemVariable.primaryTextColor,
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    {expansionDetails?.summary?.tcsa?.accepted?.tot_samt}
                                </Text>
                            </Box>

                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '18px',
                                    color: customThemVariable.primaryTextColor,
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    {expansionDetails?.summary?.tcsa?.rejected?.tot_samt}
                                </Text>
                            </Box>

                        </Box>

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            minHeight: '36px',
                            borderBottomWidth: 1,
                            borderBottomStyle: 'solid',
                            borderColor: '#E0E0E0',
                            alignItems: 'center',
                            background: '#FFFFFF',
                            minWidth: 'fit-content',
                            maxWidth: 'fit-content',
                            width: 'fit-content',
                        }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '12px',
                                    lineHeight: '18px',
                                    color: customThemVariable.primaryTextColor,
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    Count
                                </Text>
                            </Box>

                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '12px',
                                    lineHeight: '16px',
                                    color: customThemVariable.primaryTextColor,
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    {expansionDetails?.summary?.tcs?.accepted?.tot_count}
                                </Text>
                            </Box>

                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '12px',
                                    lineHeight: '16px',
                                    color: customThemVariable.primaryTextColor,
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    {expansionDetails?.summary?.tcs?.rejected?.tot_count}
                                </Text>
                            </Box>


                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '18px',
                                    color: customThemVariable.primaryTextColor,
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    {expansionDetails?.summary?.tcsa?.accepted?.tot_count}
                                </Text>
                            </Box>

                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '18px',
                                    color: customThemVariable.primaryTextColor,
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    {expansionDetails?.summary?.tcsa?.rejected?.tot_count}
                                </Text>
                            </Box>

                        </Box>

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            minHeight: '36px',
                            borderBottomWidth: 1,
                            borderBottomStyle: 'solid',
                            borderColor: '#E0E0E0',
                            alignItems: 'center',
                            background: '#FFFFFF',
                            minWidth: 'fit-content',
                            maxWidth: 'fit-content',
                            width: 'fit-content',
                        }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '12px',
                                    lineHeight: '18px',
                                    color: customThemVariable.primaryTextColor,
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '79px',
                                    maxWidth: '79px',
                                    width: '79px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    Taxable Value
                                </Text>
                            </Box>

                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '12px',
                                    lineHeight: '16px',
                                    color: customThemVariable.primaryTextColor,
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    {expansionDetails?.summary?.tcs?.accepted?.tot_amt}
                                </Text>
                            </Box>

                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '12px',
                                    lineHeight: '16px',
                                    color: customThemVariable.primaryTextColor,
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    {expansionDetails?.summary?.tcs?.rejected?.tot_amt}
                                </Text>
                            </Box>


                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '18px',
                                    color: customThemVariable.primaryTextColor,
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    {expansionDetails?.summary?.tcsa?.accepted?.tot_amt}
                                </Text>
                            </Box>

                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '119px',
                                maxWidth: '119px',
                                width: '119px',

                            }}>
                                <Text sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '18px',
                                    color: customThemVariable.primaryTextColor,
                                    position: 'relative',
                                    margin: '0px 12px 0px 18px',
                                    minWidth: '69px',
                                    maxWidth: '69px',
                                    width: '69px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    {expansionDetails?.summary?.tcsa?.rejected?.tot_amt}
                                </Text>
                            </Box>

                        </Box>

                        <Box sx={{ minHeight: '100px', minWidth: '20px' }}></Box>

                    </Box>


                </Box>

               


                {/* --------------------- */}
 
            </Box>



        </Box>


    )


}


export default GSTTDSReturnExpPlaywright