export default function itrTypeArray() {
    return (
        [
            { key: "1", value: "ITR-1" },
            { key: "2", value: "ITR-2" },
            { key: "3", value: "ITR-3" },
            { key: "4", value: "ITR-4" },
            { key: "5", value: "ITR-5" },
            { key: "6", value: "ITR-6" },
            { key: "7", value: "ITR-7" },
            { key: "8", value: "ITR-8" },
            { key: "2A", value: "ITR-2A" },
            { key: "4S", value: "ITR-4S" },
            { key: "6A", value: "ITR-A & ITR-6" },
            { key: "FO-037-WTRET", value: "WTR-Form BB" }
        ]

    )
}