import { Box, Button, Text } from "@primer/react"
import { Helmet } from "react-helmet"
import Header from "../../Portal/Header"
import SideNav from "../../Portal/SideNav"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import React from "react"
import customTheme from "../../../Theme.module.scss"
import customThemVariable from "../../../ThemeVariable.module.scss"
import { ArrowLeftIcon } from "@primer/octicons-react"
import { DownloadIcon, ExternalLinkIcon } from "@radix-ui/react-icons"
import { getSingleGstportal } from "../../redux/features/portalSlice"
import { Toaster, Intent, Position } from "@blueprintjs/core";
import Papa from "papaparse";
import TaxHeroHeader from "../../Portal/TaxHeroHeader"

function Gst2BExpansionPlaywright(props) {

    let hostName = window.location.hostname
    let host = window.location.host

    let dispatch = useDispatch()
    const navigate = useNavigate()
    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail) || localStorage.getItem("userEmail")

    const clientId = localStorage.getItem("client_id") || ""
    const insertId = localStorage.getItem("insert_id") || ""
    const itrId = localStorage.getItem("itr_id") || ""
    const pageRoute = localStorage.getItem("pageRoute") || ""

    const passwordRef = React.useRef(null)
    const [allDetails, setallDetails] = React.useState([])
    const [gstSingleData, setgstSingleData] = React.useState(JSON.parse(localStorage.getItem("gstr2bdata")))

    const getGstProfiles = (props) => {

        dispatch(getSingleGstportal({
            userId: userId,
            userEmail: userEmail,
            insertId: insertId
        })).then((data) => {

            console.log("getSingleGSTportal", data.payload)
            setallDetails(data.payload.data)


        })
    }

    const downloadDataAsExcel = (props) => {


          const excelArrayData = gstSingleData?.data?.docdata?.b2b?.flatMap((row) =>
            row?.inv?.flatMap((row2) =>
                row2.items?.map((row3) => ({
                    "GSTIN of supplier": row?.ctin || " ",
                    "Trade/Legal name of the supplier": row?.trdnm || " ",
                    "Invoice number": row2?.inum || " ",
                    "Invoice type": row2?.typ === "R" ? "Regular" : row2?.typ || " ",
                    "Invoice Date": row2?.dt || " ",
                    "Invoice Value(₹)": row2?.val || " ",
                    "Place of supply": row2?.pos == "32" ? "Kerala" : row2?.pos || " ",
                    "Supply Attract Reverse Charge": row2?.rev || " ",
                    "Rate(%)": row3?.rt || " ",
                    "Taxable Value (₹)": row3?.txval || " ",
                    "Integrated Tax(₹)": row3?.igst || " ",
                    "Central Tax(₹)": row3?.cgst || " ",
                    "State/UT Tax(₹)": row3?.sgst || " ",
                    "Cess(₹)": row3?.cess || " ",
                    "GSTR-1/IFF/GSTR-5 Period": row?.supprd || " ",
                    "GSTR-1/IFF/GSTR-5 Filing Date": row?.supfildt || " ",
                    "ITC Availability": row2?.itcavl || " ",
                    "Reason": row2?.rsn || " ",
                    "Applicable % of Tax Rate": "100%",
                    "Source": row2?.srctyp || " ",
                    "IRN": row2?.irn || " ",
                    "IRN Date": row2?.irngendate || " ",
                }))
            )
        );
        console.log("excelArrayData", excelArrayData)
        const csv = Papa.unparse(excelArrayData);
        const newCsvData = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
        const csvData = newCsvData;
        const link = document.createElement("a");
        link.setAttribute("href", csvData);
        link.setAttribute("download", 'b2b.csv');
        link.click();
    }


    React.useState(() => {
        
        getGstProfiles("")
        setgstSingleData(JSON.parse(localStorage.getItem("gstr2bdata")))

    })

    React.useState(() => {

        (function (c, l, a, r, i, t, y) {
            c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
            t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
            y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
        })(window, document, "clarity", "script", "ibtrxlrmkf");

    })



    return (
        <Box
            position={"relative"}
            display="flex"
            background={"#F6F8FA"}
            height="100vh"
            width={"100vw"}
            overflowY={"auto"}

        >
            <Helmet>
                <title>
                    {hostName === "www.app.taxherohq.com" || hostName === "www.get.taxherohq.com" ? 'GST expansion - taxhero' : 'GST expansion - deski'}
                </title>
            </Helmet>
            {


                <TaxHeroHeader />



            }


            <Box
                sx={{
                    position: 'absolute',
                    top: '48px',
                    bottom: '0px',
                    left: '64px',
                    right: '0px',
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'scroll',
                    overflowX: 'hidden',

                }}>
                {/* Header section */}
                <Box sx={{
                    position: 'absolute',
                    left: '26px',
                    top: '37px',
                    right: '61px',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '60px'
                }}>
                    <Text
                        className={customTheme.H5}
                        sx={{ color: customThemVariable.secondaryTextColor }}
                    >
                        {allDetails[0]?.pan_name}
                    </Text>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '13px',
                        marginTop: '4px',
                        minHeight: '36px',
                        maxHeight: '36px',
                        height: '36px',
                        alignItems: 'center'
                    }}>
                        <Text
                            className={customTheme.H3}
                            sx={{
                                color: customThemVariable.primaryTextColor
                            }}
                        >
                            {allDetails[0]?.pan_name}
                        </Text>
                        <Text
                            className={customTheme.H4}
                            sx={{ color: customThemVariable.secondaryTextColor }}
                        >
                            {allDetails[0]?.user_name}
                        </Text>






                    </Box>

                    <Button
                        leadingIcon={() => <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <DownloadIcon />
                        </Box>
                        }

                        sx={{
                            fontFamily: customThemVariable.primartFont,
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '20px',
                            position: 'absolute',
                            right: '198px',
                            bottom: '0px',
                            padding: '6px 12px 6px 12px',
                            height: '32px',
                            minHeight: '32px',
                            maxHeight: '32px',
                            border: 'none',
                            display: 'flex', alignItems: 'center', justifyContent: 'center'
                        }}
                        onClick={() => {
                            downloadDataAsExcel("")
                        }}
                    >
                        Download Excel


                    </Button>


                    <Button
                        leadingIcon={() => <Box>
                            <ArrowLeftIcon />
                        </Box>
                        }
                        variant="invisible"
                        sx={{
                            fontFamily: customThemVariable.primartFont,
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '20px',
                            position: 'absolute',
                            right: '64px',
                            bottom: '0px',
                            minWidth: '95px',
                            maxWidth: '95px',
                            width: '95px',
                            height: '32px',
                            minHeight: '32px',
                            maxHeight: '32px'

                        }}
                        onClick={() => {
                            navigate('/return')
                        }}
                    >
                        Return


                    </Button>



                </Box>

                {/*  horizontal line */}

                <Box sx={{
                    position: 'absolute',
                    left: '26px',
                    right: '61px',
                    top: '109px',
                    height: '1px',
                    background: customThemVariable.borderColor,

                }}></Box>

                <Box sx={{
                    position: 'absolute',
                    left: '26px',
                    right: '0px',
                    top: '129px',
                    bottom: '0px',
                    // height: '80vh',
                    display: 'flex',
                    flexDirection: 'column',
                    overflowX: 'auto',

                }}>

                    <Box sx={{
                        marginTop: '0px',
                        display: 'flex',
                        flexDirection: 'row',
                        minHeight: '46px',
                        borderBottomWidth: 1,
                        borderBottomStyle: 'solid',
                        borderColor: '#E0E0E0',
                        alignItems: 'center',
                        background: '#FFFFFF',
                        minWidth: 'fit-content',
                        maxWidth: 'fit-content',
                        width: 'fit-content',

                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: '159px',
                            maxWidth: '159px',
                            width: '159px',

                        }}>
                            <Text sx={{
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '12px',
                                lineHeight: '18px',
                                color: '#414552',
                                position: 'relative',
                                margin: '0px 12px 0px 18px',
                                minWidth: '100%',
                                maxWidth: '100%',
                                width: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                GSTIN of supplier
                            </Text>
                        </Box>

                        <Box sx={{

                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: '230px',
                            maxWidth: '230px',
                            width: '230px',
                        }}>
                            <Text sx={{
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '12px',
                                lineHeight: '16px',
                                color: '#414552',
                                position: 'relative',
                                margin: '0px 12px 0px 18px',
                                minWidth: '100%',
                                maxWidth: '100%',
                                width: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                Trade <br></br>Legal name of the supplier
                            </Text>
                        </Box>

                        <Box sx={{

                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: '129px',
                            maxWidth: '129px',
                            width: '129px',
                        }}>
                            <Text sx={{
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '12px',
                                lineHeight: '16px',
                                color: '#414552',
                                position: 'relative',
                                margin: '0px 12px 0px 18px',
                                minWidth: '100%',
                                maxWidth: '100%',
                                width: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                Invoice number
                            </Text>
                        </Box>


                        <Box sx={{

                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: '119px',
                            maxWidth: '119px',
                            width: '119px',

                        }}>
                            <Text sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '18px',
                                color: '#414552',
                                position: 'relative',
                                margin: '0px 12px 0px 18px',
                                minWidth: '100%',
                                maxWidth: '100%',
                                width: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                Invoice type
                            </Text>
                        </Box>

                        <Box sx={{

                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: '119px',
                            maxWidth: '119px',
                            width: '119px',

                        }}>
                            <Text sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '18px',
                                color: '#414552',
                                position: 'relative',
                                margin: '0px 12px 0px 18px',
                                minWidth: '100%',
                                maxWidth: '100%',
                                width: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                Invoice Date
                            </Text>
                        </Box>

                        <Box sx={{

                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: '119px',
                            maxWidth: '119px',
                            width: '119px',

                        }}>
                            <Text sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '18px',
                                color: '#414552',
                                position: 'relative',
                                margin: '0px 12px 0px 18px',
                                minWidth: '100%',
                                maxWidth: '100%',
                                width: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                Invoice Value(₹)
                            </Text>
                        </Box>
                        <Box sx={{

                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: '119px',
                            maxWidth: '119px',
                            width: '119px',

                        }}>
                            <Text sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '18px',
                                color: '#414552',
                                position: 'relative',
                                margin: '0px 12px 0px 18px',
                                minWidth: '100%',
                                maxWidth: '100%',
                                width: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                Place of supply
                            </Text>
                        </Box>
                        <Box sx={{

                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: '119px',
                            maxWidth: '119px',
                            width: '119px',

                        }}>
                            <Text sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '18px',
                                color: '#414552',
                                position: 'relative',
                                margin: '0px 12px 0px 18px',
                                minWidth: '100%',
                                maxWidth: '100%',
                                width: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                Supply Attract <br></br> Reverse Charge
                            </Text>
                        </Box>
                        <Box sx={{

                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: '119px',
                            maxWidth: '119px',
                            width: '119px',

                        }}>
                            <Text sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '18px',
                                color: '#414552',
                                position: 'relative',
                                margin: '0px 12px 0px 18px',
                                minWidth: '100%',
                                maxWidth: '100%',
                                width: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                Rate(%)
                            </Text>
                        </Box>
                        <Box sx={{

                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: '119px',
                            maxWidth: '119px',
                            width: '119px',

                        }}>
                            <Text sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '18px',
                                color: '#414552',
                                position: 'relative',
                                margin: '0px 12px 0px 18px',
                                minWidth: '100%',
                                maxWidth: '100%',
                                width: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                Taxable Value (₹)
                            </Text>
                        </Box>
                        <Box sx={{

                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: '119px',
                            maxWidth: '119px',
                            width: '119px',

                        }}>
                            <Text sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '18px',
                                color: '#414552',
                                position: 'relative',
                                margin: '0px 12px 0px 18px',
                                minWidth: '100%',
                                maxWidth: '100%',
                                width: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                Integrated Tax(₹)
                            </Text>
                        </Box>
                        <Box sx={{

                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: '119px',
                            maxWidth: '119px',
                            width: '119px',

                        }}>
                            <Text sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '18px',
                                color: '#414552',
                                position: 'relative',
                                margin: '0px 12px 0px 18px',
                                minWidth: '100%',
                                maxWidth: '100%',
                                width: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                Central Tax(₹)
                            </Text>
                        </Box>
                        <Box sx={{

                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: '119px',
                            maxWidth: '119px',
                            width: '119px',

                        }}>
                            <Text sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '18px',
                                color: '#414552',
                                position: 'relative',
                                margin: '0px 12px 0px 18px',
                                minWidth: '100%',
                                maxWidth: '100%',
                                width: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                State/UT Tax(₹)
                            </Text>
                        </Box>
                        <Box sx={{

                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: '119px',
                            maxWidth: '119px',
                            width: '119px',

                        }}>
                            <Text sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '18px',
                                color: '#414552',
                                position: 'relative',
                                margin: '0px 12px 0px 18px',
                                minWidth: '100%',
                                maxWidth: '100%',
                                width: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                Cess(₹)
                            </Text>
                        </Box>
                        <Box sx={{

                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: '189px',
                            maxWidth: '189px',
                            width: '189px',

                        }}>
                            <Text sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '18px',
                                color: '#414552',
                                position: 'relative',
                                margin: '0px 12px 0px 18px',
                                minWidth: '100%',
                                maxWidth: '100%',
                                width: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                GSTR-1/IFF/GSTR-5 Period
                            </Text>
                        </Box>
                        <Box sx={{

                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: '210px',
                            maxWidth: '210px',
                            width: '210px',

                        }}>
                            <Text sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '18px',
                                color: '#414552',
                                position: 'relative',
                                margin: '0px 12px 0px 18px',
                                minWidth: '100%',
                                maxWidth: '100%',
                                width: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                GSTR-1/IFF/GSTR-5 Filing Date
                            </Text>
                        </Box>
                        <Box sx={{

                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: '119px',
                            maxWidth: '119px',
                            width: '119px',

                        }}>
                            <Text sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '18px',
                                color: '#414552',
                                position: 'relative',
                                margin: '0px 12px 0px 18px',
                                minWidth: '100%',
                                maxWidth: '100%',
                                width: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                ITC Availability
                            </Text>
                        </Box>
                        <Box sx={{

                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: '119px',
                            maxWidth: '119px',
                            width: '119px',

                        }}>
                            <Text sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '18px',
                                color: '#414552',
                                position: 'relative',
                                margin: '0px 12px 0px 18px',
                                minWidth: '100%',
                                maxWidth: '100%',
                                width: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                Reason
                            </Text>
                        </Box>
                        <Box sx={{

                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: '119px',
                            maxWidth: '119px',
                            width: '119px',

                        }}>
                            <Text sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '18px',
                                color: '#414552',
                                position: 'relative',
                                margin: '0px 12px 0px 18px',
                                minWidth: '100%',
                                maxWidth: '100%',
                                width: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                Applicable % of Tax Rate
                            </Text>
                        </Box>
                        <Box sx={{

                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: '119px',
                            maxWidth: '119px',
                            width: '119px',

                        }}>
                            <Text sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '18px',
                                color: '#414552',
                                position: 'relative',
                                margin: '0px 12px 0px 18px',
                                minWidth: '100%',
                                maxWidth: '100%',
                                width: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                Source
                            </Text>
                        </Box>
                        <Box sx={{

                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: '459px',
                            maxWidth: '459px',
                            width: '459px',

                        }}>
                            <Text sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '18px',
                                color: '#414552',
                                position: 'relative',
                                margin: '0px 12px 0px 18px',
                                minWidth: '100%',
                                maxWidth: '100%',
                                width: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                IRN
                            </Text>
                        </Box>
                        <Box sx={{

                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: '119px',
                            maxWidth: '119px',
                            width: '119px',

                        }}>
                            <Text sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '18px',
                                color: '#414552',
                                position: 'relative',
                                margin: '0px 12px 0px 18px',
                                minWidth: '100%',
                                maxWidth: '100%',
                                width: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                IRN Date
                            </Text>
                        </Box>
                    </Box>


                    {gstSingleData?.data?.docdata?.b2b?.map((row, index) => {



                        return (<>

                            {row?.inv?.map((row2, index2) => {
                                return (<>

                                    {row2.items?.map((row3, index3) => {
                                        return (<>
                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                minHeight: '46px',
                                                maxHeight: 'auto',
                                                borderBottomWidth: 1,
                                                borderBottomStyle: 'solid',
                                                borderColor: '#E0E0E0',
                                                alignItems: 'center',
                                                background: '#FFFFFF',
                                                minWidth: 'fit-content',
                                                maxWidth: 'fit-content',
                                                width: 'fit-content',
                                            }}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    minWidth: '159px',
                                                    maxWidth: '159px',
                                                    width: '159px',

                                                }}>
                                                    <Text sx={{
                                                        fontFamily: 'Inter',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        fontSize: '12px',
                                                        lineHeight: '18px',
                                                        color: customThemVariable.primaryTextColor,
                                                        position: 'relative',
                                                        margin: '0px 12px 0px 18px',
                                                        minWidth: '100%',
                                                        maxWidth: '100%',
                                                        width: '100%',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis'
                                                    }}>
                                                        {row?.ctin || " "}

                                                    </Text>
                                                </Box>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    minWidth: '240px',
                                                    maxWidth: '240px',
                                                    width: '240px',

                                                }}>
                                                    <Text sx={{
                                                        fontFamily: 'Inter',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        fontSize: '12px',
                                                        lineHeight: '18px',
                                                        color: customThemVariable.primaryTextColor,
                                                        position: 'relative',
                                                        margin: '0px 12px 0px 18px',
                                                        minWidth: '100%',
                                                        maxWidth: '100%',
                                                        width: '100%',
                                                        wordWrap: 'break-word'

                                                    }}>
                                                        {row?.trdnm || " "}

                                                    </Text>
                                                </Box>

                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    minWidth: '129px',
                                                    maxWidth: '129px',
                                                    width: '129px',
                                                }}>
                                                    <Text sx={{
                                                        fontFamily: 'Inter',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        fontSize: '12px',
                                                        lineHeight: '18px',
                                                        color: customThemVariable.primaryTextColor,
                                                        position: 'relative',
                                                        margin: '0px 12px 0px 10px',
                                                        minWidth: '100%',
                                                        maxWidth: '100%',
                                                        width: '100%',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis'
                                                    }}>
                                                        {row2?.inum || " "}
                                                    </Text>
                                                </Box>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    minWidth: '119px',
                                                    maxWidth: '119px',
                                                    width: '119px',
                                                }}>
                                                    <Text sx={{
                                                        fontFamily: 'Inter',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        fontSize: '12px',
                                                        lineHeight: '18px',
                                                        color: customThemVariable.primaryTextColor,
                                                        position: 'relative',
                                                        margin: '0px 12px 0px 10px',
                                                        minWidth: '100%',
                                                        maxWidth: '100%',
                                                        width: '100%',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis'
                                                    }}>
                                                        {row2?.typ === "R" ? "Regular" : row2?.typ || " "}
                                                    </Text>
                                                </Box>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    minWidth: '119px',
                                                    maxWidth: '119px',
                                                    width: '119px',
                                                }}>
                                                    <Text sx={{
                                                        fontFamily: 'Inter',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        fontSize: '12px',
                                                        lineHeight: '18px',
                                                        color: customThemVariable.primaryTextColor,
                                                        position: 'relative',
                                                        margin: '0px 12px 0px 10px',
                                                        minWidth: '100%',
                                                        maxWidth: '100%',
                                                        width: '100%',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis'
                                                    }}>
                                                        {row2?.dt || " "}
                                                    </Text>
                                                </Box>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    minWidth: '119px',
                                                    maxWidth: '119px',
                                                    width: '119px',
                                                }}>
                                                    <Text sx={{
                                                        fontFamily: 'Inter',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        fontSize: '12px',
                                                        lineHeight: '18px',
                                                        color: customThemVariable.primaryTextColor,
                                                        position: 'relative',
                                                        margin: '0px 12px 0px 10px',
                                                        minWidth: '100%',
                                                        maxWidth: '100%',
                                                        width: '100%',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis'
                                                    }}>
                                                        {row2?.val || " "}
                                                    </Text>
                                                </Box>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    minWidth: '119px',
                                                    maxWidth: '119px',
                                                    width: '119px',
                                                }}>
                                                    <Text sx={{
                                                        fontFamily: 'Inter',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        fontSize: '12px',
                                                        lineHeight: '18px',
                                                        color: customThemVariable.primaryTextColor,
                                                        position: 'relative',
                                                        margin: '0px 12px 0px 10px',
                                                        minWidth: '100%',
                                                        maxWidth: '100%',
                                                        width: '100%',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis'
                                                    }}>
                                                        {row2?.pos == "32" ? "Kerala" : row2?.pos || " "}
                                                    </Text>
                                                </Box>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    minWidth: '119px',
                                                    maxWidth: '119px',
                                                    width: '119px',
                                                }}>
                                                    <Text sx={{
                                                        fontFamily: 'Inter',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        fontSize: '12px',
                                                        lineHeight: '18px',
                                                        color: customThemVariable.primaryTextColor,
                                                        position: 'relative',
                                                        margin: '0px 12px 0px 10px',
                                                        minWidth: '100%',
                                                        maxWidth: '100%',
                                                        width: '100%',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis'
                                                    }}>
                                                        {row2?.rev || " "}
                                                    </Text>
                                                </Box>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    minWidth: '119px',
                                                    maxWidth: '119px',
                                                    width: '119px',
                                                }}>
                                                    <Text sx={{
                                                        fontFamily: 'Inter',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        fontSize: '12px',
                                                        lineHeight: '18px',
                                                        color: customThemVariable.primaryTextColor,
                                                        position: 'relative',
                                                        margin: '0px 12px 0px 10px',
                                                        minWidth: '100%',
                                                        maxWidth: '100%',
                                                        width: '100%',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis'
                                                    }}>
                                                        {row3?.rt || " "}
                                                    </Text>
                                                </Box>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    minWidth: '119px',
                                                    maxWidth: '119px',
                                                    width: '119px',
                                                }}>
                                                    <Text sx={{
                                                        fontFamily: 'Inter',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        fontSize: '12px',
                                                        lineHeight: '18px',
                                                        color: customThemVariable.primaryTextColor,
                                                        position: 'relative',
                                                        margin: '0px 12px 0px 10px',
                                                        minWidth: '100%',
                                                        maxWidth: '100%',
                                                        width: '100%',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis'
                                                    }}>
                                                        {row3?.txval || " "}
                                                    </Text>
                                                </Box>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    minWidth: '119px',
                                                    maxWidth: '119px',
                                                    width: '119px',
                                                }}>
                                                    <Text sx={{
                                                        fontFamily: 'Inter',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        fontSize: '12px',
                                                        lineHeight: '18px',
                                                        color: customThemVariable.primaryTextColor,
                                                        position: 'relative',
                                                        margin: '0px 12px 0px 10px',
                                                        minWidth: '100%',
                                                        maxWidth: '100%',
                                                        width: '100%',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis'
                                                    }}>
                                                        {row3?.igst || " "}
                                                    </Text>
                                                </Box>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    minWidth: '119px',
                                                    maxWidth: '119px',
                                                    width: '119px',
                                                }}>
                                                    <Text sx={{
                                                        fontFamily: 'Inter',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        fontSize: '12px',
                                                        lineHeight: '18px',
                                                        color: customThemVariable.primaryTextColor,
                                                        position: 'relative',
                                                        margin: '0px 12px 0px 10px',
                                                        minWidth: '100%',
                                                        maxWidth: '100%',
                                                        width: '100%',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis'
                                                    }}>
                                                        {row3?.cgst || " "}
                                                    </Text>
                                                </Box>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    minWidth: '119px',
                                                    maxWidth: '119px',
                                                    width: '119px',
                                                }}>
                                                    <Text sx={{
                                                        fontFamily: 'Inter',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        fontSize: '12px',
                                                        lineHeight: '18px',
                                                        color: customThemVariable.primaryTextColor,
                                                        position: 'relative',
                                                        margin: '0px 12px 0px 10px',
                                                        minWidth: '100%',
                                                        maxWidth: '100%',
                                                        width: '100%',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis'
                                                    }}>
                                                        {row3?.sgst || " "}
                                                    </Text>
                                                </Box>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    minWidth: '119px',
                                                    maxWidth: '119px',
                                                    width: '119px',
                                                }}>
                                                    <Text sx={{
                                                        fontFamily: 'Inter',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        fontSize: '12px',
                                                        lineHeight: '18px',
                                                        color: customThemVariable.primaryTextColor,
                                                        position: 'relative',
                                                        margin: '0px 12px 0px 10px',
                                                        minWidth: '100%',
                                                        maxWidth: '100%',
                                                        width: '100%',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis'
                                                    }}>
                                                        {row3?.cess || " "}
                                                    </Text>
                                                </Box>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    minWidth: '189px',
                                                    maxWidth: '189px',
                                                    width: '189px',
                                                }}>
                                                    <Text sx={{
                                                        fontFamily: 'Inter',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        fontSize: '12px',
                                                        lineHeight: '18px',
                                                        color: customThemVariable.primaryTextColor,
                                                        position: 'relative',
                                                        margin: '0px 12px 0px 10px',
                                                        minWidth: '100%',
                                                        maxWidth: '100%',
                                                        width: '100%',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis'
                                                    }}>
                                                        {row?.supprd || " "}
                                                    </Text>
                                                </Box>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    minWidth: '210px',
                                                    maxWidth: '210px',
                                                    width: '210px',
                                                }}>
                                                    <Text sx={{
                                                        fontFamily: 'Inter',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        fontSize: '12px',
                                                        lineHeight: '18px',
                                                        color: customThemVariable.primaryTextColor,
                                                        position: 'relative',
                                                        margin: '0px 12px 0px 10px',
                                                        minWidth: '100%',
                                                        maxWidth: '100%',
                                                        width: '100%',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis'
                                                    }}>
                                                        {row?.supfildt || " "}
                                                    </Text>
                                                </Box>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    minWidth: '119px',
                                                    maxWidth: '119px',
                                                    width: '119px',
                                                }}>
                                                    <Text sx={{
                                                        fontFamily: 'Inter',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        fontSize: '12px',
                                                        lineHeight: '18px',
                                                        color: customThemVariable.primaryTextColor,
                                                        position: 'relative',
                                                        margin: '0px 12px 0px 10px',
                                                        minWidth: '100%',
                                                        maxWidth: '100%',
                                                        width: '100%',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis'
                                                    }}>
                                                        {row2?.itcavl || " "}
                                                    </Text>
                                                </Box>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    minWidth: '119px',
                                                    maxWidth: '119px',
                                                    width: '119px',
                                                }}>
                                                    <Text sx={{
                                                        fontFamily: 'Inter',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        fontSize: '12px',
                                                        lineHeight: '18px',
                                                        color: customThemVariable.primaryTextColor,
                                                        position: 'relative',
                                                        margin: '0px 12px 0px 10px',
                                                        minWidth: '100%',
                                                        maxWidth: '100%',
                                                        width: '100%',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis'
                                                    }}>
                                                        {row2?.rsn || " "}
                                                    </Text>
                                                </Box>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    minWidth: '119px',
                                                    maxWidth: '119px',
                                                    width: '119px',
                                                }}>
                                                    <Text sx={{
                                                        fontFamily: 'Inter',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        fontSize: '12px',
                                                        lineHeight: '18px',
                                                        color: customThemVariable.primaryTextColor,
                                                        position: 'relative',
                                                        margin: '0px 12px 0px 10px',
                                                        minWidth: '100%',
                                                        maxWidth: '100%',
                                                        width: '100%',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis'
                                                    }}>
                                                        {/* {row2?.diffprcnt || " "} */}
                                                        100%
                                                    </Text>
                                                </Box>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    minWidth: '119px',
                                                    maxWidth: '119px',
                                                    width: '119px',
                                                }}>
                                                    <Text sx={{
                                                        fontFamily: 'Inter',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        fontSize: '12px',
                                                        lineHeight: '18px',
                                                        color: customThemVariable.primaryTextColor,
                                                        position: 'relative',
                                                        margin: '0px 12px 0px 10px',
                                                        minWidth: '100%',
                                                        maxWidth: '100%',
                                                        width: '100%',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis'
                                                    }}>
                                                        {row2?.srctyp || " "}
                                                    </Text>
                                                </Box>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    minWidth: '459px',
                                                    maxWidth: '459px',
                                                    width: '459px',
                                                }}>
                                                    <Text sx={{
                                                        fontFamily: 'Inter',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        fontSize: '12px',
                                                        lineHeight: '18px',
                                                        color: customThemVariable.primaryTextColor,
                                                        position: 'relative',
                                                        margin: '0px 12px 0px 10px',
                                                        minWidth: '100%',
                                                        maxWidth: '100%',
                                                        width: '100%',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis'
                                                    }}>
                                                        {row2?.irn || " "}
                                                    </Text>
                                                </Box>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    minWidth: '119px',
                                                    maxWidth: '119px',
                                                    width: '119px',
                                                }}>
                                                    <Text sx={{
                                                        fontFamily: 'Inter',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        fontSize: '12px',
                                                        lineHeight: '18px',
                                                        color: customThemVariable.primaryTextColor,
                                                        position: 'relative',
                                                        margin: '0px 12px 0px 10px',
                                                        minWidth: '100%',
                                                        maxWidth: '100%',
                                                        width: '100%',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis'
                                                    }}>
                                                        {row2?.irngendate || " "}
                                                    </Text>
                                                </Box>
                                            </Box>
                                        </>)
                                    })}



                                </>)
                            })}



                        </>)
                    })}

                </Box>





            </Box>



        </Box>


    )


}


export default Gst2BExpansionPlaywright