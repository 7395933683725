export default function filingTypeArray() {
    return (
        [
            { key: "O", label: "labels.original", display: "Original" },
            { key: "R", label: "labels.revised", display: "Revised" },
            { key: "T", label: "labels.RECTIFICATION_STATUS12", display: "Rectification" },
            { key: "D", label: "labels.defective", display: "Defective" },
            { key: "M", label: "labels.modified_Return", display: "Modified Return" },
            { key: "U", label: "labels.updated_return", display: "Updated Return" },
            { key: "C", label: "labels.condonation", display: "u/s 119(2)(b) - After condonation of delay / Court Order or Sanction Order of Business reorganisation of the Competent authority issued prior to 01.04.2022" },
            { key: "CN", label: "labels.condonationNew", display: "u/s 139(9A) - After condonation of delay / Court Order or Sanction Order of Business reorganisation of the Competent authority issued prior to 01.04.2022" },
            { key: "N1421", label: "labels.return142", display: "Return filed in response to notice u/s 142(1)" },
            { key: "N148", label: "labels.return148", display: "Return filed in response to notice u/s 148" },
            { key: "N153A", label: "labels.return153A", display: "Return filed in response to notice u/s 153A" },
            { key: "N153C", label: "labels.return153C", display: "Return filed in response to notice u/s 153C" },
            { key: "N153AC", label: "labels.return153AC", display: "Return filed in response to notice u/s 153A/153C" }
        ]


    )
}