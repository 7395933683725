import { Box, Button, Text, IconButton, Dialog, TextInput, Link, Spinner, ActionMenu, ActionList } from "@primer/react"
import { Helmet } from "react-helmet"
import Header from "../../Portal/Header"
import SideNav from "../../Portal/SideNav"
import { useDispatch, useSelector } from "react-redux"
import UploadPanDialog from "../../Workplace/Dialogs/UploadPanDialog" //"./Dialogs/UploadPanDialog"
import GSTSyncDialog from "../../Workplace/Dialogs/GSTSyncDialog"
import React from "react"
import { useNavigate } from "react-router-dom"
import { KebabHorizontalIcon, ChevronRightIcon } from "@primer/octicons-react";
import { Toaster, Intent, Position, ProgressBar } from "@blueprintjs/core";
import customTheme from "../../../Theme.module.scss"
import customThemVariable from "../../../ThemeVariable.module.scss"
import { UploadIcon } from "@radix-ui/react-icons";
import Papa from "papaparse";
import Workplacetheme from "../../Workplace/Workplacetheme" //"./Workplacetheme"
import { changeGstPasswordOpenClose, deleteGstPortal, getGstPortal, getProductTourTaxhero, getTaxheroGstList, postGstPortal, postGstPortalBulk, updateProductTourTaxhero } from "../../redux/features/portalSlice"
//import TaxHeroHeader from "./TaxHeroHeader"
import TaxHeroHeader from "../../Portal/TaxHeroHeader"
import GSTSearchDialogs from "../../Workplace/Dialogs/GSTSearchDialogs"
import { getTaxheroUserSubscription, getUserSubscriptionData } from "../../redux/features/profileSlice"
import axios from "axios"
import ChangePasswordGstPanDialog from "./ChangePasswordDialog"
const synchingToast = Toaster.create({ position: Position.TOP })

function formatTimeTo12Hour(timestamp) {
    // Create a new Date object from the timestamp
    const date = new Date(timestamp);

    // Use toLocaleTimeString to format the time in 12-hour clock with AM/PM
    const time = date.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true
    });

    return time;
}

function GstListViewPlaywright(props) {

    let hostName = window.location.hostname
    let host = window.location.host
    const driver = window.driver.js.driver;

    let dispatch = useDispatch() //for redux call
    const navigate = useNavigate() // for routing path
    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail) || localStorage.getItem("userEmail")
    const serverTrigger = useSelector((state) => state.portalData.serverTrigger)

    const computerFileRef = React.useRef()
    const initialFocusRef = React.createRef()
    const [currentRef, setcurrentRef] = React.useState("panName")
    const [showLoadingBtn, setshowLoadingBtn] = React.useState(false)
    const [panOpenClose, setpanOpenClose] = React.useState(false)
    const [panNameInput, setpanNameInput] = React.useState({ value: '', validationStatus: '' })
    const [userNameInput, setuserNameInput] = React.useState({ value: '', validationStatus: '' })
    const [userPassword, setuserPassword] = React.useState({ value: '', validationStatus: '' })
    const [panBulkOpenClose, setpanBulkOpenClose] = React.useState(false)
    const [isAscending, setisAscending] = React.useState(false)
    const [gstList, setgstList] = React.useState([])
    const [bulkPanData, setbulkPanData] = React.useState([])
    const [addonsCount, setaddonsCount] = React.useState(0)
    const [planMetaDataQty, setplanMetaDataQty] = React.useState(0)

    //Post Hog Events-----------------------------------
    const postHogTrackingGSTAddPanClick = async (userId, userEmail) => {

        if (userId !== null && userEmail !== null) {

            const postData = {
                api_key: `${process.env.REACT_APP_POSTHOG_APIKEY_TAXHERO}`,
                event: 'GSTAddPANClick',
                properties: {
                    distinct_id: `${userId}`,
                    user_id: `${userId}`,
                    user_email: `${userEmail}`,
                },
                timestamp: new Date().toISOString(),
            };
            const apiUrl = `${process.env.REACT_APP_POSTHOG_APIURL}` //'https://app.posthog.com/capture/';
            const result = await axios.post(apiUrl, postData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            return result;
        }


    }

    //-------- product tour section -----------
    const updateProductTourInDb = (props) => {


        const locationPath = [...props, { pathname: window.location.pathname, tour: true }]
        dispatch(updateProductTourTaxhero({
            userId: userId,
            userEmail: userEmail,
            locationPath: locationPath
        })).then((data) => {

            console.log("updateProductTourTaxhero", data.payload)
            const newLocationPath = data.payload.data
            console.log("newLocationPath", newLocationPath)
        })

    }

    const showProductTour = (props) => {

        const driverObj = driver({
            popoverClass: 'driverjs-theme',
            showProgress: true,
            allowClose: false,
            steps: [
                { element: '#gstaddPanBtn', popover: { title: 'ADD PAN', description: `Add your client's PAN details for automated data fetching from GSTIN portal and stay up to date with e-tracking.`, side: "top", align: 'start' } },
                { element: '#gstpanListing', popover: { title: 'Clent Listing', description: `List all your clients here, stay updated, and expand the list to get all the details about their returns.`, side: "top", align: 'start' } },

            ],
            onDestroyStarted: () => {

                console.log("driverObj", driverObj)
                if (driverObj.getActiveIndex() == 1) {

                    updateProductTourInDb(props)
                    if (!driverObj.hasNextStep() || driverObj.confirm("Are you sure?")) {
                        driverObj.destroy();

                    }
                }


            },
        });

        driverObj.drive();

    }

    const getUserProductTour = (props) => {


        dispatch(getProductTourTaxhero({
            userId: userId,
            userEmail: userEmail
        })).then((data) => {

            console.log("getProductTourTaxhero", data.payload.data)
            const tourPaths = [...data.payload.data]
            const checkProductTour = tourPaths?.find((pData) => pData.pathname === window.location.pathname)?.tour
            console.log("checkProductTour", checkProductTour)
            if (!checkProductTour) {
                // showProductTour(tourPaths)
            }
        })

    }

    //-----------------------------------------

    const showgstPanDailog = (props) => {
        setpanOpenClose(true)
        postHogTrackingGSTAddPanClick(userId, userEmail)
    }

    const closeAddPanDialog = (props) => {


        setpanOpenClose(false)
        setshowLoadingBtn(false)
        setpanNameInput((prevState) => {
            return ({ ...prevState, validationStatus: '', value: '' })
        })
        setuserNameInput((prevState) => {
            return ({ ...prevState, validationStatus: '', value: '' })
        })
        setuserPassword((prevState) => {
            return ({ ...prevState, validationStatus: '', value: '' })
        })



    }

    const showBulkDialog = (props) => {
        setpanBulkOpenClose(true)
        showgstPanDailog("")
    }

    const closeBulkDialog = (props) => {
        setpanBulkOpenClose(false)
        setshowLoadingBtn(false)
    }

    const handlePickComputerFile = (props) => {

        Papa.parse(props, {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {

                console.log("results", results.data)
                setbulkPanData(results.data)

            },
        })

    }

    const downloadBulkExcel = (props) => {

        var csvContent = 'Name,PAN,Password'
        var blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'panbulk.csv';
        link.click();
        window.URL.revokeObjectURL(link.href);

    }

    const loadingToast = async (progressPercentage, key) => {

        synchingToast.show({
            icon: "cloud-upload",
            message: <Box sx={{ marginTop: '4px' }}>
                <ProgressBar
                    animate={true}
                    stripes={true}
                    intent={progressPercentage < 100 ? Intent.PRIMARY : Intent.SUCCESS}
                    value={progressPercentage / 100}

                />
            </Box>

            ,
            onDismiss: (didTimeoutExpire) => {
                if (!didTimeoutExpire) {

                    synchingToast.dismiss()

                }
            },
            timeout: progressPercentage < 100 ? 0 : 2000,


        }, key);

    }

    const handleUploadBulkExcelPan = (props) => {


        const addonCountChargebee = addonsCount
        const addedItrCount = gstList.length
        const excelCount = props.length

        if (props.length > 0) {

            // if (addedItrCount == addonCountChargebee) {

            //     closeAddPanDialog("")
            //     closeBulkDialog(false)
            //     Toaster.create({ position: Position.TOP }).show({
            //         message: <Box sx={{
            //             display: 'flex',
            //             flexDirection: 'row',
            //             alignItems: 'center',
            //         }}>
            //             Beta trial limit exceeds the {addonCountChargebee} Pan limit.
            //             <Text
            //                 onClick={() => {

            //                 }}
            //                 sx={{
            //                     marginLeft: '8px',
            //                     cursor: 'pointer',
            //                     color: 'white',
            //                     textDecoration: 'underline'
            //                 }}>
            //                 Upgrade
            //             </Text>
            //         </Box>,
            //         intent: Intent.DANGER
            //     });


            // } else {

            closeAddPanDialog("")
            closeBulkDialog(false)
            loadingToast(10, "1")
            setshowLoadingBtn(true)
            dispatch(postGstPortalBulk({
                userId: userId,
                userEmail: userEmail,
                panName: '',
                panNumber: '',
                panPassword: '',
                bulkPanData: props
            })).then((data) => {

                console.log("postGstPortalBulk", data.payload)
                console.log("postGstPortalBulk", data.payload.data.length)
                console.log("postGstPortalBulk", props.length)

                const insertedArray = [...data.payload.data]
                const duplicatePans = [...data.payload.duplicatePan]

                setbulkPanData([])
                getGstPortalFromDb("")

                if (data.payload.statusCode == 200 && data.payload.data.length > 0) {

                    loadingToast(100, "1")


                    // if (excelCount > insertedArray.length) {


                    //     Toaster.create({ position: Position.TOP }).show({
                    //         message: `Free trial allows up to ${addonCountChargebee} syncs`,
                    //         intent: Intent.PRIMARY
                    //     });
                    // }

                    if (duplicatePans.length > 0) {

                        Toaster.create({ position: Position.TOP }).show({
                            message: duplicatePans.length + "duplicates were found and automatically removed",
                            intent: Intent.PRIMARY
                        });

                    }


                }

                if (duplicatePans.length > 0 && data.payload.data.length == 0) {


                    synchingToast.clear()
                    Toaster.create({ position: Position.TOP }).show({
                        message: duplicatePans.length + "duplicates were found and automatically removed",
                        intent: Intent.PRIMARY
                    });

                }


            })



            // }




        } else {

            closeBulkDialog(false)
            setshowLoadingBtn(false)
            Toaster.create({ position: Position.TOP }).show({
                message: "No data found in uploaded file",
                intent: Intent.PRIMARY
            });

        }


    }

    const handleChangePassword = (props, panName, username, password) => {

        dispatch(changeGstPasswordOpenClose({
            openCloseGstChangePassword: true,
            insertId: props,
            gstName: panName,
            gstUsername: username,
            gstPassword: password
        }))

    }

    const handleDeleteGstPan = (props) => {

        dispatch(deleteGstPortal({
            userId: userId,
            userEmail: userEmail,
            insertId: props,
        })).then((data) => {

            const response = data?.payload?.data
            Toaster.create({ position: Position.TOP }).show({
                message: response,
                intent: Intent.SUCCESS
            });

            getGstPortalFromDb('')

        })

    }


    const AddGstPanDialog = (props) => {

        return (
            <Dialog
                sx={{ height: '315px', width: '564px' }}
                isOpen={panOpenClose}
                onDismiss={() => closeAddPanDialog("")}
                aria-labelledby="header-id"
                initialFocusRef={initialFocusRef}
            >
                <Dialog.Header id="header-id"><Text sx={Workplacetheme.scheduleDalogeHeading}>Add new PAN</Text></Dialog.Header>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: 564,
                    height: 'fit-content',
                }}>

                    <Text sx={{
                        display: 'flex',
                        position: 'relative',
                        margin: '20px 20px 0px 20px',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: '#6A737D',
                    }}>Please enter data to fetch information.</Text>

                    <Box sx={{
                        position: 'relative',
                        margin: '23px 20px 0px 20px',
                        fontFamily: 'Segoe UI'
                    }}>

                        <TextInput
                            sx={{
                                minHeight: '40px',
                                fontFamily: 'Segoe UI',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: '20px'
                            }}
                            ref={currentRef === "panName" ? initialFocusRef : null}
                            onFocus={() => setcurrentRef("panName")}
                            onBlur={() => setcurrentRef("")}
                            width={'100%'}
                            aria-label="panName"
                            name="panName"
                            placeholder="Enter name"
                            value={panNameInput.value}
                            validationStatus={panNameInput.validationStatus}
                            onChange={(e) => {
                                setpanNameInput((prevState) => {
                                    const newState = { ...prevState, value: e.target.value, validationStatus: '' }
                                    return newState
                                })
                            }}
                        />



                    </Box>



                    <Box sx={{
                        position: 'relative',
                        margin: '20px 20px 0px 20px',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '15px'
                    }}>
                        <TextInput
                            sx={{
                                width: '255px',
                                height: '40px',
                                minHeight: '40px',
                                maxHeight: '40px',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',

                            }}
                            ref={currentRef === "userName" ? initialFocusRef : null}
                            onFocus={() => setcurrentRef("userName")}
                            onBlur={() => setcurrentRef("")}
                            placeholder={"Username"}
                            value={userNameInput.value}
                            validationStatus={userNameInput.validationStatus}
                            onChange={(e) => {
                                setuserNameInput((prevState) => {
                                    return ({ ...prevState, value: e.target.value, validationStatus: '' })
                                })
                            }}

                        />
                        <TextInput

                            sx={{
                                width: '255px',
                                height: '40px',
                                minHeight: '40px',
                                maxHeight: '40px',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',

                            }}
                            type="password"
                            ref={currentRef === "userPassword" ? initialFocusRef : null}
                            onFocus={() => setcurrentRef("userPassword")}
                            onBlur={() => setcurrentRef("")}
                            placeholder={"Enter password "}
                            value={userPassword.value}
                            validationStatus={userPassword.validationStatus}
                            onChange={(e) => {
                                setuserPassword((prevState) => {
                                    return ({ ...prevState, value: e.target.value, validationStatus: '' })
                                })
                            }}

                        />




                    </Box>

                    <Link
                        sx={{
                            position: 'relative',
                            margin: '13px 20px 10px 20px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',
                            color: '#24292F',
                            cursor: 'pointer'
                        }}
                        underline={true}
                        //  href=""
                        onClick={() => {
                            showBulkDialog("")
                        }}
                    >Upload bulk PAN</Link>

                    <Box

                        sx={Workplacetheme.createSpaceDialogFooterLine}

                        borderColor="border.default" borderBottomWidth={1} borderBottomStyle="solid">


                    </Box>





                </Box>

                <Box
                    ml={24}
                    mr={24}
                    mb={10}
                    display="flex"
                    flexDirection="row"
                    justifyContent={"right"}
                >

                    <Button variant="invisible"
                        sx={Workplacetheme.createSpaceDialogFooterBox.cancelBtn}
                        onClick={() => closeAddPanDialog("")}
                    >Cancel</Button>

                    {
                        showLoadingBtn === true ?

                            <Button
                                style={{ width: '58px' }}
                                variant="outline"
                                sx={Workplacetheme.createSpaceDialogFooterBox.submitBtn}
                            >
                                <Spinner sx={{
                                    position: 'absolute',
                                    left: '0px',
                                    right: '0px',
                                    margin: 'auto',
                                    top: '0px',
                                    bottom: '0px',
                                }} size="small" />
                            </Button>

                            :

                            <Button
                                style={{ width: '58px' }}
                                variant="outline"

                                sx={Workplacetheme.createSpaceDialogFooterBox.submitBtn}
                                onClick={() => {
                                    addGstPanToDb("")
                                }
                                }
                            >
                                Add

                            </Button>


                    }






                </Box>





            </Dialog>
        )
    }


    const BulkPanDialog = (props) => {

        return (
            <Dialog
                sx={{
                    height: '315px',
                    width: '564px',

                }}
                isOpen={panBulkOpenClose}
                onDismiss={() =>

                    closeBulkDialog("")
                }
                aria-labelledby="header-id"
                initialFocusRef={initialFocusRef}
            >
                <Dialog.Header id="header-id">
                    <Text sx={Workplacetheme.scheduleDalogeHeading}>Upload PANs in bulk using an Excel sheet.</Text>
                </Dialog.Header>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: 564,
                    height: 'fit-content',
                }}>
                    <Box sx={{
                        position: 'relative',
                        margin: '25px auto 0px auto',
                        height: '100px',
                        width: '100px',
                        minHeight: '100px',
                        minWidth: '100px',
                        background: '#FAFBFC'
                    }}>

                        <Box sx={{
                            width: '100px',
                            minHeight: '100px',
                            minWidth: '100px',
                            borderRadius: '50%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            border: '1px solid rgba(27, 31, 36, 0.15)',
                            cursor: 'pointer'
                        }}
                            onClick={() => { computerFileRef.current.click() }}
                        >
                            <UploadIcon scale={32} style={{ display: 'flex', height: '32px', width: '32px' }} />
                            <input ref={computerFileRef} type={"file"} accept=".csv, text/csv" onChange={(e) => handlePickComputerFile(e.target.files[0])} hidden />
                        </Box>

                    </Box>

                    <Text
                        sx={{
                            position: 'relative',
                            margin: '34px auto 0px auto',
                            fontFamily: 'Segoe UI',
                            fontSize: '12px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '36px'
                        }}
                    >
                        Upload or drag and drop a file
                    </Text>





                    <Link
                        sx={{
                            position: 'relative',
                            margin: '-19px 20px 10px 20px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',
                            color: '#24292F'
                        }}
                        underline={true}
                        href="#"
                        onClick={() => {
                            downloadBulkExcel("")
                        }}
                    >Download Excel template</Link>

                    <Box

                        sx={Workplacetheme.createSpaceDialogFooterLine}

                        borderColor="border.default" borderBottomWidth={1} borderBottomStyle="solid">


                    </Box>





                </Box>

                <Box
                    ml={24}
                    mr={24}
                    mb={10}
                    display="flex"
                    flexDirection="row"
                    justifyContent={"right"}
                >
                    {
                        showLoadingBtn === true ?

                            <Button
                                style={{ width: '78px' }}
                                variant="outline"
                                sx={Workplacetheme.createSpaceDialogFooterBox.submitBtn}
                            >
                                <Spinner sx={{
                                    position: 'absolute',
                                    left: '0px',
                                    right: '0px',
                                    margin: 'auto',
                                    top: '0px',
                                    bottom: '0px',
                                }} size="small" />
                            </Button>

                            :

                            <Button
                                style={{ width: '78px' }}
                                variant="outline"
                                sx={Workplacetheme.createSpaceDialogFooterBox.submitBtn}
                                onClick={() => {
                                    handleUploadBulkExcelPan(bulkPanData)
                                }
                                }
                            >
                                Upload

                            </Button>


                    }






                </Box>





            </Dialog>
        )

    }


    const addGstPanToDb = async (props) => {

        const addonCountChargebee = addonsCount
        const addedItrCount = gstList.length

        if (panNameInput.value === "") {

            setpanNameInput((prevState) => {
                return ({ ...prevState, validationStatus: 'error' })
            })

        } else if (userNameInput.value === "") {

            setuserNameInput((prevState) => {
                return ({ ...prevState, validationStatus: 'error' })
            })

        } else if (userPassword.value === "") {

            setuserPassword((prevState) => {
                return ({ ...prevState, validationStatus: 'error' })
            })

        } else {


            console.log("addedItrCount", addedItrCount)
            console.log("addonCountChargebee", addonCountChargebee)
            setshowLoadingBtn(true)


            // if (addedItrCount >= addonCountChargebee) {

            //     closeAddPanDialog("")
            //     Toaster.create({ position: Position.TOP }).show({
            //         message: <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>

            //             Beta trial limit exceeds the 25 Pan limit
            //             <Text
            //                 onClick={() => {

            //                 }}
            //                 sx={{
            //                     marginLeft: '8px',
            //                     cursor: 'pointer',
            //                     color: 'white',
            //                     textDecoration: 'underline'
            //                 }}>
            //                 Upgrade
            //             </Text>
            //         </Box>,
            //         intent: Intent.DANGER
            //     });


            // } else {


            dispatch(postGstPortal({
                userId: userId,
                userEmail: userEmail,
                panName: panNameInput.value,
                panNumber: userNameInput.value,
                panPassword: userPassword.value,
            })).then((data) => {

                console.log("postGstPortal data", data)
                if (data.payload.statusCode === 409) {

                    Toaster.create({ position: Position.TOP }).show({
                        message: "Already added User",
                        intent: Intent.PRIMARY
                    });
                    setshowLoadingBtn(false)

                } else {

                    Toaster.create({ position: Position.TOP }).show({
                        message: "Successfully  added User",
                        intent: Intent.SUCCESS
                    });
                    setgstList((prevState) => {

                        const newState = [...prevState,
                        data.payload.data[0]]
                        return newState

                    })
                    closeAddPanDialog("")

                }

            })

            // }


        }




    }

    const getGrowUserSubscriptionFromDb = async (props) => {

        dispatch(getUserSubscriptionData({
            userEmail: userEmail
        })).then((data) => {

            console.log("getGrowUserSubscriptionFromDb", data.payload)

            //CANCELLED
            if (data?.payload?.body[0]?.subscription?.status === "cancelled") {

                setaddonsCount(0)
                setplanMetaDataQty(0)

            } else {

                setaddonsCount(props)
                setplanMetaDataQty(props)
            }


        })

    }


    const getTaxHeroSubscriptionFromDb = async (props) => {

        //https://deskiplatforms.chargebee.com/api/v2/customers/d2ff7024-609f-49f0-9513-ab4693dbc190/subscriptions
         

        dispatch(getTaxheroUserSubscription({
            userEmail: userEmail
        })).then(async (data) => {

            console.log("subscriptiondetails", data.payload)

            if (data?.payload?.body[0]?.subscription?.status === "active" || data?.payload?.body[0]?.subscription?.status === "in_trial") {

                const addonCountArray = [...data?.payload?.body[0]?.subscription?.subscription_items]
                const totalQuantity = addonCountArray.reduce((acc, item) => acc + item.quantity, 0);

                const freeQty = data?.payload?.plan?.item?.metadata?.freequantity
                setaddonsCount(data?.payload?.plan?.item?.metadata?.totalquantity)
                setplanMetaDataQty(data?.payload?.plan?.item?.metadata?.totalquantity)


            } else {

                const totalQuantity = data?.payload?.plan?.item?.metadata?.totalquantity
                await getGrowUserSubscriptionFromDb(totalQuantity)

            }


        })

    }


    const getGstPortalFromDb = (props) => {

        //getTaxheroGstList     getGstPortal
        dispatch(getTaxheroGstList({
            userId: userId,
            userEmail: userEmail,
        })).then((data) => {

            console.log("getgstList", data.payload)

            const allList = [...data.payload.data].sort((a, b) => {
                const nameA = a.pan_name.toUpperCase(); // Convert to upper case for case-insensitive comparison
                const nameB = b.pan_name.toUpperCase(); // Convert to upper case for case-insensitive comparison

                if (nameA < nameB) {
                    return -1; // nameA comes first
                }
                if (nameA > nameB) {
                    return 1; // nameB comes first
                }
                return 0; // names are equal
            });
            setgstList(allList)

        })


    }

    React.useState(() => {

        (function (c, l, a, r, i, t, y) {
            c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
            t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
            y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
        })(window, document, "clarity", "script", "ibtrxlrmkf");

    })


    React.useEffect(() => {

        if (serverTrigger) {

            getGstPortalFromDb("")
        }

    }, [serverTrigger])

    React.useState(() => {

        getGstPortalFromDb("")
        getTaxHeroSubscriptionFromDb("")
        //  getUserProductTour("")

    })




    return (
        <Box
            position={"relative"}
            display="flex"
            background={"#F6F6F6"}
            height="100vh"
            width={"100vw"}
            overflowY={"auto"}

        >
            <Helmet>
                <title>
                    {hostName === "www.app.taxherohq.com" || hostName === "www.get.taxherohq.com" ? 'GST List - taxhero' : 'GST List - taxhero'}
                </title>
            </Helmet>

            <TaxHeroHeader />





            <Box sx={{
                position: 'absolute',
                top: '48px',
                bottom: '0px',
                left: '64px',
                right: '0px',
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'auto',
                overflowX: 'hidden'

            }}>


                <Box sx={{
                    position: 'fixed',
                    left: hostName === "www.app.taxherohq.com" || hostName === "www.get.taxherohq.com" ? '0px' : '64px',
                    right: '0px',
                    minHeight: '48px',
                    maxHeight: '48px',
                    height: '48px',
                    background: customThemVariable.whiteBgColor,
                    display: 'flex',
                    borderBottom: '1px solid #E5E9EB',
                    zIndex: '1'
                }}>

                    <Text sx={{
                        marginTop: '12px',
                        marginLeft: '32px',
                        marginBottom: '15px',
                        color: customThemVariable.textgray,
                        fontSize: '14px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '20px',
                        cursor: 'pointer'
                    }}
                        onClick={() => {
                            hostName === "www.app.taxherohq.com"
                                ||
                                hostName === "www.get.taxherohq.com"
                                ?
                                navigate('/taxheroportal')
                                :
                                navigate('/portal')
                        }}
                    >
                        Home
                    </Text>

                    <Box sx={{
                        marginLeft: '6px',
                        marginTop: '14px',
                        marginBottom: '18px',
                        color: '#B0BABF'
                    }}>
                        <ChevronRightIcon />
                    </Box>

                    <Text
                        sx={{
                            marginTop: '12px',
                            marginLeft: '6px',
                            marginBottom: '15px',
                            color: customThemVariable.textgraydark,
                            fontSize: '14px',
                            fontFamily: customThemVariable.primartFont,
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '20px',
                            cursor: 'pointer'
                        }}
                        onClick={() => {
                            navigate('/gstpanlist')
                        }}
                    >
                        GST
                    </Text>


                    <Box sx={{
                        top: '8px',
                        position: 'absolute',
                        right: '76px',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '24px'
                    }}>
                        <Button
                            id="gstaddPanBtn"
                            variant="invisible"
                            sx={{
                                fontFamily: customThemVariable.primartFont,
                                fontStyle: 'normal',
                                fontWeight: '600',
                                lineHeight: '20px',
                                fontSize: '14px',
                            }}
                            onClick={() => {
                                showgstPanDailog("")
                            }}
                        >
                            Add new PAN
                        </Button>

                        <IconButton
                            variant="invisible"
                            sx={{
                                border: 'none',
                                color: customThemVariable.textblack
                            }}
                            icon={() => <KebabHorizontalIcon />}
                        />


                    </Box>

                </Box>


                <Box
                    sx={{
                        position: 'absolute',
                        left: '26px',
                        top: '81px',
                        right: '61px',
                        display: 'flex',
                        flexDirection: 'column',
                        bottom: '0px',
                    }}
                >

                    <Box
                        id="gstpanListing"
                        sx={{
                            height: 'auto',
                            minHeight: 'auto',
                            display: 'flex',
                            flexDirection: 'column'
                        }}>

                        {/* table header */}
                        <Box sx={{
                            // marginTop:'16px',
                            display: 'flex',
                            flexDirection: 'row',
                            minHeight: '40px',
                            height: '40px',
                            maxHeight: '40px',
                            borderBottom: `1px solid ${customThemVariable.borderColorTertiary}`,
                            background: `${customThemVariable.whiteBgColor}`
                        }}>

                            <Box sx={{
                                width: '30.0%',
                                minWidth: '30.0%',
                                maxWidth: '30.0%',
                                minHeight: '40px',
                                maxHeight: '40px',
                                height: '40px',
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <Text
                                    className={customTheme.H6}
                                    sx={{
                                        marginLeft: '16px',
                                        textTransform: 'uppercase',
                                        color: `${customThemVariable.midgreyText}`
                                    }}
                                >
                                    Name
                                </Text>
                            </Box>

                            <Box sx={{
                                width: '18.2%',
                                minWidth: '18.2%',
                                maxWidth: '18.2%',
                                minHeight: '40px',
                                maxHeight: '40px',
                                height: '40px',
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <Text
                                    className={customTheme.H6}
                                    sx={{
                                        position: 'absolute',
                                        marginLeft: '16px',
                                        textTransform: 'uppercase',
                                        color: `${customThemVariable.midgreyText}`
                                    }}
                                >
                                    PAN
                                </Text>
                            </Box>

                            <Box sx={{
                                width: '30.75%',
                                minWidth: '30.75%',
                                maxWidth: '30.75%',
                                minHeight: '40px',
                                maxHeight: '40px',
                                height: '40px',
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <Text
                                    className={customTheme.H6}
                                    sx={{
                                        marginLeft: '16px',
                                        textTransform: 'uppercase',
                                        color: `${customThemVariable.midgreyText}`
                                    }}
                                >
                                    Legal name
                                </Text>
                            </Box>

                            <Box sx={{
                                width: '13.44%',
                                minWidth: '13.44%',
                                maxWidth: '13.44%',
                                minHeight: '40px',
                                maxHeight: '40px',
                                height: '40px',
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <Text
                                    className={customTheme.H6}
                                    sx={{
                                        marginLeft: '16px',
                                        textTransform: 'uppercase',
                                        color: `${customThemVariable.midgreyText}`
                                    }}
                                >
                                    Last update
                                </Text>
                            </Box>
                            <Box sx={{
                                width: '8.5%',
                                minWidth: '8.5%',
                                maxWidth: '8.5%',
                                minHeight: '40px',
                                maxHeight: '40px',
                                height: '40px',
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <Text
                                    className={customTheme.H6}
                                    sx={{
                                        marginLeft: '16px',
                                        textTransform: 'uppercase',
                                        color: `${customThemVariable.midgreyText}`
                                    }}
                                >

                                </Text>
                            </Box>


                        </Box>
                        {/* ------------ */}
                        {
                            gstList.map((row, index) => {

                                return (
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        minHeight: '40px',
                                        height: '40px',
                                        maxHeight: '40px',
                                        borderBottom: `1px solid ${customThemVariable.borderColorTertiary}`,
                                        background: `${customThemVariable.whiteBgColor}`,
                                        cursor: 'pointer',
                                        ":hover": {
                                            background: 'rgba(234, 238, 242, 0.5)'
                                        },
                                    }}
                                    >

                                        <Box sx={{
                                            width: '30.0%',
                                            minWidth: '30.0%',
                                            maxWidth: '30.0%',
                                            minHeight: '40px',
                                            maxHeight: '40px',
                                            height: '40px',
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                            onClick={() => {

                                                localStorage.setItem("client_id", row.client_id)
                                                localStorage.setItem("insert_id", row.insert_id)
                                                localStorage.setItem("gstpanName", row.pan_name)
                                                localStorage.setItem("gstIn", row.user_name)
                                                navigate('/gst')
                                            }}
                                        >
                                            <Text
                                                className={customTheme.textnormal}
                                                sx={{
                                                    marginLeft: '16px',
                                                    color: `${customThemVariable.darkgreysecond}`
                                                }}
                                            >
                                                {row.pan_name}
                                            </Text>
                                        </Box>

                                        <Box sx={{
                                            width: '18.2%',
                                            minWidth: '18.2%',
                                            maxWidth: '18.2%',
                                            minHeight: '40px',
                                            maxHeight: '40px',
                                            height: '40px',
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>
                                            <Text
                                                className={customTheme.textnormal}
                                                sx={{
                                                    position: 'absolute',
                                                    marginLeft: '16px',
                                                    color: `${customThemVariable.darkgreysecond}`
                                                }}
                                            >
                                                {row.user_name}
                                            </Text>
                                        </Box>

                                        <Box sx={{
                                            width: '30.75%',
                                            minWidth: '30.75%',
                                            maxWidth: '30.75%',
                                            minHeight: '40px',
                                            maxHeight: '40px',
                                            height: '40px',
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>
                                            <Text
                                                className={customTheme.textnormal}
                                                sx={{
                                                    marginLeft: '16px',
                                                    color: `${customThemVariable.darkgreysecond}`
                                                }}
                                            >
                                                {row?.playwright_gst_profile[0]?.tradeName || ""}
                                            </Text>
                                        </Box>

                                        <Box sx={{
                                            width: '13.44%',
                                            minWidth: '13.44%',
                                            maxWidth: '13.44%',
                                            minHeight: '40px',
                                            maxHeight: '40px',
                                            height: '40px',
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>
                                            <Text
                                                className={customTheme.textnormal}
                                                sx={{
                                                    marginLeft: '16px',
                                                    color: `${customThemVariable.darkgreysecond}`
                                                }}
                                            >

                                                {/* {new Date(row.updated_timestamp).toDateString().substring(3, 10) + ", "}
                                                {new Date(row.updated_timestamp).toLocaleTimeString().substring(0, 20)} */}
                                                {new Date(row?.updated_timestamp).toDateString().substring(3, 10) + ", "}
                                                {new Date((new Date(row?.updated_timestamp).getTime()) - (5.5 * 60 * 60 * 1000)).toLocaleTimeString()}



                                            </Text>
                                        </Box>
                                        <Box sx={{
                                            width: '8.5%',
                                            minWidth: '8.5%',
                                            maxWidth: '8.5%',
                                            minHeight: '40px',
                                            maxHeight: '40px',
                                            height: '40px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}>

                                            <ActionMenu>
                                                <ActionMenu.Anchor>
                                                    <IconButton
                                                        sx={{
                                                            display: 'flex',
                                                            background: 'transparent',
                                                            border: 'none'
                                                        }}
                                                        icon={() => <KebabHorizontalIcon />}
                                                    />
                                                </ActionMenu.Anchor>
                                                <ActionMenu.Overlay>
                                                    <ActionList sx={{ fontFamily: 'Inter' }}>
                                                        <ActionList.Item
                                                            onSelect={() => handleChangePassword(row.insert_id, row?.pan_name, row?.user_name, row?.password)}
                                                        >Change password</ActionList.Item>
                                                        <ActionList.Item variant="danger"
                                                            onSelect={() => handleDeleteGstPan(row.insert_id)}
                                                        >Delete</ActionList.Item>
                                                    </ActionList>
                                                </ActionMenu.Overlay>
                                            </ActionMenu>



                                        </Box>


                                    </Box>
                                )
                            })
                        }


                        <Box sx={{ minHeight: '177px' }}></Box>

                    </Box>


                </Box>








            </Box>



            {AddGstPanDialog("")}
            {BulkPanDialog("")}
            <GSTSearchDialogs />
            <UploadPanDialog />
            <GSTSyncDialog />
            <ChangePasswordGstPanDialog />

        </Box>
    )

}

export default GstListViewPlaywright